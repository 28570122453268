import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import AuthService from "./AuthService";
import { usePayment } from "../../contexts/PaymentContext";
import API from "../../services/api";
import PaymentForm from "./PaymentForm";
import SelectWithOtherInput from "../reusable_components/FormInputFields/SelectWithOtherInput";
import SelectInput from "../reusable_components/FormInputFields/SelectInput";

const RegistrationForm = () => {
   const [formData, setFormData] = useState({
      username: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      companyName: "",
      industry: "",
      coreOfferings: "",
      sizeByRevenue: "",
      sizeByEmployeeCount: ""
   });
   const [error, setError] = useState("");
   const [fieldErrors, setFieldErrors] = useState({});
   const [serverErrors, setServerErrors] = useState({});
   const [isFormValid, setIsFormValid] = useState(false);
   const [showPaymentForm, setShowPaymentForm] = useState(false);
   const [registeredUserId, setRegisteredUserId] = useState(null);

   useEffect(() => {
      const savedFormData = localStorage.getItem("registrationFormData");
      if (savedFormData) {
         setFormData(JSON.parse(savedFormData));
         console.log("Loaded form data from localStorage:", JSON.parse(savedFormData));
      }
   }, []);

   useEffect(() => {
      console.log("Current formData:", formData);
   }, [formData]);

   const validateField = async (name, value) => {
      let error = "";
      if (!value.trim()) {
         error = `${name} is required`;
      } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
         error = "Invalid email format";
      } else if (name === "confirmEmail" && value !== formData.email) {
         error = "Emails do not match";
      } else if (name === "confirmPassword" && value !== formData.password) {
         error = "Passwords do not match";
      }

      setFieldErrors((prev) => ({ ...prev, [name]: error }));

      if ((name === "username" || name === "email") && value.trim() && !error) {
         try {
            const response = await API.post("auth/verify-registration-data", { [name]: value });
            if (response.data.errors && response.data.errors[name]) {
               setServerErrors((prev) => ({ ...prev, [name]: response.data.errors[name] }));
            } else {
               setServerErrors((prev) => ({ ...prev, [name]: "" }));
            }
         } catch (err) {
            console.error("Error verifying field:", err);
            if (err.response && err.response.data && err.response.data.errors) {
               setServerErrors((prev) => ({
                  ...prev,
                  [name]: err.response.data.errors[name] || "Unknown server error"
               }));
            } else {
               setServerErrors((prev) => ({ ...prev, [name]: "Error connecting to server" }));
            }
         }
      } else {
         setServerErrors((prev) => ({ ...prev, [name]: "" }));
      }
   };

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
      validateField(name, value);
   };

   const handleBlur = (e) => {
      const { name, value } = e.target;
      validateField(name, value);
   };

   useEffect(() => {
      const allFieldsFilled = Object.values(formData).every((field) => field.trim() !== "");
      const noFieldErrors = Object.values(fieldErrors).every((error) => error === "");
      const noServerErrors = Object.values(serverErrors).every((error) => error === "");
      setIsFormValid(allFieldsFilled && noFieldErrors && noServerErrors);
   }, [formData, fieldErrors, serverErrors]);

   const handleSubmit = async (e) => {
      e.preventDefault();
      setError("");

      if (!isFormValid) {
         setError("Please correct all errors before submitting");
         return;
      }

      try {
         const response = await AuthService.register(formData);
         localStorage.removeItem("registrationFormData");

         // Store the registered user ID and show payment form
         setRegisteredUserId(response.user.userId);
         setShowPaymentForm(true);
      } catch (error) {
         console.error("Registration failed:", error);
         setError(error.message || "Registration failed");
      }
   };

   const renderInput = (name, label, type = "text") => (
      <div className="mb-4">
         <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
            {label}:
         </label>
         <input
            type={type}
            id={name}
            name={name}
            value={formData[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
               fieldErrors[name] || serverErrors[name] ? "border-red-500" : ""
            }`}
         />
         {fieldErrors[name] && <p className="text-red-500 text-xs italic">{fieldErrors[name]}</p>}
         {serverErrors[name] && <p className="text-red-500 text-xs italic">{serverErrors[name]}</p>}
      </div>
   );

   const industryOptions = [
      { value: "", label: "Select Industry" },
      { value: "software", label: "Software & Technology" },
      { value: "saas", label: "SaaS (Software as a Service)" },
      { value: "fintech", label: "Financial Technology" },
      { value: "ecommerce", label: "E-commerce & Retail Tech" },
      { value: "gamedev", label: "Video Game Development" },
      { value: "healthtech", label: "Healthcare Technology" },
      { value: "edtech", label: "Education Technology" },
      { value: "martech", label: "Marketing Technology" },
      { value: "hrtech", label: "HR Technology" },
      { value: "iot", label: "Internet of Things (IoT)" },
      { value: "ai_ml", label: "Artificial Intelligence & Machine Learning" },
      { value: "cybersecurity", label: "Cybersecurity" },
      { value: "telecom", label: "Telecommunications" },
      { value: "cleantech", label: "Clean Technology & Sustainability" },
      { value: "biotech", label: "Biotechnology" },
      { value: "aerospace", label: "Aerospace & Defense" },
      { value: "automotive", label: "Automotive Technology" },
      { value: "manufacturing", label: "Manufacturing Technology" },
      { value: "logistics", label: "Logistics & Supply Chain Tech" },
      { value: "proptech", label: "Property Technology" },
      { value: "legaltech", label: "Legal Technology" },
      { value: "govtech", label: "Government & Public Sector Technology" },
      { value: "mediatech", label: "Media & Entertainment Technology" },
      { value: "agritech", label: "Agricultural Technology" },
      { value: "other", label: "Other" }
   ];

   const revenueSizeOptions = [
      { value: "", label: "Select Revenue Size" },
      { value: "0-1M", label: "$0 - $1 Million" },
      { value: "1M-10M", label: "$1 Million - $10 Million" },
      { value: "10M-50M", label: "$10 Million - $50 Million" },
      { value: "50M-100M", label: "$50 Million - $100 Million" },
      { value: "100M+", label: "$100 Million+" }
   ];

   const employeeSizeOptions = [
      { value: "", label: "Select Employee Count" },
      { value: "1-10", label: "1-10 employees" },
      { value: "11-50", label: "11-50 employees" },
      { value: "51-200", label: "51-200 employees" },
      { value: "201-500", label: "201-500 employees" },
      { value: "501+", label: "501+ employees" }
   ];

   const renderSelectWithOther = (name, label, options) => (
      <div className="mb-4">
         <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
            {label}:
         </label>
         <SelectWithOtherInput
            id={name}
            name={name}
            value={formData[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            options={options}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
               fieldErrors[name] || serverErrors[name] ? "border-red-500" : ""
            }`}
         />
         {fieldErrors[name] && <p className="text-red-500 text-xs italic">{fieldErrors[name]}</p>}
         {serverErrors[name] && <p className="text-red-500 text-xs italic">{serverErrors[name]}</p>}
      </div>
   );

   const renderSelect = (name, label, options) => (
      <div className="mb-4">
         <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
            {label}:
         </label>
         <SelectInput
            id={name}
            name={name}
            value={formData[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            options={options}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
               fieldErrors[name] || serverErrors[name] ? "border-red-500" : ""
            }`}
         />
         {fieldErrors[name] && <p className="text-red-500 text-xs italic">{fieldErrors[name]}</p>}
         {serverErrors[name] && <p className="text-red-500 text-xs italic">{serverErrors[name]}</p>}
      </div>
   );

   if (showPaymentForm) {
      return <PaymentForm userId={registeredUserId} />;
   }

   return (
      <div>
         <PageTitle>Register</PageTitle>
         {error && <div className="text-red-500 text-center mb-4">{error}</div>}
         <form
            onSubmit={handleSubmit}
            className="max-w-4xl mx-auto mt-10 p-5 border rounded-lg shadow-lg grid grid-cols-2 gap-4"
         >
            <div className="grid grid-cols-1 gap-4">
               <h2 className="text-xl font-bold mb-4">User Information</h2>
               {renderInput("username", "Username")}
               {renderInput("email", "Email", "email")}
               {renderInput("confirmEmail", "Confirm Email", "email")}
               {renderInput("password", "Password", "password")}
               {renderInput("confirmPassword", "Confirm Password", "password")}
               {renderInput("firstName", "First Name")}
               {renderInput("lastName", "Last Name")}
            </div>
            <div className="border-l-2 pl-4 grid grid-cols-1 gap-4">
               <div className="flex items-center mb-4">
                  <h2 className="text-xl font-bold">Company Information</h2>
                  <p className="text-sm text-gray-600 ml-2">used for better AI!</p>
               </div>
               {renderInput("companyName", "Company Name")}
               {renderSelectWithOther("industry", "Industry", industryOptions)}
               {renderInput("coreOfferings", "Core Offering(s)")}
               {renderSelect("sizeByRevenue", "Size By Revenue", revenueSizeOptions)}
               {renderSelect("sizeByEmployeeCount", "Size By Employee Count", employeeSizeOptions)}
            </div>
            <div className="col-span-2 flex justify-center mt-4">
               <PrimaryButton type="submit" disabled={!isFormValid}>
                  Proceed to Payment
               </PrimaryButton>
            </div>
         </form>
      </div>
   );
};

export default RegistrationForm;
