import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthService from "../components/auth/AuthService";

function OnboardingCheckRoute() {
   const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(null);

   useEffect(() => {
      const checkOnboardingStatus = async () => {
         try {
            const status = await AuthService.getUserHasCompletedTheOnboardingFlowXProd();
            setHasCompletedOnboarding(status);
         } catch (error) {
            console.error("Error checking onboarding status:", error);
            setHasCompletedOnboarding(false);
         }
      };

      checkOnboardingStatus();
   }, []);

   if (hasCompletedOnboarding === null) {
      // Still loading, you might want to show a loading spinner here
      return null;
   }

   if (hasCompletedOnboarding) {
      return <Outlet />;
   } else {
      return <Navigate to="/onboarding" replace />;
   }
}

export { OnboardingCheckRoute };