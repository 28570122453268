import React from 'react';
import styled from 'styled-components';

const FeatureRequestsContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--blue-lighter2, #E5EFFF);
`;

const FeatureRequestsText = styled.span`
  color: var(--blue-dark, #104499);
  font-family: "Roboto Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;

const FeatureRequestsDummyCard = () => {
  return (
    <FeatureRequestsContainer>
      <FeatureRequestsText>Feature Requests</FeatureRequestsText>
    </FeatureRequestsContainer>
  );
};

export default FeatureRequestsDummyCard;
