import React, { useState, useEffect } from "react";
import H2Black from "../reusable_components/HeadingsAndTitles/H2Black";
import H3Gray from "../reusable_components/HeadingsAndTitles/H3Gray";
import OnboardingFlowProgressBar from "./OnboardingFlowProgressBar";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import SkipThisStepButton from "./SkipThisStepButton";
import API from "../../services/api";
import AuthService from "../auth/AuthService";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// Custom styled Alert component
const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateInitiativesOnboardingFlowPage2 = ({ onNextStep, onSkipStep }) => {
   const [initiativeName, setInitiativeName] = useState("");
   const [initiativeDescription, setInitiativeDescription] = useState("");
   const [isFormValid, setIsFormValid] = useState(false);
   const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success", key: 0 });

   useEffect(() => {
      setIsFormValid(initiativeName.trim() !== "" && initiativeDescription.trim() !== "");
   }, [initiativeName, initiativeDescription]);

   const handleNextClick = async () => {
      const user = AuthService.getCurrentUser();
      if (!user) {
         console.error("User not authenticated");
         return;
      }

      const newInitiative = {
         title: initiativeName,
         description: initiativeDescription,
         source: "onboarding",
         sourceRelatedMetadata: { createdAt: new Date().toISOString() },
         associatedUserId: user.user.userId,
         additional_initiative_information: {
            team: []
         }
      };

      try {
         const response = await API.post("initiatives-page/create-new-initiative", newInitiative);
         const createdInitiativeId = response.data._id;
         showNotification("Initiative created successfully!", "success");
         setTimeout(() => {
            onNextStep(createdInitiativeId);
         }, 3000);
      } catch (error) {
         console.error("Error creating new initiative:", error);
         showNotification("Failed to create initiative.", "error");
      }
   };

   const handleSkipClick = () => {
      console.log("Skip button clicked");
      onSkipStep();
   };

   const showNotification = (message, severity) => {
      setSnackbar((prev) => ({
         open: true,
         message,
         severity,
         key: prev.key + 1 // Increment the key to force re-render
      }));
   };

   const handleCloseSnackbar = (event, reason) => {
      if (reason === "clickaway") {
         return;
      }
      setSnackbar((prev) => ({ ...prev, open: false }));
   };

   const getInputStyle = (value) => ({
      background: "transparent",
      color: value ? "var(--blue-darkest, #001331)" : "#727F95",
      fontFamily: '"Roboto Mono"',
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "150%"
   });

   return (
      <div
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "687px",
            width: "880px"
         }}
      >
         <OnboardingFlowProgressBar currentStep={1} totalSteps={3} />
         <H2Black style={{ textAlign: "center" }}>Create your first initiative</H2Black>
         <p style={{
            color: '#727F95',
            fontFamily: '"Roboto Mono"',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '135%',
            alignSelf: 'stretch'
         }}>
            Creating initiatives helps you prioritize roadmap items, supported by intelligent insights and ROI
            predictions
         </p>
         <p style={{
            color: '#727F95',
            fontFamily: '"Roboto Mono"',
            fontSize: '14px',
            fontStyle: 'italic',
            fontWeight: 400,
            lineHeight: '135%',
            alignSelf: 'stretch',
            marginTop: '10px',
            // textAlign: 'center'
         }}>
            Don't worry – you'll be able to import initiatives from Jira later!
         </p>
         <div
            className="flex items-center gap-2 px-5 py-[9px] rounded-xl border border-[#E5EAF3] w-[100%] mt-16"
            style={{ background: "white", borderColor: "#E5EAF3" }}
         >
            <input
               type="text"
               id="initiativeName"
               className="flex-1 bg-transparent focus:outline-none placeholder-[#727F95]"
               placeholder="Enter a name"
               maxLength="40"
               value={initiativeName}
               onChange={(e) => setInitiativeName(e.target.value)}
               style={getInputStyle(initiativeName)}
            />
            <div className="w-[39px] text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%]">
               {initiativeName.length}/40
            </div>
         </div>
         <div
            className="flex flex-col h-[150px] rounded-xl border border-[#E5EAF3] w-[100%] mt-6"
            style={{ background: "white", borderColor: "#E5EAF3" }}
         >
            <div className="flex flex-1 flex-col px-5 py-[9px] gap-2">
               <textarea
                  id="initiativeDescription"
                  className="flex-1 bg-transparent resize-none focus:outline-none placeholder-[#727F95]"
                  placeholder="Description"
                  maxLength="1000"
                  value={initiativeDescription}
                  onChange={(e) => setInitiativeDescription(e.target.value)}
                  style={getInputStyle(initiativeDescription)}
               ></textarea>
            </div>
            <div className="self-stretch text-right text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%] px-5 pb-[9px]">
               {initiativeDescription.length}/1,000
            </div>
         </div>
         <PrimaryButton onClick={handleNextClick} style={{ marginTop: "64px" }} disabled={!isFormValid}>
            Next
         </PrimaryButton>
         <SkipThisStepButton onClick={handleSkipClick} />
         <Snackbar
            key={snackbar.key}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
         >
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
               {snackbar.message}
            </Alert>
         </Snackbar>
      </div>
   );
};

export default CreateInitiativesOnboardingFlowPage2;