import React from 'react';
import FindRelatedInsightsButton from '../reusable_components/Buttons/FindRelatedInsightsButton';
import PainPointsDummyCard from '../reusable_components/Cards/InsightsDummyCards/PainPointsDummyCard';
import FeatureRequestsDummyCard from '../reusable_components/Cards/InsightsDummyCards/FeatureRequestsDummyCard';
import { ReactComponent as StarWith4PointsIcon } from '../../assets/icons/StarWith4PointsIcon.svg';

const DummyInitiativeBoxForFindRelevantInsightsStepOfOnboardingFlow = ({ relatedInsightsHaveAlreadyBeenFound = false }) => {
  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '156px',
      padding: '20px 0px 10px 0px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '20px',
      flexShrink: 0,
      borderRadius: '12px',
      border: '1px solid var(--slate-light, #C5D1E4)'
    }}>
        <div style={{
          display: 'flex',
          width: '500px',
          padding: '0px 30px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '7px'
        }}>
          <div style={{
            width: '220px',
            height: '20px',
            borderRadius: '4px',
            background: 'var(--slate-light, #C5D1E4)'
          }}></div>
          <div style={{
            height: '12px',
            alignSelf: 'stretch',
            borderRadius: '4px',
            background: 'var(--slate-lighter, #E5EAF3)'
          }}></div>
          <div style={{
            height: '12px',
            alignSelf: 'stretch',
            borderRadius: '4px',
            background: 'var(--slate-lighter, #E5EAF3)'
          }}></div>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          alignSelf: 'stretch' 
        }}>
          <div style={{
            width: 880,
            height: 1,
            background: 'var(--slate-light, #C5D1E4)'
          }}></div>
          <div style={{
            display: 'flex',
            padding: '0px 20px 0px 30px',
            alignItems: 'center',
            gap: 8,
            alignSelf: 'stretch'
          }}>
            {relatedInsightsHaveAlreadyBeenFound ? (
              <>
                <div style={{ display: 'flex', gap: '8px', marginTop: '6px' }}>
                  <PainPointsDummyCard />
                  <FeatureRequestsDummyCard />
                </div>
              </>
            ) : (
              <p style={{
                color: 'var(--slate-dark, #727F95)',
                fontFamily: '"Roboto Mono"',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '135%',
                letterSpacing: '0.32px',
                flex: '1 0 0'
              }}>
                No insights to display
              </p>
            )}
            {!relatedInsightsHaveAlreadyBeenFound && <FindRelatedInsightsButton />}
          </div>
        </div>
      
      {!relatedInsightsHaveAlreadyBeenFound && (
        <svg 
          width="51" 
          height="51" 
          style={{
            position: 'absolute',
            right: 'calc(1.23in + 51px)',
            bottom: 'calc(3px)',
          }}
        >
          <ellipse
            cx="25.5"
            cy="25.5"
            rx="25.5"
            ry="25.5"
            fill="rgba(172, 200, 255, 0.37)"
          />
        </svg>
      )}
      {relatedInsightsHaveAlreadyBeenFound && (
        <StarWith4PointsIcon
          style={{
            width: '16px',
            height: '16px',
            flexShrink: 0,
            fill: 'var(--blue-medium, #628FDA)',
            position: 'absolute',
            left: '12px',
            top: '103px',
          }}
        />
      )}
    </div>
  );
};

export default DummyInitiativeBoxForFindRelevantInsightsStepOfOnboardingFlow;