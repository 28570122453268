import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchIntegrationStatus } from '../utils/IntegrationUtils';

const IntegrationsContext = createContext();

export const IntegrationsProvider = ({ children }) => {
  const [integrations, setIntegrations] = useState({
    jira: false,
    // Add other integrations here as needed
  });

  useEffect(() => {
    const loadIntegrationStatus = async () => {
      try {
        const { integrationStatus } = await fetchIntegrationStatus();
        setIntegrations(integrationStatus);
      } catch (error) {
        console.error("Error loading integration status:", error);
      }
    };

    loadIntegrationStatus();
  }, []);

  return (
    <IntegrationsContext.Provider value={{ integrations, setIntegrations }}>
      {children}
    </IntegrationsContext.Provider>
  );
};

export const useIntegrations = () => useContext(IntegrationsContext);