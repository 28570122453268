import React from "react";
import PageTitle from "../../../reusable_components/HeadingsAndTitles/PageTitle";
import CostAreasStack from "./CostAreasStack";

function CostCalculations({ data }) {
   const totalCost = Object.values(data.cost_areas).reduce(
      (sum, area) => sum + Object.values(area.cost_items).reduce((areaSum, item) => areaSum + parseFloat(item.cost), 0),
      0
   );

   return (
      <div>
         <PageTitle>Cost Estimation</PageTitle>
         <h2 className="text-3xl font-bold mb-4">Total Cost: ${totalCost.toFixed(2)}</h2>
         <CostAreasStack costAreas={data.cost_areas} />
      </div>
   );
}

export default CostCalculations;
