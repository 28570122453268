import React from 'react';

const ExpandIcon = ({ width = 24, height = 24, color = '#628FDA' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Expand">
        <path
          id="Vector"
          d="M5.55375 19.5L4.5 18.4462L15.9462 7H9V5.5H18.5V15H17V8.05375L5.55375 19.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ExpandIcon;