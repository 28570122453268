import React, { useState } from 'react';

function DevelopmentArea({ workPackages, teamInfo, teamAllocations, phaseDuration }) {
  const [expandedWPs, setExpandedWPs] = useState({});

  const toggleWP = (wpName) => {
    setExpandedWPs(prev => ({
      ...prev,
      [wpName]: !prev[wpName]
    }));
  };

  const getTeamMemberName = (memberId) => {
    const member = teamInfo.find(m => m.id === memberId);
    return member ? member.name : 'Unknown Member';
  };

  return (
    <div>
      {Object.entries(workPackages).map(([wpName, wpData]) => (
        <div key={wpName} className="mb-8 bg-green-50 p-6 rounded-lg shadow-md border-2 border-green-200">
          <button 
            className="w-full text-left flex justify-between items-center"
            onClick={() => toggleWP(wpName)}
          >
            <h4 className="text-2xl font-semibold text-green-800">{wpName}</h4>
            <span className="text-2xl">{expandedWPs[wpName] ? '▼' : '▶'}</span>
          </button>
          {expandedWPs[wpName] && (
            <>
              <p className="mb-4 text-gray-700">{wpData.work_package_description}</p>
              <ul className="list-none p-0 space-y-4">
                {Object.entries(wpData.tasks).map(([taskName, taskData]) => (
                  <li key={taskName} className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                    <strong className="text-lg block mb-2 text-gray-800">{taskName}</strong>
                    <p className="mb-3 text-gray-600">{taskData.task_description}</p>
                    <div className="flex flex-col">
                      <label className="font-bold mb-2 text-gray-700">Team Members:</label>
                      {Object.entries(taskData.team_time_allocation).map(([memberId, hours]) => (
                        <div key={memberId} className="flex justify-between items-center mb-1">
                          <span>{getTeamMemberName(memberId)}</span>
                          <span>{hours} labor hours</span>
                        </div>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default DevelopmentArea;
