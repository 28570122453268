import React, { useState, useContext } from "react";
import { Tooltip, styled } from "@mui/material";
import H2Black from "../reusable_components/HeadingsAndTitles/H2Black";
import H3Gray from "../reusable_components/HeadingsAndTitles/H3Gray";
import OnboardingFlowProgressBar from "./OnboardingFlowProgressBar";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import SkipThisStepButton from "./SkipThisStepButton";
import InfoIcon from "../../assets/icons/OnboardingFlowIcons/InfoIcon.svg";
import documentTypes from "../../ConstantVariables.json";
import WeUnderstandDocumentConfidentialityMessage from "./WeUnderstandDocumentConfidentialityMessage";
import { uploadFiles, getSupportedFileTypes } from "../../services/localFileUploadService";
import { StatusContext } from "../../contexts/StatusContext";
import FileUploadAndDocumentTypeSelector from "../reusable_components/FileManagement/FileUploadAndDocumentTypeSelector";
import AddPlusIcon from "../../assets/icons/AddPlusIcon.svg";

// Custom styled tooltip
const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
   ({ theme }) => ({
      "& .MuiTooltip-tooltip": {
         backgroundColor: theme.palette.background.paper,
         color: theme.palette.text.primary,
         maxWidth: 320,
         fontSize: theme.typography.pxToRem(12),
         border: "1px solid #dadde9",
         boxShadow: theme.shadows[1],
         borderRadius: theme.shape.borderRadius,
         padding: theme.spacing(2)
      }
   })
);

const AddDocumentsOnboardingFlowPage3 = ({ onNextStep, onSkipStep }) => {
   const [uploadedFiles, setUploadedFiles] = useState([]);
   const [allDocumentTypesSelected, setAllDocumentTypesSelected] = useState(false);
   const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);

   const documentTypeTooltipContent = (
      <div>
         {Object.entries(documentTypes).map(([type, { description }]) => (
            <div key={type} style={{ marginBottom: "8px" }}>
               <strong style={{ color: "#1976d2" }}>{type}:</strong>
               <p style={{ margin: "4px 0 0 0" }}>{description}</p>
            </div>
         ))}
      </div>
   );

   const handleUploadClick = async () => {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.multiple = true;
      fileInput.accept = getSupportedFileTypes().join(",");
      fileInput.onchange = async (event) => {
         const files = Array.from(event.target.files);
         if (files.length > 0) {
            const uploadedFileMetadataObjects = await uploadFiles(files, setIsCurrentlyCheckingForUpdatesOnProcesses);
            setUploadedFiles(uploadedFileMetadataObjects);
         }
      };
      fileInput.click();
   };

   const handleDocumentTypeSelect = (fileId, selectedDocumentType) => {
      const updatedFiles = uploadedFiles.map((file) => {
         if (file.id === fileId) {
            return { ...file, documentType: selectedDocumentType };
         }
         return file;
      });
      setUploadedFiles(updatedFiles);

      const allSelected = updatedFiles.every((file) => file.documentType !== undefined);
      setAllDocumentTypesSelected(allSelected);
   };

   const handleAddAnother = async () => {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.multiple = true;
      fileInput.accept = getSupportedFileTypes().join(",");
      fileInput.onchange = async (event) => {
         const files = Array.from(event.target.files);
         if (files.length > 0) {
            const uploadedFileMetadataObjects = await uploadFiles(files, setIsCurrentlyCheckingForUpdatesOnProcesses);
            setUploadedFiles([...uploadedFiles, ...uploadedFileMetadataObjects]);
         }
      };
      fileInput.click();
   };

   const handleFileDelete = (deletedFileId) => {
      setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== deletedFileId));
      // Check if all remaining files have document types selected
      const remainingFiles = uploadedFiles.filter((file) => file.id !== deletedFileId);
      const allSelected = remainingFiles.every((file) => file.documentType !== undefined);
      setAllDocumentTypesSelected(allSelected);
   };

   return (
      <div
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "687px",
            width: "880px"
         }}
      >
         <OnboardingFlowProgressBar currentStep={2} totalSteps={3} />
         <H2Black style={{ textAlign: "center" }}>Add documents:</H2Black>
         <p
            style={{
               color: "var(--blue-darkest, #001331)",
               fontFamily: '"Roboto Mono"',
               fontSize: "16px",
               fontStyle: "normal",
               fontWeight: 400,
               lineHeight: "135%",
               marginBottom: "12px"
            }}
         >
            Documents you add will be used to automatically identify insights related to your initiatives.
         </p>

         <div
            style={{
               display: "flex",
               alignItems: "flex-start",
               gap: "6px",
               alignSelf: "stretch"
            }}
         >
            <StyledTooltip title={documentTypeTooltipContent} arrow placement="right-start">
               <img src={InfoIcon} alt="Info Icon" style={{ width: "20px", height: "20px", cursor: "pointer" }} />
            </StyledTooltip>
            <span
               style={{
                  color: "#727F95",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "135%"
               }}
            >
               Suggested documents: market research, discovery interviews, product strategy and meeting notes
            </span>
         </div>

         {uploadedFiles.length === 0 ? (
            <PrimaryButton onClick={handleUploadClick} style={{ marginTop: "64px" }}>
               Upload Documents
            </PrimaryButton>
         ) : (
            <div
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                  marginTop: "64px",
                  width: "100%"
               }}
            >
               {uploadedFiles.map((fileMetadata) => (
                  <FileUploadAndDocumentTypeSelector
                     key={fileMetadata.id}
                     fileName={fileMetadata.file_name}
                     file_metadata_mongodb_doc_id={fileMetadata.id}
                     onSelectDocumentType={(selectedDocumentType) =>
                        handleDocumentTypeSelect(fileMetadata.id, selectedDocumentType)
                     }
                     onFileDelete={handleFileDelete}
                  />
               ))}
               <button
                  onClick={handleAddAnother}
                  style={{
                     display: "flex",
                     height: "40px",
                     paddingRight: "12px",
                     justifyContent: "center",
                     alignItems: "center",
                     gap: "8px",
                     borderRadius: "999px"
                  }}
               >
                  <img src={AddPlusIcon} alt="Add Plus Icon" style={{ width: "20px", height: "19.121px" }} />
                  <span
                     style={{
                        color: "var(--blue-dark, var(--blue-dark, #104499))",
                        fontFamily: '"Roboto Mono"',
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "135%",
                        letterSpacing: "0.32px",
                        textTransform: "capitalize"
                     }}
                  >
                     Add Another
                  </span>
               </button>
            </div>
         )}

         <p
            style={{
               color: "#727F95",
               textAlign: uploadedFiles.length === 0 ? "center" : "left",
               fontFamily: '"Roboto Mono"',
               fontSize: "16px",
               fontStyle: "normal",
               fontWeight: 400,
               lineHeight: "130%",
               margin: "16px 0",
               width: uploadedFiles.length === 0 ? "auto" : "100%"
            }}
         >
            Up to 1 GB per file
            <br />
            Formats accepted: pdf, docx, txt, mp4, mov, avi, wmv, mp3, wav
         </p>
         <WeUnderstandDocumentConfidentialityMessage style={{ marginTop: "64px", marginBottom: "64px" }} />
         <PrimaryButton onClick={onNextStep} disabled={!allDocumentTypesSelected} style={{ marginTop: "16px" }}>
            Next
         </PrimaryButton>
         <SkipThisStepButton onClick={onSkipStep} style={{ marginTop: "20px", marginBottom: "200px" }} />
      </div>
   );
};

export default AddDocumentsOnboardingFlowPage3;
