import React from 'react';
import styled from 'styled-components';

const PainPointsContainer = styled.div`
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--color-red-light, #FFECEC);
`;

const PainPointsText = styled.span`
  color: var(--color-red, #F26969);
  font-family: "Roboto Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 0.28px;
  text-transform: uppercase;
`;

const PainPointsDummyCard = () => {
  return (
    <PainPointsContainer>
      <PainPointsText>Pain Points</PainPointsText>
    </PainPointsContainer>
  );
};

export default PainPointsDummyCard;
