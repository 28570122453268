import React, { createContext, useState, useEffect } from 'react';
import API from '../services/api';
import { useAuth } from './AuthContext';

export const StatusContext = createContext();

export const StatusProvider = ({ children }) => {
  const { user } = useAuth();
  const [isCurrentlyCheckingForUpdatesOnProcesses, setIsCurrentlyCheckingForUpdatesOnProcesses] = useState(true);
  const [processes, setProcesses] = useState([]);
  const [animationDots, setAnimationDots] = useState('');

  useEffect(() => {
    if (!user) {
      setIsCurrentlyCheckingForUpdatesOnProcesses(false);
      return;
    }

    let intervalId;
    let animationIntervalId;

    const checkForUpdatesOnProcesses = async () => {
      try {
        const response = await API.get('status-updates/check-for-updates-on-processes');
        setProcesses(response.data.processes);

        if (response.data.processes.length === 0) {
          setIsCurrentlyCheckingForUpdatesOnProcesses(false);
        }
      } catch (error) {
        console.error('Error checking for updates on processes:', error);
      }
    };

    const updateAnimationDots = () => {
      setAnimationDots((prev) => {
        if (prev === '') return '.';
        if (prev === '.') return '..';
        if (prev === '..') return '...';
        return '';
      });
    };

    checkForUpdatesOnProcesses(); // Call checkForUpdatesOnProcesses immediately

    if (isCurrentlyCheckingForUpdatesOnProcesses) {
      intervalId = setInterval(checkForUpdatesOnProcesses, 5000);
      animationIntervalId = setInterval(updateAnimationDots, 600);
    }

    return () => {
      clearInterval(intervalId);
      clearInterval(animationIntervalId);
    };
  }, [isCurrentlyCheckingForUpdatesOnProcesses, user]);

  return (
    <StatusContext.Provider value={{ isCurrentlyCheckingForUpdatesOnProcesses, setIsCurrentlyCheckingForUpdatesOnProcesses, processes, animationDots }}>
      {children}
    </StatusContext.Provider>
  );
};