import React from 'react';

const H2Black = ({ children }) => {
  const titleStyle = {
    color: "var(--blue-darkest, #001331)",
    fontFamily: '"Roboto Mono", monospace',
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "135%",
    width: "100%",
    marginBottom: "12px"
  };

  return <h2 style={titleStyle}>{children}</h2>;
};

export default H2Black;