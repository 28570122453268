import React from "react";
import { FiRefreshCw } from "react-icons/fi";
import PrimaryButton from "./PrimaryButton";
import { useNavigate } from 'react-router-dom';

function SyncInitiativesWithJiraButton() {
   const navigate = useNavigate();

   const handleClick = () => {
      navigate('/jira-sync-filter-selection');
   };

   return (
      <PrimaryButton onClick={handleClick}>
         <div className="flex items-center">
            <FiRefreshCw className="mr-3 text-lg" />
            <span>Sync Initiatives with Jira</span>
         </div>
      </PrimaryButton>
   );
}

export default SyncInitiativesWithJiraButton;