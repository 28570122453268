import React from 'react';
import ROIArea from './ROIArea';

function ROIAreasStack({ roiData }) {
  return (
    <div>
      <ROIArea
        areaName="Amount Gained"
        roiItems={{
          equation_list: roiData.amount_gained_equation_list,
          variables: roiData.amount_gained_equation_list.filter(item => typeof item === 'object')
        }}
      />
      <ROIArea
        areaName="Amount Spent"
        roiItems={{
          equation_list: roiData.amount_spent_equation_list,
          variables: roiData.amount_spent_equation_list.filter(item => typeof item === 'object')
        }}
      />
    </div>
  );
}

export default ROIAreasStack;
