import React from "react";
import H3Gray from "../reusable_components/HeadingsAndTitles/H3Gray";
import StepItem from "../InitiativesPage/WelcomeSection/WelcomeStepsPictures";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";

const WelcomeToXProdOnboardingFlowPage1 = ({ onNextStep }) => {
   const titleStyle = {
      color: "var(--blue-darkest, #001331)",
      fontFamily: '"Roboto Mono"',
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "135%",
      marginTop: "60px"
   };

   const steps = [
      {
         imgSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/907c5880a5573eb5b76c490c3f5cc8db8fa08bea27fac8feb7d8ff5c570e4e22?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43",
         imgAlt: "Create initiatives icon",
         description: "Create initiatives for product ideas"
      },
      {
         imgSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/869b93ca1bbdbfad21c02b750dae4f95e6279f8320037aa01159c68ddecf096b?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43",
         imgAlt: "Upload data icon",
         description: "Upload relevant research and business data"
      },
      {
         imgSrc:
            "https://cdn.builder.io/api/v1/image/assets/TEMP/ebd3338a4bfb0641f56aa98d6eea2aed8771b8e239819d4857cf00c145a34104?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43",
         imgAlt: "View impact predictions icon",
         description: "View impact predictions to guide prioritization"
      }
   ];

   return (
      <div
         style={{
            display: "flex",
            paddingBottom: "200px",
            flexDirection: "column",
            alignItems: "center",
            gap: "100px"
         }}
      >
         <div
            style={{
               display: "flex",
               paddingBottom: "62px",
               flexDirection: "column",
               alignItems: "center",
               gap: "60px"
            }}
         >
            <div
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                  alignSelf: "stretch"
               }}
            >
               <h1 style={titleStyle}>Welcome to XProd!</h1>
               <H3Gray>Make informed decisions for your product, backed by data</H3Gray>
               <div
                  style={{
                     marginTop: "64px",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     gap: "24px"
                  }}
               >
                  {steps.map((step, index) => (
                     <React.Fragment key={index}>
                        <StepItem imgSrc={step.imgSrc} imgAlt={step.imgAlt} description={step.description} />
                        {index < steps.length - 1 && (
                           <img
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/14b89841b1654850585114cadf9b51afccb520f7e4097ba65b45b9d25e7e67da?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43"
                              alt=""
                              className="object-contain shrink-0 self-stretch my-auto aspect-[1.25] w-[30px]"
                           />
                        )}
                     </React.Fragment>
                  ))}
               </div>
            </div>
            <PrimaryButton
               style={{
                  display: "flex",
                  height: "48px",
                  padding: "17px 40px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "40px"
               }}
               onClick={onNextStep}
            >
               BEGIN SETUP
            </PrimaryButton>
         </div>
      </div>
   );
};

export default WelcomeToXProdOnboardingFlowPage1;
