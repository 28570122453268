import React, { useState } from 'react';
import API from '../../../../services/api'; // Adjust this import path as needed
import log from '../../../../utils/logger'; // Import the custom log function

function ROIArea({ areaName, roiItems, initiativeId }) {
  const [editedROIItems, setEditedROIItems] = useState(roiItems);
  const [editField, setEditField] = useState({});

  const handleChange = (itemName, field, value) => {
    setEditedROIItems((prevItems) => ({
      ...prevItems,
      variables: prevItems.variables.map(v => 
        v.abbreviation === itemName ? { ...v, [field]: value } : v
      )
    }));
  };

  const handleSave = async (itemName, field, value) => {
    setEditField((prev) => ({
      ...prev,
      [`${itemName}-${field}`]: false,
    }));

    try {
      await API.post('/metrics/update_roi_variable', {
        initiativeId,
        variableAbbreviation: itemName,
        field,
        value,
      });
      log('Updated ROI Items:', editedROIItems);
    } catch (error) {
      console.error('Error saving ROI variable:', error);
      // Optionally, revert the change in the UI if the save fails
    }
  };

  const handleEdit = (itemName, field) => {
    setEditField((prev) => ({
      ...prev,
      [`${itemName}-${field}`]: true,
    }));
  };

  const renderVariable = (variable) => (
    <li 
      key={variable.abbreviation} 
      className="mb-5 pb-5 border-b relative group"
    >
      <div className="flex justify-between items-center mb-2">
        <strong className="text-[#001331] font-['Roboto Mono'] text-lg font-medium leading-[135%]">
          {variable.title} ({variable.abbreviation})
        </strong>
        <div className="editable-field">
          {renderEditableField(variable.abbreviation, 'final_value_prediction', variable.final_value_prediction)}
        </div>
      </div>
      <div className="invisible group-hover:visible absolute z-10 bg-white border rounded-lg shadow-lg p-4 w-110 left-1/2 transform -translate-x-1/2 mt-2">
        <p className="mb-2"><strong>Description:</strong> {variable.description}</p>
        <p><strong>Reasoning:</strong> {variable.final_value_prediction_reasoning}</p>
      </div>
    </li>
  );

  const renderEditableField = (itemName, field, value) => (
    <div className="mb-3 flex items-center editable-field">
      {editField[`${itemName}-${field}`] ? (
        <input
          type="number"
          className="flex w-[137px] h-12 items-start p-2 border rounded"
          value={value || ''}
          onChange={(e) => handleChange(itemName, field, e.target.value)}
          onBlur={(e) => handleSave(itemName, field, e.target.value)}
          autoFocus
        />
      ) : (
        <span
          className="flex w-[137px] h-12 items-start cursor-pointer p-2 border rounded hover:border-blue-500"
          onClick={() => handleEdit(itemName, field)}
        >
          {value || 'N/A'}
        </span>
      )}
    </div>
  );

  const renderEquation = (equationList) => (
    <div className="mb-5">
      <p className="text-[#727F95] font-roboto-mono text-base font-medium leading-[135%] tracking-wider uppercase">
        {equationList.map((item, index) => (
          <span key={index}>
            {typeof item === 'string' ? item : item.abbreviation}
            {index < equationList.length - 1 ? ' ' : ''}
          </span>
        ))}
      </p>
    </div>
  );

  return (
    <div className="m-5 p-5 border rounded-lg bg-white shadow-md">
      <div className="flex flex-col items-start gap-2">
        <h3 className="text-[#001331] font-mono text-2xl font-medium leading-[200%]">
          {areaName}
        </h3>
        {renderEquation(editedROIItems.equation_list)}
      </div>
      <ul className="list-none p-0">
        {editedROIItems.variables.map(renderVariable)}
      </ul>
    </div>
  );
}

export default ROIArea;


