import axios from "axios";
import log from "../../utils/logger";
import API from "../../services/api";

const API_URL = `${process.env.REACT_APP_API_URL}auth/`;

class AuthService {
   login(username, password) {
      return axios
         .post(API_URL + "login", {
            username,
            password
         })
         .then((response) => {
            if (response.data.login && response.data.subscription_status === 'active') {
               localStorage.setItem("user", JSON.stringify(response.data));
               
               localStorage.setItem('xprod_refresh_token', response.data.refresh_token);
               
               localStorage.setItem('xprod_access_token_expiry', response.data.exp); // Store access token expiry
               
               window.dispatchEvent(new Event("authChange"));
            
            }
            return response.data;
         })
         .catch((error) => {
            if (error.response && error.response.data) {
               throw new Error(error.response.data.message);
            }
            throw error;
         });
   }

   logout() {
      localStorage.removeItem("user");
      localStorage.removeItem('userHasCompletedTheOnboardingFlowXProd');
      return axios
         .post(API_URL + "logout", {}, { withCredentials: true })
         .then((response) => {
            return response;
         })
         .catch((error) => {
            throw error;
         });
   }

   async register(userData) {
      try {
         const response = await axios.post(API_URL + "register", userData);
         if (response.status === 201) {
            return response.data;
         } else {
            throw new Error(`Registration failed with status: ${response.status}`);
         }
      } catch (error) {
         throw new Error("Error during registration: " + error.message);
      }
   }

   getCurrentUser() {
      return JSON.parse(localStorage.getItem("user"));
   }

   async getJWTToken() {
      const user = this.getCurrentUser();
      if (!user) {
         return null;
      }

      const currentTime = Date.now() / 1000; // Convert to seconds
      const accessTokenExpiry = localStorage.getItem('xprod_access_token_expiry');
      
      if (!accessTokenExpiry) {
         log("Error: Access token expiry is not set properly.");
         throw new Error("Access token expiry is not set properly.");
      }

      if (currentTime < accessTokenExpiry) {
         // Access token is still valid
         return user.access_token;
      } else {
         // Access token is expired, attempt to refresh
         try {
            const newAccessToken = await this.refreshAccessToken();
            return newAccessToken;
         } catch (error) {
            // Refresh failed, clear session and require new login
            log("Token refresh failed, logging out user.");
            this.logout();
            throw new Error('Session expired. Please log in again.');
         }
      }
   }

   async refreshAccessToken() {
      const refreshToken = localStorage.getItem('xprod_refresh_token');
      
      try {
         const response = await axios.post(API_URL + 'refresh-token', null, {
            headers: {
               'Authorization': `Bearer ${refreshToken}`
            }
         });

         const newAccessToken = response.data.access_token;
         const newAccessTokenExpiry = response.data.exp;
         

         // Update user object with new access token
         const user = this.getCurrentUser();
         user.access_token = newAccessToken;
         localStorage.setItem('user', JSON.stringify(user));

         // Update access token expiry in localStorage
         localStorage.setItem('xprod_access_token_expiry', newAccessTokenExpiry);
         
         return newAccessToken;
      } catch (error) {
         log("Error refreshing access token:", error);
         throw error;
      }
   }

   async getUserDetails() {
      const user = this.getCurrentUser();
      if (!user) {
         throw new Error("User not authenticated");
      }

      try {
         const response = await axios.get(API_URL + "user-details", {
            headers: {
               Authorization: `Bearer ${user.access_token}`
            }
         });
         return response.data;
      } catch (error) {
         log("Failed to fetch user details:", error);
         throw error;
      }
   }

   async updateUserDetails(details) {
      const user = this.getCurrentUser();
      if (!user) {
         throw new Error("User not authenticated");
      }

      try {
         const response = await axios.put(API_URL + "update-user-details", details, {
            headers: {
               Authorization: `Bearer ${user.access_token}`
            }
         });
         return response.data;
      } catch (error) {
         log("Failed to update user details:", error);
         throw error;
      }
   }

   async getUserAndCompanyDetails() {
      const user = this.getCurrentUser();
      if (!user) {
         throw new Error("User not authenticated");
      }

      try {
         const response = await axios.get(API_URL + "user-and-company-details", {
            headers: {
               Authorization: `Bearer ${user.access_token}`
            }
         });
         return response.data;
      } catch (error) {
         log("Failed to fetch user and company details:", error);
         throw error;
      }
   }

   async resetPassword(email) {
      try {
         const response = await axios.post(API_URL + "reset-password", { email });
         return response.data;
      } catch (error) {
         log("Error during password reset request:", error);
         throw new Error("Error during password reset request: " + error.message);
      }
   }

   async updatePassword(token, newPassword) {
      try {
         const response = await axios.post(API_URL + "update-password", { token, newPassword });
         return response.data;
      } catch (error) {
         log("Error during password update:", error);
         throw new Error("Error during password update: " + error.message);
      }
   }

   async getUserHasCompletedTheOnboardingFlowXProd() {
      const userHasCompletedTheOnboardingFlow = localStorage.getItem('userHasCompletedTheOnboardingFlowXProd');

      if (userHasCompletedTheOnboardingFlow === null) {
         try {
            const response = await API.get('onboarding/get-onboarding-flow-completion-status-for-this-user');
            const { userHasCompletedTheOnboardingFlow: hasCompleted } = response.data;
            localStorage.setItem('userHasCompletedTheOnboardingFlowXProd', JSON.stringify(hasCompleted));
            return hasCompleted;
         } catch (error) {
            log.error('Failed to retrieve onboarding flow completion status:', error);
            return false;
         }
      }

      return JSON.parse(userHasCompletedTheOnboardingFlow);
   }

   async updateUserHasCompletedTheOnboardingFlowInLocalStorageAndDB(hasCompleted) {
      try {
         await API.post('onboarding/set-onboarding-flow-completion-status-for-this-user', {
            userHasCompletedTheOnboardingFlow: hasCompleted,
         });
         localStorage.setItem('userHasCompletedTheOnboardingFlowXProd', JSON.stringify(hasCompleted));
      } catch (error) {
         log.error('Failed to update onboarding flow completion status:', error);
      }
   }
}

const authService = new AuthService();
export default authService;