import React, { useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MoreOptionsDropdownOnVerticalMenuOnDocViewerPageCard from './MoreOptionsDropdownOnVerticalMenuOnDocViewerPageCard';

function VerticalMenuOnDocViewerPage({ 
    vertical_menu_items_dict, 
    idOfInsightInFocus, 
    setIdOfInsightInFocus, 
    colorCodeByInsightTypeOrInsight,
    setColorCodeByInsightTypeOrInsight,
    displayReadyInsightTypeToShowDecisionMap,
    setDisplayReadyInsightTypeToShowDecisionMap,
    getInsightInstanceColor,
    setSelectedMentionId
}) {
    const [openDropdownId, setOpenDropdownId] = useState(null);

    const handleColorCodeChange = (event) => {
        setColorCodeByInsightTypeOrInsight(event.target.value);
    };

    const handleInsightTypeToggle = (insightType) => {
        setDisplayReadyInsightTypeToShowDecisionMap(prevState => ({
            ...prevState,
            [insightType]: !prevState[insightType]
        }));
    };

    const handleMoreOptionsClick = (e, id) => {
        e.stopPropagation();
        setOpenDropdownId(openDropdownId === id ? null : id);
    };

    const handleCloseDropdown = () => {
        setOpenDropdownId(null);
    };

    const handleCardClick = (id) => {
        setIdOfInsightInFocus(id);
        setSelectedMentionId(null); // Clear the selected mention when a card is clicked
    };

    return (
        <div className="vertical-menu w-80 bg-gray-100 p-4 overflow-y-auto h-screen sticky top-0">
            <div className="mb-4">
                <label htmlFor="colorCodeSelect" className="mr-2">Color by:</label>
                <select 
                    id="colorCodeSelect" 
                    value={colorCodeByInsightTypeOrInsight} 
                    onChange={handleColorCodeChange}
                    className="p-1 rounded"
                >
                    <option value="type">Insight Type</option>
                    <option value="insight">Insight</option>
                </select>
            </div>
            
            <div className="mb-4">
                <h4 className="font-semibold mb-2">Filter Insight Types:</h4>
                {Object.keys(displayReadyInsightTypeToShowDecisionMap).map(insightType => (
                    <FormControlLabel
                        key={insightType}
                        control={
                            <Checkbox
                                checked={displayReadyInsightTypeToShowDecisionMap[insightType]}
                                onChange={() => handleInsightTypeToggle(insightType)}
                                name={insightType}
                            />
                        }
                        label={insightType}
                    />
                ))}
            </div>

            {Object.entries(vertical_menu_items_dict).map(([id, item]) => (
                displayReadyInsightTypeToShowDecisionMap[item.displayReadyInsightType] && (
                    <div 
                        key={id} 
                        className="node-card mb-4 p-3 rounded-lg shadow cursor-pointer transition-all duration-300 ease-in-out relative"
                        style={{
                            backgroundColor: colorCodeByInsightTypeOrInsight === 'type' ? item.insightTypeColor : getInsightInstanceColor(id),
                            transform: id === idOfInsightInFocus ? 'scale(1.05)' : 'scale(1)',
                            border: id === idOfInsightInFocus ? '2px solid black' : 'none',
                        }}
                        onClick={() => handleCardClick(id)}
                    >
                        <div className="absolute top-2 right-2">
                            <button
                                onClick={(e) => handleMoreOptionsClick(e, id)}
                                className="text-gray-600 hover:text-gray-800"
                            >
                                <MdMoreVert size={20} />
                            </button>
                            {openDropdownId === id && (
                                <MoreOptionsDropdownOnVerticalMenuOnDocViewerPageCard
                                    onClose={handleCloseDropdown}
                                    insightType={item.displayReadyInsightType}
                                    insightId={id}
                                />
                            )}
                        </div>
                        <div className="text-sm font-semibold text-gray-600 mb-1">{item.displayReadyInsightType}</div>
                        <div className="text-lg font-bold mb-2">{item.title}</div>
                        <div className="text-sm text-gray-700 line-clamp-3">{item.description}</div>
                    </div>
                )
            ))}
        </div>
    );
}

export default VerticalMenuOnDocViewerPage;