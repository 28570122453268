import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import StepItem from "./WelcomeStepsPictures";
import InitiativeForm from "./WelcomeSectionManualInitiativeCreationForm";

const steps = [
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/907c5880a5573eb5b76c490c3f5cc8db8fa08bea27fac8feb7d8ff5c570e4e22?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43",
    imgAlt: "Create initiatives icon",
    description: "Create initiatives for product ideas",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/869b93ca1bbdbfad21c02b750dae4f95e6279f8320037aa01159c68ddecf096b?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43",
    imgAlt: "Upload data icon",
    description: "Upload relevant research and business data",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/ebd3338a4bfb0641f56aa98d6eea2aed8771b8e239819d4857cf00c145a34104?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43",
    imgAlt: "View impact predictions icon",
    description: "View impact predictions to guide prioritization",
  },
];

function WelcomeSection() {
  const [showInitiativeForm, setShowInitiativeForm] = useState(false);
  const navigate = useNavigate();

  const handleCreateManually = () => {
    setShowInitiativeForm(true);
  };

  const handleSaveInitiative = (newInitiative) => {
    setShowInitiativeForm(false);
  };

  const handleCloseForm = () => {
    setShowInitiativeForm(false);
  };

  return (
    <div
      className="flex flex-col items-center w-full"
      style={{ background: "var(--blue-light, #F6F9FF)" }}
    >
      <div className="h-[48px]"></div>

      <main className="flex flex-col w-[880px] pb-[200px] items-center gap-[100px]">
        <section className="flex flex-col items-center gap-16 self-stretch">
          <h1 className="self-stretch text-[#001331] font-['Roboto_Mono'] text-2xl font-medium leading-[135%]">
            Welcome to XProd
          </h1>
          <div className="flex justify-center items-center gap-6">
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <StepItem
                  imgSrc={step.imgSrc}
                  imgAlt={step.imgAlt}
                  description={step.description}
                />
                {index < steps.length - 1 && (
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/14b89841b1654850585114cadf9b51afccb520f7e4097ba65b45b9d25e7e67da?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43"
                    alt=""
                    className="object-contain shrink-0 self-stretch my-auto aspect-[1.25] w-[30px]"
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </section>
        <section className="flex flex-col gap-12 w-full">
          <div className="flex flex-col gap-2.5">
            <h2 className="text-2xl font-medium leading-none text-slate-900">
              Create your first initiative
            </h2>
            <p className="text-base leading-6 text-slate-500">
              Initiatives help you prioritize roadmap items, supported by
              intelligent insights and ROI predictions
            </p>
          </div>
          <div className="flex flex-col items-center w-full">
            {!showInitiativeForm ? (
              <div className="flex justify-center items-center gap-9">
                <button
                  onClick={handleCreateManually}
                  className="flex h-12 px-10 py-[17px] justify-center items-center gap-2 rounded-[999px] bg-[#104499] shadow-[0px_8px_16px_0px_rgba(16,68,153,0.05),_0px_0px_8px_0px_rgba(16,68,153,0.05)] text-white font-medium uppercase"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    className="w-5 h-[19.121px]"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.2071 2.76776C15.4174 2.55747 15.7026 2.43933 16 2.43933C16.1473 2.43933 16.2931 2.46833 16.4291 2.52469C16.5652 2.58104 16.6888 2.66363 16.7929 2.76776C16.897 2.87188 16.9796 2.9955 17.036 3.13154C17.0923 3.26759 17.1213 3.4134 17.1213 3.56065C17.1213 3.7079 17.0923 3.85372 17.036 3.98976C16.9796 4.12581 16.897 4.24942 16.7929 4.35354L4.48877 16.6577L2.37439 17.1863L2.90299 15.0719L15.2071 2.76776ZM16 0.439331C15.1722 0.439331 14.3783 0.768183 13.7929 1.35354L1.29291 13.8535C1.16476 13.9817 1.07384 14.1423 1.02988 14.3181L0.0298787 18.3181C-0.055315 18.6589 0.0445341 19.0194 0.292914 19.2678C0.541295 19.5161 0.901782 19.616 1.24256 19.5308L5.24256 18.5308C5.41839 18.4868 5.57897 18.3959 5.70713 18.2678L18.2071 5.76776C18.497 5.47792 18.7269 5.13383 18.8837 4.75513C19.0406 4.37643 19.1213 3.97055 19.1213 3.56065C19.1213 3.15075 19.0406 2.74487 18.8837 2.36617C18.7269 1.98748 18.497 1.64339 18.2071 1.35354C17.9173 1.0637 17.5732 0.833788 17.1945 0.676927C16.8158 0.520066 16.4099 0.439331 16 0.439331ZM10 17.5607C9.44774 17.5607 9.00002 18.0084 9.00002 18.5607C9.00002 19.1129 9.44774 19.5607 10 19.5607H19C19.5523 19.5607 20 19.1129 20 18.5607C20 18.0084 19.5523 17.5607 19 17.5607H10Z"
                      fill="#90BAFF"
                    />
                  </svg>
                  Create Manually
                </button>
                <span className="text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[135%]">
                  OR
                </span>
                <button
                  onClick={() => navigate("/data-integrations")}
                  className="flex h-12 px-10 py-[17px] justify-center items-center gap-2 rounded-[999px] bg-[#104499] shadow-[0px_8px_16px_0px_rgba(16,68,153,0.05),_0px_0px_8px_0px_rgba(16,68,153,0.05)] text-white font-medium uppercase"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="w-6 h-6"
                  >
                    <path
                      d="M23.712 11.328L13.035 1.0005L12 0L3.963 7.7745L0.288 11.3295C0.197474 11.4162 0.125325 11.5203 0.0758475 11.6355C0.0263698 11.7507 0.000575439 11.8746 0 12C0 12.252 0.1035 12.4935 0.288 12.672L7.6305 19.7745L12 24L20.037 16.2255L20.1615 16.1055L23.712 12.6705C23.8025 12.5838 23.8747 12.4797 23.9242 12.3645C23.9736 12.2493 23.9994 12.1254 24 12C23.9996 11.8744 23.9739 11.7501 23.9244 11.6347C23.875 11.5192 23.8027 11.4149 23.712 11.328ZM12 15.5475L8.331 12L12 8.4525L15.669 12L12 15.5475Z"
                      fill="#2684FF"
                    />
                    <path
                      d="M12.0001 8.45098C11.4325 7.90661 10.9799 7.25386 10.6691 6.53139C10.3584 5.80891 10.1958 5.03142 10.1911 4.24498C10.1908 3.45831 10.3486 2.67962 10.6549 1.95506C10.9613 1.23051 11.4101 0.57485 11.9746 0.0269775L3.94507 7.78948L8.31457 12.015L12.0001 8.45098Z"
                      fill="url(#paint0_linear_11_304)"
                    />
                    <path
                      d="M15.678 11.991L12 15.5475C12.5745 16.1025 13.029 16.7625 13.3395 17.487C13.6494 18.2098 13.8092 18.9881 13.8092 19.7745C13.8092 20.561 13.6494 21.3392 13.3395 22.062C13.029 22.7865 12.5745 23.445 12 24L20.0475 16.2165L15.678 11.991Z"
                      fill="url(#paint1_linear_11_304)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_11_304"
                        x1="11.3446"
                        y1="4.86148"
                        x2="6.39307"
                        y2="9.98098"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.18" stopColor="#0052CC" />
                        <stop offset="1" stopColor="#2684FF" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_11_304"
                        x1="324.082"
                        y1="735.924"
                        x2="497.47"
                        y2="619.737"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.18" stopColor="#0052CC" />
                        <stop offset="1" stopColor="#2684FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  Import from Jira
                </button>
              </div>
            ) : (
              <InitiativeForm
                onClose={handleCloseForm}
                onSave={handleSaveInitiative}
              />
            )}
          </div>
        </section>
      </main>
    </div>
  );
}

export default WelcomeSection;