import React from 'react';

const PainPointCard = ({ count }) => {
  return (
    <div className="flex items-center gap-2 w-[139px] px-3 py-1 rounded-lg bg-[#FFECEC]">
      <span className="text-xl font-bold tracking-wide text-red-400">{count}</span>
      <span className="text-sm font-medium tracking-wide text-slate-500 whitespace-nowrap">Pain points</span>
    </div>
  );
};

export default PainPointCard;