import React from "react";

function HeadwindAndTailwindCard({ headwindOrTailwind }) {
  return (
    <div className="bg-white border border-gray-300 rounded-lg p-6 shadow-lg flex flex-col h-full">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-xl font-bold text-teal-600">{headwindOrTailwind.title}</h4>
        <div className={`${headwindOrTailwind.type === 'headwind' ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'} font-semibold py-1 px-2 rounded-full text-sm`}>
          {headwindOrTailwind.type === 'headwind' ? 'Headwind' : 'Tailwind'}
        </div>
      </div>
      
      {headwindOrTailwind.summary && (
        <p className="text-base mb-4">{headwindOrTailwind.summary}</p>
      )}
      
      {headwindOrTailwind.past_impacts && (
        <div className="mb-4">
          <h5 className="font-semibold mb-1">Past Impacts:</h5>
          <p className="text-sm">{headwindOrTailwind.past_impacts}</p>
        </div>
      )}
      
      {headwindOrTailwind.mitigation_plans && (
        <div className="mt-auto">
          <h5 className="font-semibold mb-1">Mitigation Plans:</h5>
          <p className="text-sm">{headwindOrTailwind.mitigation_plans}</p>
        </div>
      )}
    </div>
  );
}

export default HeadwindAndTailwindCard;

