import React, { useEffect, useMemo, useState, useCallback } from 'react';
import WelcomeSection from "../WelcomeSection/WelcomeSectionPage";
import SearchBar from './SearchBar';
import InitiativesBoxRenderer from './InitiativesBoxRenderer';
import LoadingSpinner from '../../reusable_components/LoadingSpinner/LoadingSpinner';
import { useInitiativesPage } from '../../../contexts/InitiativesPageContext';
import { useIntegrations } from '../../../contexts/IntegrationsContext';
import Pagination from './Pagination';

const InitiativesPage = () => {
  const { 
    initiatives,
    filteredInitiatives,
    totalInitiatives,
    pageLoading: isLoading,
    defaultPageLoader,
    currentPage,
    setCurrentPageNumber,
    searchPageLoader,
    searchMode
  } = useInitiativesPage();

  const { integrations } = useIntegrations();

  useEffect(() => {
    console.log('Current page:', currentPage, 'Search mode:', searchMode);
    if (searchMode) {
      searchPageLoader();
    } else {
      defaultPageLoader(currentPage);
    }
  }, [defaultPageLoader, searchPageLoader, currentPage, searchMode]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPageNumber(newPage);
  }, [setCurrentPageNumber]);

  const showWelcomeSection = useMemo(() => 
    initiatives.length === 0 && !integrations.jira,
    [initiatives, integrations.jira]
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col px-3 pt-2" style={{ backgroundColor: '#f6f9ff' }}>
      <main className={`flex flex-col self-center pb-52 mt-12 max-w-full w-[880px] max-md:pb-24 max-md:mt-10`}>
        <div className="flex gap-2 items-center self-start font-medium leading-none whitespace-nowrap">
          <h1 className="self-stretch my-auto text-2xl text-slate-900">Initiatives</h1>
          <div className="self-stretch my-auto text-xl text-slate-500">
            ({totalInitiatives})
          </div>
        </div>
        {!showWelcomeSection && <SearchBar />}
        {showWelcomeSection ? <WelcomeSection /> : <InitiativesBoxRenderer />}
      </main>
      <Pagination
        currentPage={currentPage}
        totalInitiatives={totalInitiatives}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default React.memo(InitiativesPage);