import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";

const ResetPasswordForm = () => {
   const { updatePassword } = useAuth();
   const { token } = useParams();
   const [newPassword, setNewPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");

   const handleResetPassword = async (event) => {
      event.preventDefault();
      if (newPassword !== confirmPassword) {
         alert("Passwords do not match.");
         return;
      }
      try {
         await updatePassword(token, newPassword);
         alert("Password reset successful. You can now log in with your new password.");
      } catch (error) {
         console.error("Password reset failed:", error);
         alert("Password reset failed. Please try again.");
      }
   };

   return (
      <div>
         <PageTitle>Reset Password</PageTitle>
         <form onSubmit={handleResetPassword} className="max-w-sm mx-auto mt-10 p-5 border rounded-lg shadow-lg">
            <div className="mb-4">
               <label htmlFor="newPassword" className="block text-gray-700 text-sm font-bold mb-2">
                  New Password:
               </label>
               <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               />
            </div>
            <div className="mb-4">
               <label htmlFor="confirmPassword" className="block text-gray-700 text-sm font-bold mb-2">
                  Confirm Password:
               </label>
               <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               />
            </div>
            <PrimaryButton type="submit">Reset Password</PrimaryButton>
         </form>
      </div>
   );
};

export default ResetPasswordForm;
