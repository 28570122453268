import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import log from "../../../utils/logger";

function ObjectiveCard({ objective }) {
   const navigate = useNavigate();

   useEffect(() => {
      log("ObjectiveCard component created");
   }, []);

   const handleClick = () => {
      navigate(`/insights/objective/${objective.id}`);
   };

   return (
      <div
         className="flex w-full h-40 pr-4 items-start gap-3 flex-shrink-0 rounded-lg bg-white shadow-[0px_2px_8px_0px_rgba(114,127,149,0.25)] cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl"
         onClick={handleClick}
      >
         <div className="w-[6px] h-full self-stretch rounded-l-lg bg-[#0AA476]" />
         <div className="flex flex-col flex-1 pt-4">
            <div className="flex items-start gap-3 mb-2">
               <h4 className="text-[#001331] font-rubik text-base font-medium leading-[145%] line-clamp-2">{objective.title}</h4>
            </div>
            <p className="text-[#727F95] font-rubik text-sm font-normal leading-[120%] line-clamp-3">{objective.qualitative_summary}</p>
         </div>
      </div>
   );
}

export default ObjectiveCard;
