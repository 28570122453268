import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";

const ForgotPasswordForm = () => {
   const { resetPassword } = useAuth();
   const [email, setEmail] = useState("");

   const handleResetPassword = async (event) => {
      event.preventDefault();
      try {
         await resetPassword(email);
         alert("Password reset email sent. Please check your inbox.");
      } catch (error) {
         console.error("Password reset request failed:", error);
         alert("Password reset request failed. Please try again.");
      }
   };

   return (
      <div>
         <PageTitle>Forgot Password</PageTitle>
         <form onSubmit={handleResetPassword} className="max-w-sm mx-auto mt-10 p-5 border rounded-lg shadow-lg">
            <div className="mb-4">
               <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
                  Email:
               </label>
               <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               />
            </div>
            <PrimaryButton type="submit">Reset Password</PrimaryButton>
         </form>
      </div>
   );
};

export default ForgotPasswordForm;
