import React from "react";
import PageTitle from "../../../reusable_components/HeadingsAndTitles/PageTitle";
import ROIEquation from "./ROIEquationComponent/ROIEquation";
import ROIAreasStack from "./ROIAreasStack";

function ROICalculations({ data }) {
   const roiValue = parseFloat(data.value);
   const formattedROI = `${(roiValue * 100).toFixed(2)}%`;

   return (
      <div>
         <PageTitle>Return on Investment (ROI) Breakdown</PageTitle>
         <ROIEquation
            value={data.value}
            amountGained={data.amount_gained_value}
            amountSpent={data.amount_spent_value}
         />
         <ROIAreasStack roiData={data} />
      </div>
   );
}

export default ROICalculations;
