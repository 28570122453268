import React, { useState, useEffect } from "react";
import { FaPlus, FaTrash, FaCopy } from "react-icons/fa";
import API from "../services/api";
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../contexts/AuthContext';

function TeamInformationPage() {
    const [team, setTeam] = useState([]);
    const [customTeams, setCustomTeams] = useState([]);
    const [customRoles, setCustomRoles] = useState([]);
    const { user } = useAuth();

    useEffect(() => {
        if (user && user.user && user.user.userId) {
            fetchTeamInfo(user.user.userId);
        }
    }, [user]);

    const fetchTeamInfo = async (userId) => {
        try {
            const response = await API.get(`/user/${userId}/team-info`);
            if (response.data && response.data.team && response.data.team.length > 0) {
                setTeam(response.data.team.map(member => ({
                    ...member,
                    id: member.id || uuidv4(),
                })));

                // Update custom options
                const customTeams = response.data.team
                    .map(member => member.team)
                    .filter(team => team && !["Human Resources", "Finance and Accounting", "Sales", "Marketing", "Product", "Operations", "Information Technology", "Engineering", "Management"].includes(team));
                const customRoles = response.data.team
                    .map(member => member.role)
                    .filter(role => role && !["Project Manager", "Product Manager", "Backend Software Engineer", "Frontend Software Engineer", "Fullstack Software Engineer", "Frontend Designer"].includes(role));

                setCustomTeams([...new Set(customTeams)]);
                setCustomRoles([...new Set(customRoles)]);
            } else {
                // If no team members, set a blank one
                setTeam([{ id: uuidv4(), name: "", team: "", role: "", responsibilities: "" }]);
            }
        } catch (error) {
            console.error("Error fetching team information:", error);
            // If there's an error, still set a blank team member
            setTeam([{ id: uuidv4(), name: "", team: "", role: "", responsibilities: "" }]);
        }
    };

    const handleTeamChange = (index, field, value) => {
        setTeam(prevTeam => {
            const newTeam = [...prevTeam];
            newTeam[index] = {
                ...newTeam[index],
                [field]: value,
                [`is${field.charAt(0).toUpperCase() + field.slice(1)}Custom`]: value === "Custom..." ? true : newTeam[index][`is${field.charAt(0).toUpperCase() + field.slice(1)}Custom`]
            };

            if (value === "Custom...") {
                newTeam[index][field] = "";
            }

            return newTeam;
        });
    };

    const addTeamMember = (index) => {
        setTeam(prevTeam => {
            const newTeam = [...prevTeam];
            newTeam.splice(index + 1, 0, { id: uuidv4(), name: "", team: "", role: "", responsibilities: "" });
            return newTeam;
        });
    };

    const removeTeamMember = (index) => {
        setTeam(prevTeam => {
            if (prevTeam.length > 1) {
                return prevTeam.filter((_, i) => i !== index);
            } else {
                // If it's the last team member, clear its fields instead of removing it
                return [{ id: uuidv4(), name: "", team: "", role: "", responsibilities: "" }];
            }
        });
    };

    const copyTeamMember = (index) => {
        setTeam(prevTeam => {
            const newTeam = [...prevTeam];
            const memberToCopy = { ...newTeam[index], id: uuidv4() };
            newTeam.splice(index + 1, 0, memberToCopy);
            return newTeam;
        });
    };

    const handleSave = async () => {
        try {
            if (user && user.user && user.user.userId) {
                await API.post(`/user/${user.user.userId}/team-info`, { team });
                alert("Team information saved successfully!");
            } else {
                throw new Error("User ID not found");
            }
        } catch (error) {
            console.error("Error saving team information:", error);
            alert("Failed to save team information. Please try again.");
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h2 className="text-2xl mb-4">Team Members</h2>
            <div className="mb-4">
                {team.map((member, index) => (
                    <div key={member.id} className="mb-4 p-4 border border-gray-300 rounded-md relative">
                        <div className="absolute top-2 right-2 flex space-x-2">
                            <button
                                onClick={() => addTeamMember(index)}
                                className="text-blue-500 hover:text-blue-700"
                            >
                                <FaPlus />
                            </button>
                            <button
                                onClick={() => copyTeamMember(index)}
                                className="text-blue-500 hover:text-blue-700"
                            >
                                <FaCopy />
                            </button>
                            <button
                                onClick={() => removeTeamMember(index)}
                                className="text-blue-500 hover:text-blue-700"
                            >
                                <FaTrash />
                            </button>
                        </div>
                        <h3 className="text-lg font-semibold mb-2">Team Member {index + 1}</h3>
                        <label className="block text-sm font-medium text-gray-700">Name:</label>
                        <input
                            type="text"
                            value={member.name}
                            onChange={(e) => handleTeamChange(index, "name", e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <label className="block text-sm font-medium text-gray-700 mt-2">Team:</label>
                        <select
                            value={member.isTeamCustom ? "Custom..." : member.team}
                            onChange={(e) => handleTeamChange(index, "team", e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="">Select a team</option>
                            <option value="Human Resources">Human Resources</option>
                            <option value="Finance and Accounting">Finance and Accounting</option>
                            <option value="Sales">Sales</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Product">Product</option>
                            <option value="Operations">Operations</option>
                            <option value="Information Technology">Information Technology</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Management">Management</option>
                            {customTeams.map((customTeam, idx) => (
                                <option key={idx} value={customTeam}>{customTeam}</option>
                            ))}
                            <option value="Custom...">Custom...</option>
                        </select>
                        {member.isTeamCustom && (
                            <input
                                type="text"
                                value={member.team}
                                onChange={(e) => handleTeamChange(index, "team", e.target.value)}
                                placeholder="Enter custom team"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        )}
                        <label className="block text-sm font-medium text-gray-700 mt-2">Role:</label>
                        <select
                            value={member.isRoleCustom ? "Custom..." : member.role}
                            onChange={(e) => handleTeamChange(index, "role", e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="">Select a role</option>
                            <option value="Project Manager">Project Manager</option>
                            <option value="Product Manager">Product Manager</option>
                            <option value="Backend Software Engineer">Backend Software Engineer</option>
                            <option value="Frontend Software Engineer">Frontend Software Engineer</option>
                            <option value="Fullstack Software Engineer">Fullstack Software Engineer</option>
                            <option value="Frontend Designer">Frontend Designer</option>
                            {customRoles.map((customRole, idx) => (
                                <option key={idx} value={customRole}>{customRole}</option>
                            ))}
                            <option value="Custom...">Custom...</option>
                        </select>
                        {member.isRoleCustom && (
                            <input
                                type="text"
                                value={member.role}
                                onChange={(e) => handleTeamChange(index, "role", e.target.value)}
                                placeholder="Enter custom role"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        )}
                        <label className="block text-sm font-medium text-gray-700 mt-2">Responsibilities:</label>
                        <textarea
                            value={member.responsibilities}
                            onChange={(e) => handleTeamChange(index, "responsibilities", e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            rows="3"
                        />
                    </div>
                ))}
            </div>
            <div className="flex justify-end">
                <button
                    onClick={handleSave}
                    className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700"
                >
                    Save Team Information
                </button>
            </div>
        </div>
    );
}

export default TeamInformationPage;