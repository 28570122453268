import React from 'react';
import { Link } from 'react-router-dom';

const PaymentCancel = () => {
    return (
        <div>
            <h2>Payment Cancelled</h2>
            <p>Your payment was not completed. You can try again when you're ready.</p>
            <Link to="/payment">Return to Payment Page</Link>
        </div>
    );
};

export default PaymentCancel;
