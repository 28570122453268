import React from "react";
import log from "../../utils/logger";
import SyncInitiativesWithJiraButton from "../reusable_components/Buttons/SyncInitiativesWithJiraButton";
import API from "../../services/api";
import { useIntegrations } from "../../contexts/IntegrationsContext";

function JiraIntegrationBox({ user, renderIntegrationButtonFunction }) {
   const { integrations } = useIntegrations();
   const name = "Jira";
   const category = "Developer tool";
   const description = "Jira helps every team reach their full potential with powerful workflow and project tracking.";
   const imgSrc = `${process.env.PUBLIC_URL}/assets/external_integration_logos/jira_logo.png`;
   log("The image source URL is:", imgSrc);
   const altText = "Jira logo";

   const handleIntegrationClickJira = async () => {
      const service_name = "jira";
      log("In handleIntegrationClickJira");

      const token = user ? user.access_token : null;
      if (token) {
         const state_value = btoa(JSON.stringify({ user_id: user.id, timestamp: new Date().getTime() }));
         log("Generated state_value:", state_value);
         const storeStateUrl = `${process.env.REACT_APP_BACKEND_URL}/api/integrations_general/store_state_value_for_oauth2_flow_for_an_external_integration`;
         log("Store State URL:", storeStateUrl);
         try {
            await API.post(storeStateUrl, { state_value, external_tool_name: service_name });
            log("State stored successfully");
         } catch (error) {
            console.error("Failed to store state:", error);
            return;
         }
         const authorizationURL = `${process.env.REACT_APP_JIRA_AUTHORIZATION_URL_PREFIX}${state_value}&response_type=code&prompt=consent`;
         window.location.href = authorizationURL;
      } else {
         console.error("No token found, user must be logged in to add integration.");
      }
   };

   return (
      <section className="flex flex-col p-4 bg-white rounded-lg leading-[135%] max-w-[388px]">
         <article className="flex gap-4">
            <img loading="lazy" src={imgSrc} alt={altText} className="shrink-0 w-14 aspect-square" />
            <div className="flex flex-col flex-1 my-auto">
               <h1 className="text-lg font-medium text-slate-900">{name}</h1>
               <span className="mt-1 text-sm tracking-wide uppercase text-slate-500">{category}</span>
            </div>
         </article>
         <p className="mt-4 text-sm leading-5 text-slate-900 h-16">{description}</p>
         {renderIntegrationButtonFunction("jira", handleIntegrationClickJira)}
         {integrations.jira && <SyncInitiativesWithJiraButton />}
      </section>
   );
}

export default JiraIntegrationBox;
