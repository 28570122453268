import React from 'react';
import styled from 'styled-components';

const PrimaryButtonContainer = styled.button`
  display: inline-flex;
  height: 48px;
  padding: 17px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 999px;
  background: ${props => props.disabled ? 'var(--slate-light, #C5D1E4)' : 'var(--blue-dark, #104499)'};
  box-shadow: 0px 8px 16px 0px rgba(16, 68, 153, 0.05), 0px 0px 8px 0px rgba(16, 68, 153, 0.05);
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

  &:hover {
    background: ${props => props.disabled ? 'var(--slate-light, #C5D1E4)' : 'var(--blue-medium, #628FDA)'};
  }
`;

const ButtonText = styled.span`
  color: var(--white, var(--white, #FFF));
  font-family: "Roboto Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 0.32px;
  text-transform: uppercase;
`;

const ButtonIcon = styled.img`
  width: 20px;
  height: 19.121px;
  filter: brightness(0) invert(1); // This makes the icon white
`;

const PrimaryButton = ({ 
  children, // The text content of the button
  disabled = false, // A boolean indicating whether the button is disabled
  style = {}, // An object containing additional styles to override the default styles
  icon = null, // The URL or path to the icon image to be displayed on the left side of the button text
  onClick = () => {}, // A function to handle the button click event
}) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <PrimaryButtonContainer 
      disabled={disabled} 
      style={style}
      onClick={handleClick}
    >
      {icon && <ButtonIcon src={icon} alt="Button Icon" />}
      <ButtonText>{children}</ButtonText>
    </PrimaryButtonContainer>
  );
};

export default PrimaryButton;