import React from 'react';

const ExpandCollapseArrow = ({ width = 24, height = 24, fill = '#104499', rotation = 0 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: `rotate(${rotation}deg)` }}>
      <g id="chevron_right_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24 1">
        <path
          id="Vector"
          d="M12.8006 12L8 7.11828L9.0997 6L15 12L9.0997 18L8 16.8817L12.8006 12Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default ExpandCollapseArrow;