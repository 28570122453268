import React from 'react';
import { ReactComponent as SearchMagnifyingGlassIcon } from '../../../assets/icons/SearchMagnifyingGlassIcon.svg';


function FindOnPageTextInputAndSearchIcon({ searchText, onSearchTextChange = () => {}, onSearchSubmit = () => {}, maxChars = 50, placeholder }) {
  return (
    <div className="find-on-page-input-container" style={{
      display: 'flex',
      padding: '9px 20px',
      alignItems: 'center',
      gap: '8px',
      flex: '1 0 0',
      alignSelf: 'stretch',
      borderRadius: '12px',
      border: '1px solid var(--slate-lighter, #E5EAF3)',
      background: 'var(--blue-lighter, #FBFCFC)'
    }}>
        <input
          type="text"
          placeholder={placeholder}
          value={searchText}
          onChange={(e) => onSearchTextChange(e.target.value)}
          maxLength={maxChars}
          style={{
            flex: '1 0 0',
            color: 'var(--slate-dark, #727F95)',
            fontFamily: '"Roboto Mono"',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '130%',
            border: 'none',
            background: 'transparent',
            outline: 'none'
          }}
        />
        <div style={{
          width: '39px',
          color: 'var(--slate-dark, #727F95)',
          fontFamily: '"Roboto Mono"',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '150%'
        }}>
          {searchText.length}/{maxChars}
        </div>
        <SearchMagnifyingGlassIcon style={{ width: '20.017px', height: '20.017px' }} />
    </div>
  );
}

export default FindOnPageTextInputAndSearchIcon;