import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../services/api';
import VerticalMenuOnDocViewerPage from './VerticalMenuOnDocViewerPage';
import DocPortionOfDocViewerPage from './DocPortionOfDocViewerPage';
import PageLoadSpinner from '../reusable_components/PageLoadSpinner/PageLoadSpinner';

const makeInsightTypeFieldDisplayReady = (insightTypeAsWrittenInDataModel) => {
    const displayReadyInsightType = insightTypeAsWrittenInDataModel
        .replace(/individual|aggregate/gi, '')
        .replace(/_/g, ' ')
        .trim()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    
    // console.log('Display-ready insight type:', displayReadyInsightType);
    return displayReadyInsightType;
};

const getInsightTypeColor = (insightType) => {
    const colorMap = {
        'Pain Point': '#fcecec',
        'Feature Request': '#e5efff',
        'Objective': '#ce71e8',
        'Quantitative Statement': '#CFF3EB'
    };
    return colorMap[insightType] || 'transparent';
};

const DocViewerPage = () => {
    const [pageData, setPageData] = useState(null);
    const [verticalMenuItemsDict, setVerticalMenuItemsDict] = useState({});
    const [mentionsWithColors, setMentionsWithColors] = useState([]);
    const [idOfInsightInFocus, setIdOfInsightInFocus] = useState(null);
    const [colorCodeByInsightTypeOrInsight, setColorCodeByInsightTypeOrInsight] = useState('type');
    const [displayReadyInsightTypeToShowDecisionMap, setDisplayReadyInsightTypeToShowDecisionMap] = useState({});
    const [insightInstanceColorMap, setInsightInstanceColorMap] = useState({});
    const { fileMetadataDocMongoId, initialMentionIdToScrollTo } = useParams();
    const [initialMentionId, setInitialMentionId] = useState(null);
    const mentionRef = useRef(null);
    const [selectedMentionId, setSelectedMentionId] = useState(null);

    const getInsightInstanceColor = (insightId) => {
        if (insightInstanceColorMap[insightId]) {
            return insightInstanceColorMap[insightId];
        }

        const generateDistinctColor = () => {
            const existingColors = Object.values(insightInstanceColorMap);
            let newColor;
            do {
                newColor = '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0');
            } while (existingColors.includes(newColor));
            return newColor;
        };

        const newColor = generateDistinctColor();
        setInsightInstanceColorMap(prevMap => {
            const updatedMap = {...prevMap, [insightId]: newColor};
            // console.log('Updated insightInstanceColorMap:', updatedMap);
            return updatedMap;
        });
        return newColor;
    };

    useEffect(() => {
        if (!pageData) {
            API.get(`/doc-viewer-page/get-doc-viewer-page-data?filemetadata_doc_mongo_id=${fileMetadataDocMongoId}`)
                .then(response => {
                    setPageData(response.data);
                    processPageData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching doc viewer page data:', error);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageData, fileMetadataDocMongoId]);

    useEffect(() => {
        // Set the initialMentionId from the URL parameter if it exists
        if (initialMentionIdToScrollTo) {
            setInitialMentionId(initialMentionIdToScrollTo);
        }
    }, [initialMentionIdToScrollTo]);

    useEffect(() => {
        if (pageData && initialMentionId) {
            const mentionToScrollTo = mentionsWithColors.find(mention => mention.mentionId === initialMentionId);
            if (mentionToScrollTo) {
                setIdOfInsightInFocus(mentionToScrollTo.insightId);
            }
        }
    }, [pageData, initialMentionId, mentionsWithColors]);

    useEffect(() => {
        if (initialMentionIdToScrollTo) {
            setSelectedMentionId(initialMentionIdToScrollTo);
        }
    }, [initialMentionIdToScrollTo]);

    const processPageData = (data) => {
        const verticalMenuItems = {};
        let mentionsArray = [];
        const uniqueDisplayReadyInsightTypes = new Set();

        data.mentions_with_properties.forEach(mention => {
            const originalInsightType = mention.related_insight_properties.node_type || mention.related_insight_properties.type || mention.related_insight_type;
            const displayReadyInsightType = makeInsightTypeFieldDisplayReady(originalInsightType);

            uniqueDisplayReadyInsightTypes.add(displayReadyInsightType);

            const insightTypeColor = getInsightTypeColor(displayReadyInsightType);
            const insightId = mention.related_insight_id;
            const insightInstanceColor = getInsightInstanceColor(insightId);

            verticalMenuItems[insightId] = {
                title: mention.related_insight_properties.title || 'Untitled',
                insightTypeColor: insightTypeColor,
                insightInstanceColor: insightInstanceColor,
                displayReadyInsightType: displayReadyInsightType
            };

            // console.log('Mention ID:', mention.id);
            // console.log('mention.span_text:', mention.span_text);
            mentionsArray.push({
                mentionId: mention.id,
                insightId: insightId,
                span_indices: mention.span_indices_in_doc[0],
                insightTypeColor: insightTypeColor,
                insightInstanceColor: insightInstanceColor,
                text: mention.span_text,
                title: mention.related_insight_properties.title || 'Untitled',
                insightType: displayReadyInsightType,
                description: mention.related_insight_properties.description || '',
                impact_score: mention.related_insight_properties.impact_score,
                created_at: mention.related_insight_properties.created_at
            });

            mention.related_insight_properties.displayReadyInsightType = displayReadyInsightType;
        });

        mentionsArray.sort((a, b) => a.span_indices[0] - b.span_indices[0]);

        // logOverlappingMentions(mentionsArray);

        setVerticalMenuItemsDict(verticalMenuItems);
        setMentionsWithColors(mentionsArray);

        // Create displayReadyInsightTypeToShowDecisionMap
        const insightTypeShowMap = {};
        uniqueDisplayReadyInsightTypes.forEach(insightType => {
            insightTypeShowMap[insightType] = true; // Initially set all to true
        });
        setDisplayReadyInsightTypeToShowDecisionMap(insightTypeShowMap);

        // Log the value of displayReadyInsightTypeToShowDecisionMap
        // console.log('displayReadyInsightTypeToShowDecisionMap:', insightTypeShowMap);
    };

    const logOverlappingMentions = (mentions) => {
        for (let i = 0; i < mentions.length; i++) {
            for (let j = i + 1; j < mentions.length; j++) {
                if (mentions[i].span_indices[1] > mentions[j].span_indices[0]) {
                    // console.log('Overlapping mentions found:');
                    // console.log(`Mention 1: Title - "${mentions[i].title}", Text - "${mentions[i].text}", Type - "${mentions[i].insightType}"`);
                    // console.log(`Mention 2: Title - "${mentions[j].title}", Text - "${mentions[j].text}", Type - "${mentions[j].insightType}"`);
                    // console.log('---');
                } else {
                    break; // No more overlaps for mention i
                }
            }
        }
    };

    // Replace the filterMentions function with a calculation during rendering
    const filteredMentionsWithColors = mentionsWithColors.filter(mention => 
        displayReadyInsightTypeToShowDecisionMap[mention.insightType]
    );

    if (!pageData) {
        return <PageLoadSpinner />;
    }

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '30%', marginRight: '20px' }}>
                <VerticalMenuOnDocViewerPage 
                    vertical_menu_items_dict={verticalMenuItemsDict} 
                    idOfInsightInFocus={idOfInsightInFocus}
                    setIdOfInsightInFocus={setIdOfInsightInFocus}
                    colorCodeByInsightTypeOrInsight={colorCodeByInsightTypeOrInsight}
                    setColorCodeByInsightTypeOrInsight={setColorCodeByInsightTypeOrInsight}
                    displayReadyInsightTypeToShowDecisionMap={displayReadyInsightTypeToShowDecisionMap}
                    setDisplayReadyInsightTypeToShowDecisionMap={setDisplayReadyInsightTypeToShowDecisionMap}
                    getInsightInstanceColor={getInsightInstanceColor}
                    setSelectedMentionId={setSelectedMentionId} // Add this new prop
                />
            </div>
            <div style={{ width: '70%' }}>
                <DocPortionOfDocViewerPage 
                    file_content={pageData.file_content} 
                    mentions_with_colors={filteredMentionsWithColors}
                    colorCodeByInsightTypeOrInsight={colorCodeByInsightTypeOrInsight} 
                    idOfInsightInFocus={idOfInsightInFocus}
                    setIdOfInsightInFocus={setIdOfInsightInFocus}
                    getInsightInstanceColor={getInsightInstanceColor}
                    mentionRef={mentionRef}
                    initialMentionId={initialMentionId}
                    selectedMentionId={selectedMentionId}
                    setSelectedMentionId={setSelectedMentionId}
                />
            </div>
        </div>
    );
};

export default DocViewerPage;