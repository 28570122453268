import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as DropdownArrowIcon } from '../../../assets/icons/DropdownArrowIcon.svg';
import documentTypes from '../../../ConstantVariables.json';

const DocumentTypeDropdownSelector = ({ style, onSelectDocumentType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select Document Type');
  const documentTypeOptions = Object.keys(documentTypes);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelectDocumentType(selectedOption);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} style={{ ...style, position: 'relative' }}>
      <div
        onClick={toggleDropdown}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <span
          style={{
            color: selectedOption === 'Select Document Type' ? 'var(--slate-dark, #727F95)' : 'var(--blue-dark, #104499)',
            fontFamily: '"Roboto Mono"',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%',
          }}
        >
          {selectedOption}
        </span>
        <DropdownArrowIcon style={{ width: '24px', height: '24px' }} />
      </div>
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            background: 'white',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            zIndex: 1,
          }}
        >
          {documentTypeOptions.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option)}
              style={{
                padding: '12px 16px',
                cursor: 'pointer',
                color: 'var(--blue-darkest, #001331)',
                fontFamily: '"Roboto Mono"',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '150%',
                transition: 'background-color 0.2s ease',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'var(--slate-lightest, #F7FAFD)';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'white';
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DocumentTypeDropdownSelector;