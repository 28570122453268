import React, { useState, useEffect, useContext } from "react";
import { FaTimes } from "react-icons/fa";
import API from "../../../services/api";
import { v4 as uuidv4 } from 'uuid';
import { StatusContext } from '../../../contexts/StatusContext';
import { useAuth } from '../../../contexts/AuthContext';

function TeamInformationForm({ initiativeId, onClose, title }) {
    const [team, setTeam] = useState([]);
    const [expandedMembers, setExpandedMembers] = useState({});
    const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);
    const { user } = useAuth();

    useEffect(() => {
        if (user && user.user && user.user.userId) {
            fetchTeamInfo(user.user.userId);
        }
    }, [user]);

    const fetchTeamInfo = async (userId) => {
        try {
            const response = await API.get(`/user/${userId}/team-info`);
            if (response.data && response.data.team && response.data.team.length > 0) {
                setTeam(response.data.team.map(member => ({
                    ...member,
                    id: member.id || uuidv4(),
                    hoursPerWeek: "",
                    isSelected: false
                })));
            } else {
                setTeam([{ id: uuidv4(), name: "", team: "", role: "", responsibilities: "", hoursPerWeek: "", isSelected: false }]);
            }
        } catch (error) {
            console.error("Error fetching team information:", error);
            setTeam([{ id: uuidv4(), name: "", team: "", role: "", responsibilities: "", hoursPerWeek: "", isSelected: false }]);
        }
    };

    const handleTeamChange = (index, field, value) => {
        setTeam(prevTeam => {
            const newTeam = [...prevTeam];
            newTeam[index] = {
                ...newTeam[index],
                [field]: value
            };
            return newTeam;
        });
    };

    const toggleTeamMemberSelection = (event, index) => {
        event.stopPropagation();
        setTeam(prevTeam => {
            const newTeam = [...prevTeam];
            newTeam[index].isSelected = !newTeam[index].isSelected;
            return newTeam;
        });
    };

    const toggleMemberExpansion = (id) => {
        setExpandedMembers(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const handleEstimateMetrics = async () => {
        try {
            const selectedTeamMembers = team.filter(member => member.isSelected);
            const additionalInitiativeInformation = { 
                team: selectedTeamMembers.map(member => ({
                    id: member.id,
                    name: member.name,
                    team: member.team,
                    role: member.role,
                    responsibilities: member.responsibilities,
                    hoursPerWeek: member.hoursPerWeek
                }))
            };
            
            const estimateMetricsPromise = API.post(`/initiatives-page/${initiativeId}/estimate_all_metrics`, { 
                additional_initiative_information: additionalInitiativeInformation,
                initiative_title: title
            });

            const findRelatedInsightsPromise = API.get(`/initiatives-page/${initiativeId}/find-related-insights-for-initiative`);

            await Promise.all([estimateMetricsPromise, findRelatedInsightsPromise]);

            setIsCurrentlyCheckingForUpdatesOnProcesses(true);
            onClose();
        } catch (error) {
            console.error("Error starting metrics estimation or finding related insights:", error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-1/2 max-h-full overflow-y-auto relative">
                <button
                    onClick={onClose}
                    className="absolute top-2 left-2 text-gray-500 hover:text-gray-700"
                >
                    <FaTimes />
                </button>
                <h2 className="text-2xl mb-4">Select Team Members</h2>
                <div className="mb-4">
                    {team.map((member, index) => (
                        <div 
                            key={member.id} 
                            className="relative mb-4 p-4 border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50"
                            onClick={() => toggleMemberExpansion(member.id)}
                        >
                            <div className="flex justify-between items-start mb-2">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Name:</label>
                                    <div className="font-medium">{member.name}</div>
                                </div>
                                <input
                                    type="checkbox"
                                    checked={member.isSelected}
                                    onChange={(e) => toggleTeamMemberSelection(e, index)}
                                    onClick={(e) => e.stopPropagation()}
                                    className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                />
                            </div>
                            
                            <label className="block text-sm font-medium text-gray-700">Number of hours per work week dedicated to initiative:</label>
                            <input
                                type="number"
                                value={member.hoursPerWeek}
                                onChange={(e) => handleTeamChange(index, "hoursPerWeek", e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-8"
                                min="0"
                                step="0.5"
                                onClick={(e) => e.stopPropagation()}
                            />
                            
                            {expandedMembers[member.id] && (
                                <>
                                    <label className="block text-sm font-medium text-gray-700 mt-2">Team:</label>
                                    <div className="mt-1 text-gray-700">
                                        {member.team}
                                    </div>
                                    
                                    <label className="block text-sm font-medium text-gray-700 mt-2">Role:</label>
                                    <div className="mt-1 text-gray-700">
                                        {member.role}
                                    </div>
                                    
                                    <label className="block text-sm font-medium text-gray-700 mt-2">Responsibilities:</label>
                                    <div className="mt-1 text-gray-700">
                                        {member.responsibilities}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={handleEstimateMetrics}
                        className="mr-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700"
                    >
                        Run Metrics
                    </button>
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700"
                    >
                        Discard
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TeamInformationForm;