import React from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../reusable_components/HeadingsAndTitles/PageTitle";

function AboutInitiativePage() {
   const { initiativeId } = useParams();

   return (
      <div>
         <PageTitle>About Initiative</PageTitle>
         <p>Initiative ID: {initiativeId}</p>
      </div>
   );
}

export default AboutInitiativePage;
