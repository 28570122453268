import React from 'react';

const SubsectionDropdownHeader = ({
  showDropdownArrow = true,
  count,
  showCount = true,
  sectionHeader,
  isExpanded,
  onToggle
}) => {
  return (
    <div style={{
      display: 'inline-flex',
      alignItems: 'center',
      gap: '8px',
      cursor: 'pointer'
    }} onClick={onToggle}>
      {showDropdownArrow && (
        <div style={{ width: '24px', height: '24px', transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s ease' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <path d="M11.5 13.8006L16.3817 9L17.5 10.0997L11.5 16L5.5 10.0997L6.61828 9L11.5 13.8006Z" fill="#104499"/>
          </svg>
        </div>
      )}
      <div style={{
        color: 'var(--blue-darkest, #001331)',
        fontFamily: '"Roboto Mono"',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '135%'
      }}>
        {sectionHeader}
      </div>
      {showCount && (
        <span style={{
          color: 'var(--Mid-grey, #727F95)',
          fontFamily: '"Roboto Mono"',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '130%'
        }}>
          ({count})
        </span>
      )}
    </div>
  );
};

export default SubsectionDropdownHeader;