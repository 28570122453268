import React from "react";
import AnchorMenuTab from "./AnchorMenuTab";

const AnchorMenu = ({ headings, activeHeading, onHeadingClick }) => {
   return (
      <div
         id="AnchorMenuWithLinesAndTabs"
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "2px",
            width: "100%"
         }}
      >
         <div
            id="LineAboveAnchorMenu"
            style={{
               width: "100%",
               height: "1px",
               background: "var(--slate-light, #C5D1E4)"
            }}
         ></div>
         <div
            id="AnchorMenuTabs"
            style={{
               display: "flex",
               alignItems: "flex-start",
               gap: "36px",
               alignSelf: "stretch"
            }}
         >
            {headings.map((heading) => (
               <AnchorMenuTab
                  key={heading.id}
                  text={heading.text}
                  isActive={activeHeading === heading.id}
                  onClick={() => onHeadingClick(heading.id)}
               />
            ))}
         </div>
         <div
            id="LineBelowAnchorMenu"
            style={{
               width: "100%",
               height: "1px",
               background: "var(--slate-light, #C5D1E4)"
            }}
         ></div>
      </div>
   );
};

export default AnchorMenu;
