import React, { useEffect, useState } from "react";
import PageTitle from "../../../reusable_components/HeadingsAndTitles/PageTitle";
import DevelopmentAreasStack from "./DevelopmentAreasStack";
import API from "../../../../services/api";
import log from "../../../../utils/logger";

function DevelopmentTimeCalculations({ data, initiativeId }) {
   const [teamInfo, setTeamInfo] = useState([]);
   const [teamAllocations, setTeamAllocations] = useState({});
   const [bottleneckTeam, setBottleneckTeam] = useState("");
   const [bottleneckTeamMember, setBottleneckTeamMember] = useState("");
   const [bottleneckTeamMemberInBottleneckTeam, setBottleneckTeamMemberInBottleneckTeam] = useState("");
   const [orderedPhases, setOrderedPhases] = useState([]);

   useEffect(() => {
      const fetchTeamInfo = async () => {
         try {
            const response = await API.get(`initiatives/${initiativeId}/team-info`);
            if (Array.isArray(response.data)) {
               setTeamInfo(response.data);
               calculateTeamAllocations(response.data, data.team_allocation);
            } else {
               console.error("Unexpected response structure:", response.data);
            }
         } catch (error) {
            console.error("Error fetching team info:", error);
         }
      };

      fetchTeamInfo();
   }, [initiativeId, data.team_allocation]);

   useEffect(() => {
      // Set the ordered phases when data is available
      if (data && data.work_breakdown_structure && data.work_breakdown_structure.phases) {
         setOrderedPhases(Object.keys(data.work_breakdown_structure.phases));
      }
   }, [data]);

   const calculateTeamAllocations = (teamInfoData, teamAllocation) => {
      log("Team Info Data:", teamInfoData);
      log("Team Allocation:", teamAllocation);

      const allocations = {};
      teamInfoData.forEach((member) => {
         const memberId = member.id;
         const hoursPerWeek = parseFloat(member.hoursPerWeek);
         const totalHours = parseFloat(teamAllocation[memberId]) || 0;
         const weeksAllocated = Math.round(totalHours / hoursPerWeek); // Round to the nearest week

         log(`Member ${memberId}:`, {
            hoursPerWeek,
            totalHours,
            weeksAllocated
         });

         allocations[memberId] = {
            name: member.name,
            weeksAllocated: weeksAllocated,
            totalHours: totalHours.toFixed(1),
            hoursPerWeek: member.hoursPerWeek
         };
      });

      log("Calculated Allocations:", allocations);
      setTeamAllocations(allocations);
   };

   useEffect(() => {
      if (teamInfo.length > 0 && Object.keys(teamAllocations).length > 0) {
         const teamTotals = {};

         teamInfo.forEach((member) => {
            const allocation = teamAllocations[member.id];
            if (allocation) {
               if (!teamTotals[member.team]) {
                  teamTotals[member.team] = 0;
               }
               teamTotals[member.team] += parseFloat(allocation.totalHours);
            }
         });

         const maxTeamAllocation = Math.max(...Object.values(teamTotals));
         const bottleneckTeam = Object.keys(teamTotals).find((team) => teamTotals[team] === maxTeamAllocation);

         setBottleneckTeam(bottleneckTeam || "Unknown");
      }
   }, [teamInfo, teamAllocations]);

   useEffect(() => {
      if (Object.keys(teamAllocations).length > 0) {
         const maxAllocation = Math.max(...Object.values(teamAllocations).map((a) => parseFloat(a.totalHours)));
         const bottleneckMember = Object.entries(teamAllocations).find(
            ([_, a]) => parseFloat(a.totalHours) === maxAllocation
         );
         setBottleneckTeamMember(bottleneckMember ? bottleneckMember[1].name : "Unknown");
      }
   }, [teamAllocations]);

   useEffect(() => {
      if (teamInfo.length > 0 && Object.keys(teamAllocations).length > 0 && bottleneckTeam) {
         const bottleneckTeamMembers = teamInfo.filter((member) => member.team === bottleneckTeam);
         let maxAllocation = 0;
         let maxMember = null;

         bottleneckTeamMembers.forEach((member) => {
            const allocation = teamAllocations[member.id];
            if (allocation && parseFloat(allocation.totalHours) > maxAllocation) {
               maxAllocation = parseFloat(allocation.totalHours);
               maxMember = member;
            }
         });

         setBottleneckTeamMemberInBottleneckTeam(maxMember ? maxMember.name : "Unknown");
      }
   }, [teamInfo, teamAllocations, bottleneckTeam]);

   const formatDevelopmentTime = (value) => {
      const months = parseFloat(value);
      return `${months.toFixed(0)} MONTHS`;
   };

   return (
      <div>
         <PageTitle>Development Time</PageTitle>
         <div className="flex justify-between items-start mb-6 gap-4">
            <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200 flex flex-col h-full">
               <h3 className="text-lg font-semibold mb-2 text-blue-600">Duration</h3>
               <p className="text-xl font-medium flex-grow flex items-center">
                  {formatDevelopmentTime(data.value)}
               </p>
            </div>
            <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200 flex flex-col h-full">
               <h3 className="text-lg font-semibold mb-2 text-blue-600">Department Bottleneck</h3>
               <p className="text-xl font-medium flex-grow flex items-center">{bottleneckTeam}</p>
            </div>
            <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200 flex flex-col h-full">
               <h3 className="text-lg font-semibold mb-2 text-blue-600">{bottleneckTeam} Bottleneck</h3>
               <p className="text-xl font-medium flex-grow flex items-center">{bottleneckTeamMemberInBottleneckTeam}</p>
            </div>
            <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200 flex flex-col h-full">
               <h3 className="text-lg font-semibold mb-2 text-blue-600">True Bottleneck</h3>
               <p className="text-xl font-medium flex-grow flex items-center">{bottleneckTeamMember}</p>
            </div>
            <div className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
               <h3 className="text-lg font-semibold mb-2 text-blue-600">Team Allocation</h3>
               <ul className="list-none p-0 space-y-2">
                  {Object.entries(teamAllocations).map(([memberId, allocation]) => (
                     <li key={memberId} className="flex justify-between items-center">
                        <span className="font-medium">
                           {allocation.name}: {allocation.weeksAllocated} weeks work @ {allocation.hoursPerWeek} hours /
                           week
                        </span>
                     </li>
                  ))}
               </ul>
            </div>
         </div>
         <DevelopmentAreasStack
            phases={data.work_breakdown_structure.phases}
            orderedPhases={orderedPhases}
            teamInfo={teamInfo}
            teamAllocations={teamAllocations}
         />
      </div>
   );
}

export default DevelopmentTimeCalculations;
