import React from 'react';

const OnboardingFlowProgressBar = ({ currentStep, totalSteps }) => {
  const progressBarContainerStyle = {
    // width: '100%',
    // height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'inline-flex',
    marginBottom: '64px',
  };

  const progressDotStyle = (isActive) => ({
    width: 13,
    height: 13,
    background: isActive ? '#104499' : '#C5D1E4',
    borderRadius: 9999,
  });

  const progressLineStyle = (isActive) => ({
    width: 96,
    height: 0,
    background: isActive ? '#104499' : '#C5D1E4',
    border: `2px ${isActive ? '#104499' : '#C5D1E4'} solid`,
  });

  const renderProgressElements = () => {
    const elements = [];
    for (let i = 0; i < totalSteps; i++) {
      elements.push(
        <div key={`dot-${i}`} style={progressDotStyle(i < currentStep)} />
      );
      if (i < totalSteps - 1) {
        elements.push(
          <div key={`line-${i}`} style={progressLineStyle(i < currentStep - 1)} />
        );
      }
    }
    return elements;
  };

  return (
    <div style={progressBarContainerStyle}>
      {renderProgressElements()}
    </div>
  );
};

export default OnboardingFlowProgressBar;