import React from "react";
import LabeledEquationValueUSD from "./LabeledEquationValueUSD";

const ROINumerator = ({ amountGained, amountSpent }) => {
   return (
      <div className="roi-numerator flex justify-center items-end gap-8">
         <LabeledEquationValueUSD formatted_value={amountGained} label="AMOUNT GAINED" />
         <span className="text-[#727F95] font-['Roboto_Mono'] text-[40px] font-medium leading-[135%]">-</span>
         <LabeledEquationValueUSD formatted_value={amountSpent} label="AMOUNT SPENT" />
      </div>
   );
};

export default ROINumerator;
