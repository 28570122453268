import React, { createContext, useContext, useState } from 'react';
import API from '../services/api';

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
    const [paymentStatus, setPaymentStatus] = useState(null);

    const createCheckoutSession = async (userId) => {
        try {
            const response = await API.post('payments/create-checkout-session', { userId });
            return response.data.checkout_url;
        } catch (error) {
            console.error('Failed to create checkout session:', error);
            throw error;
        }
    };

    const verifyPayment = async (sessionId) => {
        try {
            const response = await API.post('payments/verify-payment', { sessionId });
            setPaymentStatus('active');
            return response.data;
        } catch (error) {
            console.error('Failed to verify payment:', error);
            throw error;
        }
    };

    const checkSubscriptionStatus = async (userId) => {
        try {
            const response = await API.get(`payments/check-subscription/${userId}`);
            setPaymentStatus(response.data.status);
            return response.data.status;
        } catch (error) {
            console.error('Failed to check subscription status:', error);
            throw error;
        }
    };

    return (
        <PaymentContext.Provider value={{ paymentStatus, createCheckoutSession, verifyPayment, checkSubscriptionStatus }}>
            {children}
        </PaymentContext.Provider>
    );
};

export const usePayment = () => useContext(PaymentContext);
