// Layout.js
import React from "react";
import Header from "./Header/Header";
import DevManagementMenu from "./DevManagementMenu/DevManagementMenu";

const Layout = ({ children }) => {
   return (
      <div className="pb-20">
         {process.env.REACT_APP_IS_PRODUCTION === 'false' && <DevManagementMenu />}
         <Header />
         <div style={{ padding: "0 200px", marginTop: "60px" }}>
            <main>{children}</main>
         </div>
         {/* Optionally add a footer here */}
      </div>
   );
};

export default Layout;

