import ReactDOM from "react-dom";
import App from "./App";
import 'tailwindcss/tailwind.css';
import './styles/global.css';
import { createRoot } from 'react-dom/client';
import React from "react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <App />
);