import React from "react";

import { Link } from "react-router-dom";

const QuoteCard = ({
   title,
   quote_text,
   source_file_name,
   isAKeyAccount = false,
   isClickable = false,
   onClick = () => {},
   style = {} // New style prop with default empty object
}) => {
   const defaultStyle = {
      display: "flex",
      width: "360px",
      padding: "16px 20px 24px 20px",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "20px",
      alignSelf: "stretch",
      borderRadius: "12px",
      border: "1px solid var(--slate-lighter, #E5EAF3)",
      background: "var(--white, #FFF)"
   };

   // Merge default style with provided style
   const mergedStyle = { ...defaultStyle, ...style };

   return (
      <div style={mergedStyle}>
         <div
            style={{
               display: "flex",
               flexDirection: "column",
               alignItems: "flex-start",
               gap: "10px",
               alignSelf: "stretch",
               height: "100%",
               justifyContent: "space-between"
            }}
         >
            <div
               id="TitleAndExpandArrow"
               style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "10px",
                  alignSelf: "stretch",
                  height: "46px"
               }}
            >
               <div
                  id="title"
                  style={{
                     flex: "1 0 0",
                     color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                     fontFamily: "Rubik",
                     fontSize: "16px",
                     fontStyle: "normal",
                     fontWeight: 500,
                     lineHeight: "145%"
                  }}
               >
                  {title}
               </div>
               {isClickable && (
                  <div onClick={onClick} style={{ cursor: 'pointer' }}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                           d="M5.55375 19.5L4.5 18.4462L15.9462 7H9V5.5H18.5V15H17V8.05375L5.55375 19.5Z"
                           fill="#628FDA"
                        />
                     </svg>
                  </div>
               )}
            </div>
            <div
               id="quote_text_and_source_file_name"
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                  alignSelf: "stretch",
                  flexGrow: 1,
                  minHeight: "fit-content",
                  justifyContent: "space-between"
               }}
            >
               <div
                  id="quote_text_and_left_line"
                  style={{
                     display: "flex",
                     alignItems: "center",
                     gap: "10px",
                     alignSelf: "stretch"
                  }}
               >
                  <div
                     id="line_on_left"
                     style={{
                        width: "2px",
                        alignSelf: "stretch",
                        borderRadius: "2px",
                        background: "var(--blue-medium, #628FDA)"
                     }}
                  ></div>
                  <div
                     id="quote_text"
                     style={{
                        flex: "1 0 0",
                        color: "var(--blue-darkest, #001331)",
                        fontFamily: '"Roboto Mono"',
                        fontSize: "16px",
                        fontStyle: "italic",
                        fontWeight: 400,
                        lineHeight: "140%",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis"
                     }}
                  >
                     {quote_text}
                  </div>
               </div>
               <div
                  id="KeyAccountSymbolAndSourceFileName"
                  style={{
                     display: "flex",
                     alignItems: "center",
                     gap: "6px",
                     alignSelf: "stretch"
                  }}
               >
                  {isAKeyAccount && (
                     <div
                        id="KeyAccountSymbol"
                        style={{
                           width: "20px",
                           height: "20px"
                        }}
                     >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                           <path
                              d="M10 13.2981L6.70188 10L10 6.70188L13.2981 10L10 13.2981ZM11.0625 17.1794C10.9204 17.3215 10.7559 17.4302 10.569 17.5056C10.382 17.5809 10.1924 17.6185 10 17.6185C9.80764 17.6185 9.61799 17.5809 9.43104 17.5056C9.2441 17.4302 9.07958 17.3215 8.9375 17.1794L2.79646 11.0385C2.65979 10.9017 2.55375 10.7399 2.47833 10.5529C2.40306 10.366 2.36542 10.179 2.36542 9.99208C2.36542 9.805 2.40306 9.61799 2.47833 9.43104C2.55375 9.2441 2.65979 9.08229 2.79646 8.94563L8.92479 2.81729C9.07535 2.66674 9.24195 2.55375 9.42458 2.47834C9.60736 2.40306 9.79917 2.36542 10 2.36542C10.2008 2.36542 10.3926 2.40306 10.5754 2.47834C10.7581 2.55375 10.9247 2.66674 11.0752 2.81729L17.2035 8.94563C17.3402 9.08229 17.4463 9.2441 17.5217 9.43104C17.5969 9.61799 17.6346 9.805 17.6346 9.99208C17.6346 10.179 17.5969 10.366 17.5217 10.5529C17.4463 10.7399 17.3402 10.9017 17.2035 11.0385L11.0625 17.1794ZM10.1363 15.8558L15.8638 10.1281C15.9011 10.0908 15.9198 10.0454 15.9198 9.99208C15.9198 9.93861 15.9011 9.8932 15.8638 9.85583L10.1363 4.12813C10.0989 4.09077 10.0535 4.0734 10 4.07604C9.94653 4.07882 9.90111 4.09889 9.86375 4.13625L4.12813 9.87188C4.09076 9.90924 4.0734 9.95195 4.07604 10C4.07882 10.0481 4.09889 10.0908 4.13625 10.1281L9.86375 15.8558C9.90111 15.8932 9.94653 15.9119 10 15.9119C10.0535 15.9119 10.0989 15.8932 10.1363 15.8558Z"
                              fill="#104499"
                           />
                        </svg>
                     </div>
                  )}
                  <div
                     id="source_file_name"
                     style={{
                        flex: "1 0 0",
                        color: "var(--slate-dark, #727F95)",
                        fontFamily: '"Roboto Mono"',
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "130%"
                     }}
                  >
                     {source_file_name}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default QuoteCard;
