import React from 'react';

function ObjectiveCard({ title, summary, count, percentage, onClick, fileName }) {

  return (
    <article 
      className="flex w-[270px] h-[160px] pr-4 items-start gap-3 rounded-lg bg-white shadow-[0px_2px_8px_rgba(114,127,149,0.25)] cursor-pointer"
      onClick={onClick}
    >
      <div className="w-[6px] self-stretch rounded-l-lg bg-[#0AA476]" />
      <div className="flex flex-col flex-1 h-full justify-start py-4">
        <h2 className="text-[#001331] font-['Rubik'] text-[15px] font-medium leading-[145%] line-clamp-4 overflow-hidden">
          {title}
        </h2>
      </div>
    </article>
  );
}

export default ObjectiveCard;