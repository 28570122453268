import API from '../services/api';

export const fetchIntegrationStatus = async () => {
  try {
    const response = await API.get("/integrations_general/get_dict_saying_which_external_services_are_integrated");
    const hasAnyIntegration = Object.values(response.data).some(status => status);
    return { hasIntegrations: hasAnyIntegration, integrationStatus: response.data };
  } catch (error) {
    console.error("Error fetching integration status:", error);
    throw error;
  }
};

export const isJiraIntegrated = async () => {
  try {
    const { integrationStatus } = await fetchIntegrationStatus();
    return !!integrationStatus.jira;
  } catch (error) {
    console.error("Error checking Jira integration status:", error);
    return false;
  }
};