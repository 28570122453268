import React, { useState } from "react";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import API from "../../services/api"; // Import API instead of axios
import { useAuth } from "../../contexts/AuthContext";

export default function XProdFeedback() {
   const [feedback, setFeedback] = useState("");
   const { user } = useAuth();

   const handleSubmit = async (e) => {
      e.preventDefault();
      if (!feedback) {
         alert("Please enter your feedback.");
         return;
      }

      const feedbackData = {
         feedback: feedback
      };

      try {
         await API.post("/feedback/", feedbackData, {
            headers: {
               Authorization: `Bearer ${user.access_token}`
            }
         });
         alert("Feedback received successfully.");
         setFeedback(""); // Clear the feedback form
      } catch (error) {
         console.error("Error submitting feedback:", error);
         alert("Failed to submit feedback. Please try again later.");
      }
   };

   return (
      <div className="flex flex-col items-center justify-start min-h-screen bg-white pt-10">
         <PageTitle>Feedback and Feature Requests</PageTitle>
         <form className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-md mt-6" onSubmit={handleSubmit}>
            <div className="mb-4">
               <textarea
                  id="feedback"
                  name="feedback"
                  rows="15"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter your feedback or feature requests here..."
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
               ></textarea>
            </div>
            <div className="flex justify-center">
               <PrimaryButton type="submit">Submit</PrimaryButton>
            </div>
         </form>
      </div>
   );
}
