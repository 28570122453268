import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import AuthService from "../auth/AuthService";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import log from "../../utils/logger";
import API from "../../services/api";
import SelectInput from "../reusable_components/FormInputFields/SelectInput";
import SelectWithOtherInput from "../reusable_components/FormInputFields/SelectWithOtherInput";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

// Add this custom Alert component
const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MyAccountPage() {
   const { user } = useAuth();
   const [userDetails, setUserDetails] = useState(null);
   const [editableDetails, setEditableDetails] = useState({});
   const [fieldErrors, setFieldErrors] = useState({});
   const [serverErrors, setServerErrors] = useState({});
   const [isFormValid, setIsFormValid] = useState(false);
   const [hasChanges, setHasChanges] = useState(false);
   const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success", key: 0 });

   useEffect(() => {
      const fetchUserAndCompanyDetails = async () => {
         try {
            const details = await AuthService.getUserAndCompanyDetails();
            setUserDetails(details);
            setEditableDetails(details);
         } catch (error) {
            console.error("Failed to fetch user and company details:", error);
         }
      };

      fetchUserAndCompanyDetails();
   }, []);

   const validateField = async (name, value) => {
      let error = "";
      if (!value.trim()) {
         error = `${name} is required`;
      } else if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
         error = "Invalid email format";
      }

      setFieldErrors((prev) => ({ ...prev, [name]: error }));

      // Only perform server-side validation if the value has changed
      if (name === "email" && value.trim() && !error && value !== userDetails.email) {
         try {
            const response = await API.post("auth/verify-registration-data", { [name]: value });
            if (response.data.errors && response.data.errors[name]) {
               setServerErrors((prev) => ({ ...prev, [name]: response.data.errors[name] }));
            } else {
               setServerErrors((prev) => ({ ...prev, [name]: "" }));
            }
         } catch (err) {
            console.error("Error verifying field:", err);
            if (err.response && err.response.data && err.response.data.errors) {
               setServerErrors((prev) => ({
                  ...prev,
                  [name]: err.response.data.errors[name] || "Unknown server error"
               }));
            } else {
               setServerErrors((prev) => ({ ...prev, [name]: "Error connecting to server" }));
            }
         }
      } else {
         setServerErrors((prev) => ({ ...prev, [name]: "" }));
      }
   };

   const handleChange = (e) => {
      const { name, value } = e.target;
      setEditableDetails((prev) => ({ ...prev, [name]: value }));
      validateField(name, value);
      setHasChanges(true);
   };

   const handleBlur = (e) => {
      const { name, value } = e.target;
      validateField(name, value);
   };

   useEffect(() => {
      const allFieldsFilled = Object.values(editableDetails).every((field) => field && field.trim() !== "");
      const noFieldErrors = Object.values(fieldErrors).every((error) => error === "");
      const noServerErrors = Object.values(serverErrors).every((error) => error === "");
      setIsFormValid(allFieldsFilled && noFieldErrors && noServerErrors && hasChanges);
   }, [editableDetails, fieldErrors, serverErrors, hasChanges]);

   const showNotification = (message, severity) => {
      setSnackbar((prev) => ({
         open: true,
         message,
         severity,
         key: prev.key + 1
      }));
   };

   const handleCloseSnackbar = (event, reason) => {
      if (reason === "clickaway") {
         return;
      }
      setSnackbar((prev) => ({ ...prev, open: false }));
   };

   const handleSave = async (e) => {
      e.preventDefault();
      if (!isFormValid) {
         showNotification("Please correct all errors before submitting", "error");
         return;
      }
      try {
         log("Updating user details:", editableDetails);
         await AuthService.updateUserDetails(editableDetails);
         const updatedDetails = await AuthService.getUserAndCompanyDetails();
         log("Updated user details:", updatedDetails);
         setUserDetails(updatedDetails);
         setEditableDetails(updatedDetails);
         showNotification("Details updated successfully", "success");
         setHasChanges(false); // Reset hasChanges to false
      } catch (error) {
         console.error("Failed to update user details:", error);
         showNotification("Failed to update details", "error");
      }
   };

   const renderInput = (name, label, type = "text") => (
      <div className="mb-4">
         <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
            {label}:
         </label>
         <input
            type={type}
            id={name}
            name={name}
            value={editableDetails[name] || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
               fieldErrors[name] || serverErrors[name] ? "border-red-500" : ""
            }`}
            disabled={name === "username"}
         />
         {fieldErrors[name] && <p className="text-red-500 text-xs italic">{fieldErrors[name]}</p>}
         {serverErrors[name] && <p className="text-red-500 text-xs italic">{serverErrors[name]}</p>}
      </div>
   );

   const industryOptions = [
      { value: "", label: "Select Industry" },
      { value: "software", label: "Software & Technology" },
      { value: "saas", label: "SaaS (Software as a Service)" },
      { value: "fintech", label: "Financial Technology" },
      { value: "ecommerce", label: "E-commerce & Retail Tech" },
      { value: "gamedev", label: "Video Game Development" },
      { value: "healthtech", label: "Healthcare Technology" },
      { value: "edtech", label: "Education Technology" },
      { value: "martech", label: "Marketing Technology" },
      { value: "hrtech", label: "HR Technology" },
      { value: "iot", label: "Internet of Things (IoT)" },
      { value: "ai_ml", label: "Artificial Intelligence & Machine Learning" },
      { value: "cybersecurity", label: "Cybersecurity" },
      { value: "telecom", label: "Telecommunications" },
      { value: "cleantech", label: "Clean Technology & Sustainability" },
      { value: "biotech", label: "Biotechnology" },
      { value: "aerospace", label: "Aerospace & Defense" },
      { value: "automotive", label: "Automotive Technology" },
      { value: "manufacturing", label: "Manufacturing Technology" },
      { value: "logistics", label: "Logistics & Supply Chain Tech" },
      { value: "proptech", label: "Property Technology" },
      { value: "legaltech", label: "Legal Technology" },
      { value: "govtech", label: "Government & Public Sector Technology" },
      { value: "mediatech", label: "Media & Entertainment Technology" },
      { value: "agritech", label: "Agricultural Technology" },
      { value: "other", label: "Other" }
   ];

   const revenueSizeOptions = [
      { value: "", label: "Select Revenue Size" },
      { value: "0-1M", label: "$0 - $1 Million" },
      { value: "1M-10M", label: "$1 Million - $10 Million" },
      { value: "10M-50M", label: "$10 Million - $50 Million" },
      { value: "50M-100M", label: "$50 Million - $100 Million" },
      { value: "100M+", label: "$100 Million+" }
   ];

   const employeeSizeOptions = [
      { value: "", label: "Select Employee Count" },
      { value: "1-10", label: "1-10 employees" },
      { value: "11-50", label: "11-50 employees" },
      { value: "51-200", label: "51-200 employees" },
      { value: "201-500", label: "201-500 employees" },
      { value: "501+", label: "501+ employees" }
   ];

   const renderSelectWithOther = (name, label, options) => (
      <div className="mb-4">
         <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
            {label}:
         </label>
         <SelectWithOtherInput
            id={name}
            name={name}
            value={editableDetails[name] || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            options={options}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
               fieldErrors[name] || serverErrors[name] ? "border-red-500" : ""
            }`}
         />
         {fieldErrors[name] && <p className="text-red-500 text-xs italic">{fieldErrors[name]}</p>}
         {serverErrors[name] && <p className="text-red-500 text-xs italic">{serverErrors[name]}</p>}
      </div>
   );

   const renderSelect = (name, label, options) => (
      <div className="mb-4">
         <label htmlFor={name} className="block text-gray-700 text-sm font-bold mb-2">
            {label}:
         </label>
         <SelectInput
            id={name}
            name={name}
            value={editableDetails[name] || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            options={options}
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
               fieldErrors[name] || serverErrors[name] ? "border-red-500" : ""
            }`}
         />
         {fieldErrors[name] && <p className="text-red-500 text-xs italic">{fieldErrors[name]}</p>}
         {serverErrors[name] && <p className="text-red-500 text-xs italic">{serverErrors[name]}</p>}
      </div>
   );

   if (!userDetails) {
      return (
         <div className="flex justify-center items-center h-screen">
            <div className="text-xl">Loading...</div>
         </div>
      );
   }

   return (
      <div className="max-w-4xl mx-auto mt-10 p-5">
         <div className="text-center mb-10">
            <PageTitle>My Account</PageTitle>
         </div>
         <div className="border rounded-lg shadow-lg p-5" style={{ backgroundColor: '#fbfcfc' }}>
            <form onSubmit={handleSave} className="grid grid-cols-2 gap-4">
               <div className="grid grid-cols-1 gap-4">
                  <h2 className="text-xl font-bold mb-4">User Information</h2>
                  {renderInput("username", "Username")}
                  {renderInput("email", "Email", "email")}
                  {renderInput("firstName", "First Name")}
                  {renderInput("lastName", "Last Name")}
               </div>
               <div className="grid grid-cols-1 gap-4">
                  <div className="flex items-center mb-4">
                     <h2 className="text-xl font-bold">Company Information</h2>
                     <p className="text-sm text-gray-600 ml-2">used for better AI!</p>
                  </div>
                  {renderInput("companyName", "Company Name")}
                  {renderSelectWithOther("industry", "Industry", industryOptions)}
                  {renderInput("coreOfferings", "Core Offering(s)")}
                  {renderSelect("sizeByRevenue", "Size By Revenue", revenueSizeOptions)}
                  {renderSelect("sizeByEmployeeCount", "Size By Employee Count", employeeSizeOptions)}
               </div>
               <div className="col-span-2 flex justify-center mt-4">
                  <PrimaryButton type="submit" disabled={!isFormValid || !hasChanges}>
                     Save
                  </PrimaryButton>
               </div>
            </form>
         </div>
         <Snackbar
            key={snackbar.key}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
         >
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
               {snackbar.message}
            </Alert>
         </Snackbar>
      </div>
   );
}

export default MyAccountPage;
