import React from 'react';

const FeatureRequestCard = ({ count }) => {
  return (
    <div className="flex w-[182px] px-3 py-1 items-center gap-2 rounded-lg bg-[#E5EFFF]">
      <div className="text-xl font-bold tracking-wide text-blue-900">{count}</div>
      <div className="text-sm font-medium tracking-wide text-slate-500 whitespace-nowrap">Feature requests</div>
    </div>
  );
};

export default FeatureRequestCard;