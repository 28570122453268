import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SelectInput from './SelectInput';

const InputWrapper = styled.div`
  margin-top: 0.5rem;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #374151;

  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
  }
`;

const SelectWithOtherInput = ({ id, name, value, onChange, onBlur, options, className, disabled }) => {
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherValue, setOtherValue] = useState('');

  useEffect(() => {
    if (value === 'other' || value.startsWith('other:')) {
      setShowOtherInput(true);
      setOtherValue(value.startsWith('other:') ? value.slice(6) : '');
    } else {
      setShowOtherInput(false);
      setOtherValue('');
    }
  }, [value]);

  const handleSelectChange = (e) => {
    const newValue = e.target.value;
    onChange({ target: { name, value: newValue } });
  };

  const handleOtherInputChange = (e) => {
    const newValue = e.target.value;
    setOtherValue(newValue);
    onChange({ target: { name, value: `other:${newValue}` } });
  };

  return (
    <>
      <SelectInput
        id={id}
        name={name}
        value={value.startsWith('other:') ? 'other' : value}
        onChange={handleSelectChange}
        onBlur={onBlur}
        options={options}
        className={className}
        disabled={disabled}
      />
      {showOtherInput && (
        <InputWrapper>
          <StyledInput
            type="text"
            value={otherValue}
            onChange={handleOtherInputChange}
            onBlur={onBlur}
            placeholder="Please specify"
            disabled={disabled}
          />
        </InputWrapper>
      )}
    </>
  );
};

export default SelectWithOtherInput;