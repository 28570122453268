import React from 'react';

const Pagination = ({ currentPage, totalInitiatives, onPageChange }) => {
  const itemsPerPage = 50;
  const totalPages = Math.ceil(totalInitiatives / itemsPerPage);
  
  const getPageRange = () => {
    let start = Math.max(1, currentPage - 5);
    let end = Math.min(totalPages, start + 9);
    
    if (end - start < 9 && start > 1) {
      start = Math.max(1, end - 9);
    }
    
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  const pageNumbers = getPageRange();

  const handleJump = (direction) => {
    const newPage = direction === 'left'
      ? Math.max(1, currentPage - 10)
      : Math.min(totalPages, currentPage + 10);
    onPageChange(newPage);
  };

  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber);
  };

  return (
    <div className="pagination flex justify-center items-center mt-4">
      {currentPage > 1 && (
        <button onClick={() => handlePageChange(currentPage - 1)} className="px-3 py-1 mx-1 bg-blue-500 text-white rounded">
          Previous
        </button>
      )}
      {currentPage > 10 && (
        <button onClick={() => handleJump('left')} className="px-3 py-1 mx-1 bg-blue-500 text-white rounded">
          &#171;
        </button>
      )}
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => handlePageChange(number)}
          className={`px-3 py-1 mx-1 ${currentPage === number ? 'bg-blue-700' : 'bg-blue-500'} text-white rounded`}
        >
          {number}
        </button>
      ))}
      {currentPage < totalPages - 9 && (
        <button onClick={() => handleJump('right')} className="px-3 py-1 mx-1 bg-blue-500 text-white rounded">
          &#187;
        </button>
      )}
      {currentPage < totalPages && (
        <button onClick={() => handlePageChange(currentPage + 1)} className="px-3 py-1 mx-1 bg-blue-500 text-white rounded">
          Next
        </button>
      )}
    </div>
  );
};

export default Pagination;