import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "../../services/api";
import log from "../../utils/logger";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";

function SingleIndividualFeatureRequestPage() {
   const [insight, setInsight] = useState(null);
   const [fileMetadata, setFileMetadata] = useState(null);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
   const { id } = useParams();

   useEffect(() => {
      const fetchIndividualFeatureRequestInsight = async () => {
         try {
            const response = await API.get(`insights/get-individual-insight/${id}`);
            setInsight(response.data.individual_insight);
            setFileMetadata(response.data.file_metadata);
            setLoading(false);
         } catch (err) {
            log("Error fetching individual feature request insight:", err);
            setError("Failed to load insight. Please try again later.");
            setLoading(false);
         }
      };

      fetchIndividualFeatureRequestInsight();
   }, [id]);

   if (loading)
      return (
         <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
         </div>
      );
   if (error) return <div className="text-red-600 text-center p-4">{error}</div>;
   if (!insight) return <div className="text-gray-600 text-center p-4">No insight found</div>;

   return (
      <div className="single-individual-feature-request-page max-w-4xl mx-auto px-4 py-8">
         <PageTitle className="text-3xl font-bold mb-6 text-gray-800">
            <span className="text-green-600">Feature request: </span>
            {insight.title}
         </PageTitle>

         <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="p-6">
               {insight.summary && <InfoSection title="Summary" content={insight.summary} />}
               {insight.description && <InfoSection title="Description" content={insight.description} />}
               {insight.specifics && <InfoSection title="Specifics" content={insight.specifics} />}
               {insight.why_them && <InfoSection title="Why Them" content={insight.why_them} />}
               {insight.cost && <InfoSection title="Cost" content={insight.cost} />}
               {insight.current_solutions && (
                  <InfoSection title="Current Solutions" content={insight.current_solutions} />
               )}
               {insight.requested_solutions && (
                  <InfoSection title="Requested Solutions" content={insight.requested_solutions} />
               )}
               {insight.solutions_considered && (
                  <InfoSection title="Solutions Considered" content={insight.solutions_considered} />
               )}
               {insight.impact_score && <InfoSection title="Impact Score" content={insight.impact_score} />}
            </div>
         </div>

         {insight.impactful_quotes && insight.impactful_quotes.length > 0 && (
            <ListSection title="Impactful Quotes" items={insight.impactful_quotes} />
         )}
         {insight.metrics_impacted && insight.metrics_impacted.length > 0 && (
            <ListSection title="Metrics Impacted" items={insight.metrics_impacted} />
         )}
         {insight.segments && insight.segments.length > 0 && <ListSection title="Segments" items={insight.segments} />}

         {insight.applies_to_entire_segment !== undefined && (
            <div className="bg-gray-100 p-4 rounded-lg mb-8">
               <p className="font-semibold">
                  Applies to Entire Segment:
                  <span className={`ml-2 ${insight.applies_to_entire_segment ? "text-green-600" : "text-red-600"}`}>
                     {insight.applies_to_entire_segment ? "Yes" : "No"}
                  </span>
               </p>
            </div>
         )}

         {fileMetadata && (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
               <div className="bg-gray-800 text-white py-2 px-4">
                  <h2 className="text-xl font-semibold">Source Document</h2>
               </div>
               <div className="p-4">
                  {fileMetadata.file_name && (
                     <p>
                        <strong>File Name:</strong> {fileMetadata.file_name}
                     </p>
                  )}
                  {fileMetadata.document_type && (
                     <p>
                        <strong>Document Type:</strong> {fileMetadata.document_type}
                     </p>
                  )}
                  {fileMetadata.source && (
                     <p>
                        <strong>Source:</strong> {fileMetadata.source}
                     </p>
                  )}
               </div>
            </div>
         )}
      </div>
   );
}

function InfoSection({ title, content }) {
   return (
      <div className="mb-4">
         <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
         <p className="text-gray-600">{content}</p>
      </div>
   );
}

function ListSection({ title, items }) {
   return (
      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
         <div className="bg-gray-800 text-white py-2 px-4">
            <h2 className="text-xl font-semibold">{title}</h2>
         </div>
         <ul className="p-4 list-disc list-inside">
            {items.map((item, index) => (
               <li key={index} className="mb-2 last:mb-0 text-gray-700">
                  {item}
               </li>
            ))}
         </ul>
      </div>
   );
}

export default SingleIndividualFeatureRequestPage;
