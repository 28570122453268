import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { ReactComponent as DocIcon } from '../../../assets/icons/DocIcon.svg';
import { ReactComponent as TrashCanIcon } from '../../../assets/icons/TrashCanIcon.svg';
import DocumentTypeDropdownSelector from './DocumentTypeDrowdownSelector';
import { handleDocumentTypeChange } from '../../../services/fileMetadataManagementService';
import API from '../../../services/api';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FileUploadAndDocumentTypeSelector = ({ fileName, file_metadata_mongodb_doc_id, onSelectDocumentType, onFileDelete }) => {
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success", key: 0 });

  const showNotification = (message, severity) => {
    setSnackbar((prev) => ({
      open: true,
      message,
      severity,
      key: prev.key + 1
    }));
  };

  const handleDocumentTypeSelect = async (selectedDocumentType) => {
    const success = await handleDocumentTypeChange(file_metadata_mongodb_doc_id, selectedDocumentType);
    if (success) {
      showNotification("Document type has been successfully saved.", "success");
      onSelectDocumentType(selectedDocumentType);
    } else {
      showNotification("Failed to save document type.", "error");
    }
  };

  const handleDelete = async () => {
    try {
      await API.delete(`file-management/delete-files-and-metadata`, {
        data: { file_ids: [file_metadata_mongodb_doc_id] }
      });
      showNotification("File has been successfully deleted.", "success");
      if (onFileDelete) {
        onFileDelete(file_metadata_mongodb_doc_id);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      showNotification("Failed to delete file.", "error");
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{
        display: 'flex',
        height: '48px',
        padding: '9px 12px 9px 20px',
        alignItems: 'center',
        gap: '20px',
        alignSelf: 'stretch',
        borderRadius: '12px',
        border: '1px solid var(--slate-lighter, #E5EAF3)',
        background: 'var(--blue-lighter, #FBFCFC)',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          flex: 1,
          overflow: 'hidden'
        }}>
          <DocIcon style={{ width: '24px', height: '24px', flexShrink: 0 }} />
          <span style={{
            color: 'var(--blue-darkest, #001331)',
            fontFamily: '"Roboto Mono"',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            {fileName}
          </span>
        </div>
        <div style={{
          width: '1px',
          height: '48px',
          background: 'var(--slate-light, #C5D1E4)'
        }} />
        <DocumentTypeDropdownSelector 
          style={{ width: '350px' }} 
          onSelectDocumentType={handleDocumentTypeSelect}
        />
        <div style={{
          display: 'flex',
          width: '44px',
          alignItems: 'center',
          gap: '16px'
        }}>
          <div style={{
            width: '1px',
            height: '48px',
            background: 'var(--slate-light, #C5D1E4)'
          }} />
          <TrashCanIcon 
            style={{
              width: '24px',
              height: '24px',
              flexShrink: 0,
              cursor: 'pointer'
            }} 
            onClick={handleDelete}
          />
        </div>
      </div>
      <Snackbar
        key={snackbar.key}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FileUploadAndDocumentTypeSelector;