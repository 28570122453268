import React from 'react';

const RightSideOfEquation = ({ value }) => {
  return (
    <div className="right-side-equation flex flex-col items-start pb-5">
      <span className="roi-label text-[14px] font-['Roboto_Mono'] font-medium leading-[18.9px] tracking-[0.28px] uppercase text-[#727F95]">RETURN ON INVESTMENT</span>
      <span className="roi-value text-[72px] font-['Roboto_Mono'] font-medium leading-[135%] text-[#104499]">{Math.round(value * 100)}%</span>
    </div>
  );
};

export default RightSideOfEquation;