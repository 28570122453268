import React from "react";
import ShieldIcon from "../../assets/icons/OnboardingFlowIcons/ShieldIcon.svg";

const WeUnderstandDocumentConfidentialityMessage = ({ style }) => {
   return (
      <div
         className="confidentiality-message-container h-[202px] flex-col justify-start items-start gap-[120px] inline-flex"
         style={style}
      >
         <div className="confidentiality-content self-stretch justify-center items-center gap-4 inline-flex">
            <img src={ShieldIcon} alt="Shield Icon" className="w-[30px] h-[30px] relative" />
            <div className="confidentiality-text w-[600px] text-[#001230] text-base font-normal font-['Roboto Mono'] leading-tight">
               We understand that research documents may be confidential. Data you upload will not be shared with any
               third party
            </div>
         </div>
      </div>
   );
};

export default WeUnderstandDocumentConfidentialityMessage;
