import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import API from "../../../services/api";
import "./SingleAggregatePainPointPage.css";
import PageLoadSpinner from "../../reusable_components/PageLoadSpinner/PageLoadSpinner";
import { ReactComponent as DropdownArrowIcon } from "../../../assets/icons/DropdownArrowIcon.svg";
import FindOnPageTextInputAndSearchIcon from "../../reusable_components/FormInputFields/FindOnPageTextInputAndSearchIcon";
import AnchorMenu from "../../reusable_components/AnchorMenu/AnchorMenu";
import IndividualPainPointsCardScroller from "../../reusable_components/InsightsCardScrollers/IndividualPainPointsCardScroller";
import CurrentSolutionsTable from "../../reusable_components/CurrentSolutionsTable/CurrentSolutionsTable";
import SubsectionDropdownHeader from "../../reusable_components/SubsectionDropdownHeader";
import DocWhereInsightIsMentionedCard from "../../reusable_components/DocWhereInsightIsMentionedCard";


const SingleAggregatePainPointPage = () => {
   const [isLoading, setIsLoading] = useState(true);
   const { id } = useParams();
   const [searchText, setSearchText] = useState("");
   const [painPoint, setPainPoint] = useState(null);
   const [totalInterviewees, setTotalInterviewees] = useState(0);
   const [activeHeading, setActiveHeading] = useState("Summary");
   const headings = [
      { id: "SummaryAndImpactedPartiesSection", text: "Summary" },
      { id: "IndividualPainPoints", text: "Individual Pain Points" },
      { id: "ImportanceAndMetricsImpactedAndCostsCards", text: "Importance/Metrics/Costs" },
      { id: "CurrentSolutions", text: "Current Solutions" },
      { id: "RequestedSolutions", text: "Requested Solutions" },
      { id: "ImpactfulQuotes", text: "Impactful Quotes" },
      { id: "Sources", text: "Sources" }
   ];
   const sectionRefs = useRef(headings.map(() => React.createRef()));
   const anchorMenuRef = useRef(null);
   const [individualPainPoints, setIndividualPainPoints] = useState([]);

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await API.get(`insights/aggregate-pain-point/${id}`);
            const fetchedPainPoint = response.data.aggregate_pain_point;
            setPainPoint(fetchedPainPoint);
            setIndividualPainPoints(response.data.individual_pain_points);

            // Calculate totalInterviewees
            const calculatedTotalInterviewees = Math.round(fetchedPainPoint.count / fetchedPainPoint.percentage);
            setTotalInterviewees(calculatedTotalInterviewees);

            setIsLoading(false);
         } catch (error) {
            console.error("Error fetching pain point data:", error);
            setIsLoading(false);
         }
      };

      fetchData();
   }, [id]);

   useEffect(() => {
      const observerOptions = {
         root: null,
         rootMargin: "0px",
         threshold: 0.5
      };

      const observerCallback = (entries) => {
         entries.forEach((entry) => {
            if (entry.isIntersecting) {
               setActiveHeading(entry.target.id);
            }
         });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      sectionRefs.current.forEach((ref) => {
         if (ref.current) {
            observer.observe(ref.current);
         }
      });

      return () => {
         sectionRefs.current.forEach((ref) => {
            if (ref.current) {
               observer.unobserve(ref.current);
            }
         });
      };
   }, [id]);

   const handleHeadingClick = (heading) => {
      setActiveHeading(heading);
      const index = headings.findIndex((h) => h.id === heading);
      if (sectionRefs.current[index].current) {
         const yOffset = -anchorMenuRef.current.offsetHeight;
         const y = sectionRefs.current[index].current.getBoundingClientRect().top + window.pageYOffset + yOffset;
         window.scrollTo({ top: y, behavior: "smooth" });
      }
   };

   if (isLoading) {
      return <PageLoadSpinner />;
   }

   return (
      <div className="single-aggregate-pain-point-page">
         <div
            id="back-to-insights-page-button-and-find-on-page-search-box-and-export-insights-button"
            style={{
               display: "flex",
               alignItems: "center",
               gap: "6px"
            }}
         >
            <DropdownArrowIcon style={{ width: "24px", height: "24px", transform: "rotate(90deg)" }} />
            <span
               style={{
                  color: "var(--blue-dark, #104499)",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "130%",
                  flex: "1 0 0"
               }}
            >
               Insights
            </span>
            <div
               id="find on page text box and export insights button"
               style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  flex: "1 0 0"
               }}
            >
               <div
                  className="find-on-page-form-field"
                  style={{
                     display: "flex",
                     height: "48px",
                     alignItems: "flex-start",
                     flex: "1 0 0"
                  }}
               >
                  <FindOnPageTextInputAndSearchIcon searchText={searchText} placeholder="Find on page..." />
               </div>
            </div>
         </div>
         <div
            id="TitleAndImpactScoreAndNumberOfInsightsFromKeyAccountsAndAnchorMenu"
            ref={anchorMenuRef}
            style={{
               position: "sticky",
               top: 0,
               zIndex: 1000,
               display: "flex",
               paddingTop: "24px",
               flexDirection: "column",
               alignItems: "flex-start",
               gap: "24px",
               backgroundColor: "#f6f9ff"
            }}
         >
            <div
               id="TitleAndImpactScoreAndNumberOfInsightsFromKeyAccounts"
               style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "24px",
                  alignSelf: "stretch"
               }}
            >
               <div
                  id="InsightTypeIndicatorAndTitle"
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "flex-start",
                     gap: "6px",
                     width: "900px"
                  }}
               >
                  <div
                     className="insight-type-indicator"
                     style={{
                        alignSelf: "stretch",
                        color: "var(--slate-dark, #727F95)",
                        fontFamily: '"Roboto Mono"',
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "135%",
                        letterSpacing: "0.32px",
                        textTransform: "uppercase"
                     }}
                  >
                     PAIN POINT
                  </div>
                  {painPoint && (
                     <div
                        style={{
                           alignSelf: "stretch",
                           color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                           fontFamily: '"Roboto Mono"',
                           fontSize: "24px",
                           fontStyle: "normal",
                           fontWeight: 500,
                           lineHeight: "135%"
                        }}
                     >
                        {painPoint.title}
                     </div>
                  )}
               </div>
               <div
                  id="ImpactScoreAndNumberOfInsightsFromKeyAccounts"
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "center",
                     alignItems: "flex-end",
                     gap: "16px",
                     flex: "1 0 0"
                     //  border: "2px solid var(--blue-medium, #628FDA)"
                  }}
               >
                  <div
                     id="ImpactScoreAndNumberOfInterviewees"
                     style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px"
                     }}
                  >
                     <div
                        style={{
                           color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                           fontFamily: '"Roboto Mono"',
                           fontSize: "16px",
                           fontStyle: "normal",
                           fontWeight: 500,
                           lineHeight: "135%",
                           letterSpacing: "0.32px",
                           textTransform: "uppercase"
                        }}
                     >
                        IMPACT SCORE:
                     </div>
                     <div
                        id="ImpactScoreWithoutTextPlusNumberOfInterviewees"
                        style={{
                           display: "flex",
                           alignItems: "center",
                           gap: "10px"
                        }}
                     >
                        <div
                           id="ImpactScorePercentagePill"
                           style={{
                              display: "flex",
                              padding: "4px 10px",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              borderRadius: "14px",
                              background: "var(--blue-medium, #628FDA)"
                           }}
                        >
                           {painPoint && (
                              <span style={{ color: "white", fontWeight: "bold" }}>
                                 {`${(painPoint.percentage * 100).toFixed(0)}%`}
                              </span>
                           )}
                        </div>
                        <div
                           style={{
                              color: "var(--slate-dark, #727F95)",
                              fontFamily: "Rubik",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "140%"
                           }}
                        >
                           {painPoint && totalInterviewees
                              ? `${painPoint.count}/${totalInterviewees} interviewees`
                              : "Loading..."}
                        </div>
                     </div>
                  </div>
                  {/* <div
                     id="NumberOfInsightsFromKeyAccounts"
                     style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        borderRadius: "12px"
                     }}
                  >
                    InsightsFKAD
                  </div> */}
               </div>
            </div>
            <AnchorMenu headings={headings} activeHeading={activeHeading} onHeadingClick={handleHeadingClick} />
         </div>
         <div
            id="AllContentBelowAnchorMenu"
            style={{
               display: "flex",
               paddingBottom: "200px",
               flexDirection: "column",
               alignItems: "flex-start",
               gap: "40px"
            }}
         >
            <div
               id="SummaryAndImpactedPartiesSection"
               ref={sectionRefs.current[0]}
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "30px",
                  alignSelf: "stretch",
                  marginTop: "40px"
               }}
            >
               <div
                  style={{
                     display: "flex",
                     alignItems: "flex-start",
                     gap: "24px",
                     alignSelf: "stretch",
                     justifyContent: "space-between"
                  }}
               >
                  <div
                     id="Summary"
                     style={{
                        display: "flex",
                        width: "744px",
                        paddingRight: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "12px"
                     }}
                  >
                     <div
                        style={{
                           flex: "1 0 0",
                           color: "var(--blue-darkest, #001331)",
                           fontFamily: "Rubik",
                           fontSize: "16px",
                           fontStyle: "normal",
                           fontWeight: 400,
                           lineHeight: "140%"
                        }}
                     >
                        {painPoint.description}
                     </div>
                  </div>
                  <div
                     id="ImpactedParties"
                     style={{
                        display: "flex",
                        padding: "24px 20px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "10px",
                        flex: "0 0 0",
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        border: "1px solid var(--slate-lighter, #E5EAF3)",
                        background: "var(--white, #FFF)"
                     }}
                  >
                     <div
                        style={{
                           color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                           fontFamily: '"Roboto Mono"',
                           fontSize: "16px",
                           fontStyle: "normal",
                           fontWeight: 500,
                           lineHeight: "135%",
                           letterSpacing: "0.32px",
                           textTransform: "uppercase"
                        }}
                     >
                        IMPACTED PARTIES
                     </div>
                     <ul
                        style={{
                           color: "var(--blue-darkest, #001331)",
                           fontFamily: "Rubik",
                           fontSize: "16px",
                           fontStyle: "normal",
                           fontWeight: 400,
                           lineHeight: "140%",
                           width: "331px",
                           paddingLeft: "20px",
                           margin: 0
                        }}
                     >
                        {painPoint.impacted_parties.slice(0, 5).map((party, index) => (
                           <li key={index}>{party}</li>
                        ))}
                     </ul>
                  </div>
               </div>
            </div>
            <div id="IndividualPainPoints" ref={sectionRefs.current[1]}>
               <IndividualPainPointsCardScroller individualPainPointsList={individualPainPoints} />
            </div>
            <div
               id="ImportanceAndMetricsImpactedAndCostsCards"
               ref={sectionRefs.current[2]}
               style={{
                  display: "flex",
                  height: "570px",
                  alignItems: "flex-start",
                  gap: "24px",
                  justifyContent: "space-between",
                  width: "100%"
               }}
            >
               <div
                  id="ImportanceCard"
                  style={{
                     display: "flex",
                     width: "360px",
                     padding: "24px",
                     alignItems: "flex-start",
                     gap: "10px",
                     alignSelf: "stretch",
                     borderRadius: "12px",
                     background: "var(--white, #FFF)",
                     maxHeight: "570px",
                     overflowY: "auto"
                  }}
               >
                  <div
                     id="ImportanceCardContent"
                     style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                        flex: "1 0 0"
                     }}
                  >
                     <div
                        id="TitleAndNumberOfFeatureRequestsItRelatesTo"
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "flex-start",
                           gap: "8px",
                           alignSelf: "stretch"
                        }}
                     >
                        <div
                           id="ImportanceTitle"
                           style={{
                              alignSelf: "stretch",
                              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                              fontFamily: '"Roboto Mono"',
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "135%",
                              letterSpacing: "0.32px",
                              textTransform: "uppercase"
                           }}
                        >
                           IMPORTANCE
                        </div>
                        <div
                           id="NumberOfFeatureRequestsItRelatesToSection"
                           style={{
                              display: "flex",
                              padding: "4px 12px",
                              alignItems: "center",
                              gap: "4px",
                              borderRadius: "8px",
                              background: "var(--color-red-light, #FFECEC)"
                           }}
                        >
                           <div
                              style={{
                                 color: "var(--slate-dark, #727F95)",
                                 fontFamily: '"Roboto Mono"',
                                 fontSize: "14px",
                                 fontStyle: "normal",
                                 fontWeight: 500,
                                 lineHeight: "135%",
                                 letterSpacing: "0.28px",
                                 textTransform: "uppercase"
                              }}
                           >
                              relates to:
                           </div>
                           <div
                              style={{
                                 color: "var(--color-red, #F26969)",
                                 fontFamily: '"Roboto Mono"',
                                 fontSize: "14px",
                                 fontStyle: "normal",
                                 fontWeight: 500,
                                 lineHeight: "135%",
                                 letterSpacing: "0.28px",
                                 textTransform: "uppercase"
                              }}
                           >
                              0 pain points
                           </div>
                        </div>
                        <div
                           id="ImportanceTextContent"
                           style={{
                              alignSelf: "stretch",
                              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                              fontFamily: "Rubik",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "145%"
                           }}
                        >
                           <div
                              style={{
                                 display: "flex",
                                 flexDirection: "column",
                                 gap: "8px"
                              }}
                           >
                              <div
                                 style={{
                                    alignSelf: "stretch",
                                    color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                                    fontFamily: "Rubik",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "145%"
                                 }}
                              >
                                 Specifics:
                              </div>
                              <div>{painPoint.specifics}</div>
                              <div
                                 style={{
                                    alignSelf: "stretch",
                                    color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                                    fontFamily: "Rubik",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "145%"
                                 }}
                              >
                                 Why it's particularly impactful for this group:
                              </div>
                              <div>{painPoint.why_them}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  id="MetricsImpactedCard"
                  style={{
                     display: "flex",
                     width: "360px",
                     padding: "24px",
                     alignItems: "flex-start",
                     gap: "10px",
                     alignSelf: "stretch",
                     borderRadius: "12px",
                     border: "1px solid var(--slate-lighter, #E5EAF3)",
                     background: "var(--white, #FFF)",
                     maxHeight: "570px",
                     overflowY: "auto"
                  }}
               >
                  <div
                     id="MetricsImpactedCardContent"
                     style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                        flex: "1 0 0"
                     }}
                  >
                     <div
                        id="TitleAndNumberOfAlignedKPIs"
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "flex-start",
                           gap: "8px",
                           alignSelf: "stretch"
                        }}
                     >
                        <div
                           id="MetricsImpactedTitle"
                           style={{
                              alignSelf: "stretch",
                              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                              fontFamily: '"Roboto Mono"',
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "135%",
                              letterSpacing: "0.32px",
                              textTransform: "uppercase"
                           }}
                        >
                           METRICS IMPACTED
                        </div>
                        <div
                           id="NumberOfAlignedKPIsSection"
                           style={{
                              display: "flex",
                              padding: "4px 12px",
                              alignItems: "center",
                              gap: "4px",
                              borderRadius: "8px",
                              background: "var(--color-yellow-light, #FFEFC6)"
                           }}
                        >
                           <div
                              style={{
                                 color: "var(--slate-dark, #727F95)",
                                 fontFamily: '"Roboto Mono"',
                                 fontSize: "14px",
                                 fontStyle: "normal",
                                 fontWeight: 500,
                                 lineHeight: "135%",
                                 letterSpacing: "0.28px",
                                 textTransform: "uppercase"
                              }}
                           >
                              Aligned with:
                           </div>
                           <div
                              style={{
                                 color: "var(--color-yellow, #B88C1D)",
                                 fontFamily: '"Roboto Mono"',
                                 fontSize: "14px",
                                 fontStyle: "normal",
                                 fontWeight: 500,
                                 lineHeight: "135%",
                                 letterSpacing: "0.28px",
                                 textTransform: "uppercase"
                              }}
                           >
                              0 KPIs
                           </div>
                        </div>
                     </div>
                     <div
                        style={{
                           color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                           fontFamily: "Rubik",
                           fontSize: "16px",
                           fontStyle: "normal",
                           fontWeight: 400,
                           lineHeight: "145%",
                           alignSelf: "stretch"
                        }}
                     >
                        <ul
                           style={{
                              listStyleType: "none",
                              padding: 0,
                              margin: 0
                           }}
                        >
                           {painPoint.metrics_impacted.map((metric, index) => (
                              <li
                                 key={index}
                                 style={{
                                    paddingLeft: "1em",
                                    textIndent: "-1em"
                                 }}
                              >
                                 <span
                                    style={{
                                       color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                                       marginRight: "0.5em"
                                    }}
                                 >
                                    •
                                 </span>
                                 {metric}
                              </li>
                           ))}
                        </ul>
                     </div>
                  </div>
               </div>
               <div
                  id="CostsCard"
                  style={{
                     display: "flex",
                     width: "360px",
                     padding: "24px",
                     alignItems: "flex-start",
                     gap: "10px",
                     alignSelf: "stretch",
                     borderRadius: "12px",
                     background: "var(--white, #FFF)",
                     maxHeight: "570px",
                     overflowY: "auto"
                  }}
               >
                  <div
                     id="CostsCardContent"
                     style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                        flex: "1 0 0"
                     }}
                  >
                     <div
                        id="CostsTitle"
                        style={{
                           alignSelf: "stretch",
                           color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                           fontFamily: '"Roboto Mono"',
                           fontSize: "16px",
                           fontStyle: "normal",
                           fontWeight: 500,
                           lineHeight: "135%",
                           letterSpacing: "0.32px",
                           textTransform: "uppercase"
                        }}
                     >
                        COSTS
                     </div>
                     <div
                        id="PrimaryCostsTitleAndBulletList"
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "flex-start",
                           gap: "4px",
                           flex: "1 0 0",
                           alignSelf: "stretch"
                        }}
                     >
                        <div
                           id="PrimaryCostsTitle"
                           style={{
                              alignSelf: "stretch",
                              color: "var(--slate-dark, #727F95)",
                              fontFamily: '"Roboto Mono"',
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "135%",
                              letterSpacing: "0.32px",
                              textTransform: "uppercase"
                           }}
                        >
                           PRIMARY COSTS
                        </div>
                        <div
                           id="PrimaryCostsBulletList"
                           style={{
                              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                              fontFamily: "Rubik",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "145%",
                              alignSelf: "stretch"
                           }}
                        >
                           <ul
                              style={{
                                 listStyleType: "none",
                                 padding: 0,
                                 margin: 0
                              }}
                           >
                              {painPoint.primary_costs.map((cost, index) => (
                                 <li
                                    key={index}
                                    style={{
                                       paddingLeft: "1em",
                                       textIndent: "-1em"
                                    }}
                                 >
                                    <span
                                       style={{
                                          color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                                          marginRight: "0.5em"
                                       }}
                                    >
                                       •
                                    </span>
                                    {cost.charAt(0).toUpperCase() + cost.slice(1)}
                                 </li>
                              ))}
                           </ul>
                        </div>
                     </div>
                     <div
                        id="ImpliedCostsTitleAndBulletList"
                        style={{
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "flex-start",
                           gap: "4px",
                           flex: "1 0 0",
                           alignSelf: "stretch"
                        }}
                     >
                        <div
                           id="ImpliedCostsTitle"
                           style={{
                              alignSelf: "stretch",
                              color: "var(--slate-dark, #727F95)",
                              fontFamily: '"Roboto Mono"',
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "135%",
                              letterSpacing: "0.32px",
                              textTransform: "uppercase"
                           }}
                        >
                           IMPLIED COSTS
                        </div>
                        <div
                           id="ImpliedCostsBulletList"
                           style={{
                              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                              fontFamily: "Rubik",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "145%",
                              alignSelf: "stretch"
                           }}
                        >
                           <ul
                              style={{
                                 listStyleType: "none",
                                 padding: 0,
                                 margin: 0
                              }}
                           >
                              {painPoint.implied_costs.map((cost, index) => (
                                 <li
                                    key={index}
                                    style={{
                                       paddingLeft: "1em",
                                       textIndent: "-1em"
                                    }}
                                 >
                                    <span
                                       style={{
                                          color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                                          marginRight: "0.5em"
                                       }}
                                    >
                                       •
                                    </span>
                                    {cost.charAt(0).toUpperCase() + cost.slice(1)}
                                 </li>
                              ))}
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div
               id="CurrentSolutions"
               ref={sectionRefs.current[3]}
               style={{
                  width: "100%"
               }}
            >
               <CurrentSolutionsTable currentSolutionsListOfDicts={painPoint.current_solutions} />
            </div>
            <div
               id="RequestedSolutions"
               ref={sectionRefs.current[4]}
               style={{
                  display: "flex",
                  padding: "24px 20px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "10px",
                  alignSelf: "stretch",
                  borderRadius: "12px",
                  border: "1px solid var(--slate-lighter, #E5EAF3)",
                  background: "var(--white, #FFF)"
               }}
            >
               <div
                  id="RequestedSolutionsTitle"
                  style={{
                     alignSelf: "stretch",
                     color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                     fontFamily: '"Roboto Mono"',
                     fontSize: "16px",
                     fontStyle: "normal",
                     fontWeight: 500,
                     lineHeight: "135%",
                     letterSpacing: "0.32px",
                     textTransform: "uppercase"
                  }}
               >
                  REQUESTED SOLUTIONS
               </div>
               <div
                  id="RequestedSolutionsContent"
                  style={{
                     flex: "1 0 0",
                     color: "var(--blue-darkest, #001331)",
                     fontFamily: "Rubik",
                     fontSize: "16px",
                     fontStyle: "normal",
                     fontWeight: 400,
                     lineHeight: "140%"
                  }}
               >
                  {painPoint.requested_solutions}
               </div>
            </div>
            <div id="ImpactfulQuotes" ref={sectionRefs.current[5]}></div>
            <div
               id="Sources"
               ref={sectionRefs.current[6]}
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                  alignSelf: "stretch"
               }}
            >
               <SubsectionDropdownHeader
                  showDropdownArrow={false}
                  sectionHeader="Sources"
                  count={painPoint.source_document_ids.length}
               />
               <div 
                  id="DocWhereInsightIsMentionedCardScroller"
                  style={{
                     display: "flex",
                     alignItems: "flex-start",
                     gap: "24px",
                     alignSelf: "stretch"
                  }}
               >
                  {painPoint.source_document_ids.map((sourceDocumentId) => (
                     <DocWhereInsightIsMentionedCard
                        key={sourceDocumentId}
                        insight_id={painPoint.id}
                        filemetadata_doc_id={sourceDocumentId}
                        choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight="mongo_aggregate_insight"
                     />
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
};

export default SingleAggregatePainPointPage;
