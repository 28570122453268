import React from 'react';

function PainPointCard({ title, summary, count, percentage, onClick }) {
  const totalInterviewees = Math.round(count / percentage);
  const percentageFormatted = Math.round(percentage * 100);

  const getPercentageColors = (percentage) => {
    const hue = 210; // Blue hue
    const saturation = 100;
    const lightness = 100 - percentage * 0.7; // Adjust lightness based on percentage
    const bgColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    const textColor = lightness > 50 ? '#000' : '#fff';
    return { bgColor, textColor };
  };

  const { bgColor, textColor } = getPercentageColors(percentageFormatted);

  return (
    <article 
      className="flex w-[270px] h-[160px] pr-4 items-start gap-3 rounded-lg bg-white shadow-[0px_2px_8px_rgba(114,127,149,0.25)] cursor-pointer"
      onClick={onClick}
    >
      <div className="w-[6px] self-stretch rounded-l-lg bg-[#F26969]" />
      <div className="flex flex-col flex-1 h-full justify-between py-4"> {/* Changed py-4 to py-3 */}
        <h2 className="text-[#001331] font-['Rubik'] text-[15px] font-medium leading-[145%] line-clamp-4 overflow-hidden">
          {title}
        </h2>
        <div className="flex gap-3 items-center w-full">
          <div className="px-2.5 py-[5.5px] text-sm font-bold tracking-wide leading-none uppercase whitespace-nowrap rounded-2xl"
               style={{ background: bgColor, color: textColor }}>
            {percentageFormatted}%
          </div>
          <div className="flex-1 text-[#727F95] font-['Rubik'] text-sm font-normal leading-[120%]">
            {count}/{totalInterviewees} interviewees expressed this
          </div>
        </div>
      </div>
    </article>
  );
}

export default PainPointCard;