import React from "react";
import ROINumerator from "./ROINumerator";
import LabeledEquationValueUSD from "./LabeledEquationValueUSD";

const InsideOfParenthesesContent = ({ amountGained, amountSpent }) => {
   return (
      <div className="inside-parentheses">
         <ROINumerator amountGained={amountGained} amountSpent={amountSpent} />
         <div className="h-px bg-gray-300 my-2"></div>
         <div className="roi-single-equation flex justify-center items-end gap-8">
            <LabeledEquationValueUSD formatted_value={amountSpent} label="AMOUNT SPENT" />
         </div>
      </div>
   );
};

export default InsideOfParenthesesContent;
