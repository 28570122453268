import React from "react";

const LabeledEquationValueUSD = ({ formatted_value, label }) => {
   return (
      <div
         className="labeled-equation-value"
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px"
         }}
      >
         <span className="equation-label text-slate-700 font-['Roboto_Mono'] text-sm font-medium leading-[135%] tracking-wider uppercase">
            {label}
         </span>
         <span className="equation-value text-[#001331] font-['Roboto_Mono'] text-2xl font-medium leading-[135%]">
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 0 }).format(
               formatted_value
            )}
         </span>
      </div>
   );
};

export default LabeledEquationValueUSD;
