import React from 'react';
import CostArea from './CostArea';

function CostAreasStack({ costAreas }) {
  return (
    <div>
      {Object.entries(costAreas).map(([areaName, areaData]) => (
        <CostArea
          key={areaName}
          areaName={areaName}
          costItems={areaData.cost_items}
        />
      ))}
    </div>
  );
}

export default CostAreasStack;