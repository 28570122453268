import React, { useState } from 'react';
import DevelopmentArea from './DevelopmentArea';
import { FaInfoCircle } from 'react-icons/fa';

function DevelopmentAreasStack({ phases, teamInfo, teamAllocations }) {
  const [expandedPhases, setExpandedPhases] = useState({});
  const [hoveredPhase, setHoveredPhase] = useState(null);

  const togglePhase = (phaseName) => {
    setExpandedPhases(prev => ({
      ...prev,
      [phaseName]: !prev[phaseName]
    }));
  };

  // Calculate total hours for each phase
  const calculateTotalHours = (workPackages) => {
    return Object.values(workPackages).reduce((sum, wp) => 
      sum + Object.values(wp.tasks).reduce((taskSum, task) => 
        taskSum + Object.values(task.team_time_allocation).reduce((teamSum, hours) => 
          teamSum + parseFloat(hours), 0), 0), 0);
  };

  return (
    <div className="space-y-8">
      {Object.entries(phases).map(([phaseName, phaseData]) => (
        <div 
          key={phaseName} 
          className="bg-blue-100 p-6 rounded-lg shadow-lg border-2 border-blue-300 cursor-pointer transition-all duration-300 hover:bg-blue-200"
        >
          <div 
            className="flex justify-between items-center mb-4"
            onClick={() => togglePhase(phaseName)}
          >
            <div className="flex items-center relative">
              <FaInfoCircle 
                className="text-blue-600 mr-2 text-xl cursor-help"
                onMouseEnter={() => setHoveredPhase(phaseName)}
                onMouseLeave={() => setHoveredPhase(null)}
              />
              {hoveredPhase === phaseName && phaseData.phase_description && (
                <div className="absolute left-0 top-6 w-64 p-2 bg-white border border-gray-300 rounded shadow-lg z-10">
                  <p className="text-sm text-gray-700">
                    {phaseData.phase_description}
                  </p>
                </div>
              )}
              <h2 className="text-3xl font-bold text-blue-800">{phaseName}</h2>
            </div>
            <div className="text-xl font-semibold text-blue-600">
              <span className="mr-4">Total Labor Hours: {calculateTotalHours(phaseData.work_packages)}</span>
              <span>Phase Duration: {phaseData.phase_duration}</span>
            </div>
            <span className="text-2xl">{expandedPhases[phaseName] ? '▼' : '▶'}</span>
          </div>
          {expandedPhases[phaseName] && (
            <div onClick={(e) => e.stopPropagation()}>
              <DevelopmentArea
                workPackages={phaseData.work_packages}
                teamInfo={teamInfo}
                teamAllocations={teamAllocations}
                phaseDuration={phaseData.phase_duration}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default DevelopmentAreasStack;
