import React, { createContext, useState, useContext, useEffect } from 'react';
import AuthService from '../components/auth/AuthService';
import log from '../utils/logger';
import API from '../services/api';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const currentUser = AuthService.getCurrentUser();
    const [user, setUser] = useState(currentUser);
    
    const login = async (username, password) => {
        try {
            const userData = await AuthService.login(username, password);
            if (userData.login) {
                if (userData.subscription_status === 'active') {
                    setUser(userData);
                }
                return { success: true, data: userData };
            } else {
                return { success: false, message: userData.message };
            }
        } catch (error) {
            console.error('Login failed:', error);
            return { success: false, message: error.message };
        }
    };

    const logout = () => {
        AuthService.logout();
        setUser(null);
    };

    const resetPassword = async (email) => {
        try {
            await AuthService.resetPassword(email);
        } catch (error) {
            console.error('Password reset request failed:', error);
            throw error;
        }
    };

    const updatePassword = async (token, newPassword) => {
        try {
            await AuthService.updatePassword(token, newPassword);
        } catch (error) {
            console.error('Password update failed:', error);
            throw error;
        }
    };

    // Effect to handle the case where the JWT might change in local storage outside of this context
    useEffect(() => {
        const handleStorageChange = () => {
            setUser(AuthService.getCurrentUser());
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [user]);

    return (
        <AuthContext.Provider
            value={{
                user,
                login,
                logout,
                resetPassword,
                updatePassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);
    return context;
};