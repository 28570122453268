import React from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

function AggregatePainPointCard({ painPoint }) {
  const navigate = useNavigate();
  const totalInterviewees = Math.round(painPoint.count / painPoint.percentage);
  const percentageFormatted = Math.round(painPoint.percentage * 100);

  const handleCardClick = () => {
    navigate(`/insights/aggregate-pain-point/${painPoint.id}`);
  };

  const getPercentageColors = (percentage) => {
    const hue = 210; // Blue hue
    const saturation = 100;
    const lightness = 100 - percentage * 0.7; // Adjust lightness based on percentage
    const bgColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    const textColor = lightness > 50 ? '#000' : '#fff';
    return { bgColor, textColor };
  };

  const { bgColor, textColor } = getPercentageColors(percentageFormatted);

  return (
    <div
      className="flex w-full h-40 pr-4 items-center gap-3 flex-shrink-0 rounded-lg bg-white shadow-[0px_2px_8px_0px_rgba(114,127,149,0.25)] cursor-pointer transition duration-300 ease-in-out hover:-translate-y-1 hover:shadow-xl"
      onClick={handleCardClick}
    >
      <div className="w-[6px] self-stretch rounded-l-lg bg-[#F26969]" style={{ flexShrink: 0 }}></div>
      <div className="flex flex-col h-full justify-between py-4">
        <div className="flex items-start gap-2">
          {/*<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 13.2979L6.70188 9.99982L10 6.70169L13.2981 9.99982L10 13.2979ZM11.0625 17.1792C10.9204 17.3213 10.7559 17.43 10.569 17.5054C10.382 17.5807 10.1924 17.6184 10 17.6184C9.80764 17.6184 9.61799 17.5807 9.43104 17.5054C9.2441 17.43 9.07958 17.3213 8.9375 17.1792L2.79646 11.0384C2.65979 10.9016 2.55375 10.7397 2.47833 10.5527C2.40306 10.3658 2.36542 10.1788 2.36542 9.9919C2.36542 9.80482 2.40306 9.6178 2.47833 9.43086C2.55375 9.24392 2.65979 9.08211 2.79646 8.94544L8.92479 2.81711C9.07535 2.66655 9.24195 2.55357 9.42458 2.47815C9.60736 2.40287 9.79917 2.36523 10 2.36523C10.2008 2.36523 10.3926 2.40287 10.5754 2.47815C10.7581 2.55357 10.9247 2.66655 11.0752 2.81711L17.2035 8.94544C17.3402 9.08211 17.4463 9.24392 17.5217 9.43086C17.5969 9.6178 17.6346 9.80482 17.6346 9.9919C17.6346 10.1788 17.5969 10.3658 17.5217 10.5527C17.4463 10.7397 17.3402 10.9016 17.2035 11.0384L11.0625 17.1792ZM10.1363 15.8557L15.8638 10.1279C15.9011 10.0906 15.9198 10.0452 15.9198 9.9919C15.9198 9.93843 15.9011 9.89301 15.8638 9.85565L10.1363 4.12794C10.0989 4.09058 10.0535 4.07322 10 4.07586C9.94653 4.07864 9.90111 4.09871 9.86375 4.13607L4.12813 9.87169C4.09076 9.90905 4.0734 9.95176 4.07604 9.99982C4.07882 10.0479 4.09889 10.0906 4.13625 10.1279L9.86375 15.8557C9.90111 15.893 9.94653 15.9117 10 15.9117C10.0535 15.9117 10.0989 15.893 10.1363 15.8557Z" fill="#104499"/>
          </svg>*/}
          <h4 className="text-[#001331] font-rubik text-base font-medium leading-[145%] h-[70px] flex-1">
            {painPoint.title}
          </h4>
        </div>
        <div className="flex items-center gap-2">
          <div
            className="px-2.5 py-[5.5px] text-sm font-bold tracking-wide leading-none text-white uppercase whitespace-nowrap rounded-2xl"
            style={{ backgroundColor: bgColor, color: textColor }}
          >
            {percentageFormatted}%
          </div>
          <p className="text-[#727F95] font-rubik text-sm font-normal leading-[120%] flex-1"
             style={{ color: '#727F95', fontFamily: 'Rubik', fontSize: '14px', fontWeight: 400, lineHeight: '120%', flex: '1 0 0' }}>
            ({painPoint.count}/{totalInterviewees} interviewees expressed this)
          </p>
        </div>
      </div>
    </div>
  );
}

export default AggregatePainPointCard;