import React from 'react';

const SkipThisStepButton = ({ onClick, style }) => {
  const defaultStyle = {
    color: 'var(--blue-dark, #104499)',
    fontFamily: '"Roboto Mono"',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '135%',
    letterSpacing: '0.32px',
    textTransform: 'capitalize',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    marginTop: '16px'
  };

  const combinedStyle = { ...defaultStyle, ...style };

  return (
    <button onClick={onClick} style={combinedStyle}>
      Skip This Step
    </button>
  );
};

export default SkipThisStepButton;