import React, { useEffect, useState } from "react";
import { FaFileAlt, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import API from "../../services/api";

function DocWhereInsightIsMentionedCard({
   insight_id,
   filemetadata_doc_id,
   choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight
}) {
   const [mentions, setMentions] = useState([]);
   const [currentMentionIndex, setCurrentMentionIndex] = useState(0);
   const [fileMetadata, setFileMetadata] = useState(null);

   useEffect(() => {
      const fetchMentionsAndMetadata = async () => {
         try {
            const mentionsResponse = await API.get(
               `/doc-viewer-page/get-list-of-mentions-of-this-insight-in-this-document/${insight_id}/${filemetadata_doc_id}/${choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight}`
            );
            setMentions(mentionsResponse.data);

            const metadataResponse = await API.get(`/insights/get-filemetadata-doc-info/${filemetadata_doc_id}`);
            setFileMetadata(metadataResponse.data);
         } catch (error) {
            console.error("Error fetching mentions and metadata:", error);
         }
      };
      fetchMentionsAndMetadata();
   }, [insight_id, filemetadata_doc_id, choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight]);

   const handlePrevious = () => {
      setCurrentMentionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : mentions.length - 1));
   };

   const handleNext = () => {
      setCurrentMentionIndex((prevIndex) => (prevIndex < mentions.length - 1 ? prevIndex + 1 : 0));
   };

   const handleMentionClick = (mention) => {
      console.log("Mention clicked:", {
         mentionData: mention,
         insightIdentifier: insight_id,
         fileMetadataDocumentId: filemetadata_doc_id,
         insightType: choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight,
         fileMetadataInfo: fileMetadata
      });
   };

   if (!fileMetadata || mentions.length === 0) {
      return <div>Loading...</div>;
   }

   const currentMention = mentions[currentMentionIndex];
   const linkUrl = `/doc-viewer/${filemetadata_doc_id}/${currentMention.id}`;

   return (
      <div
         className="bg-white border border-gray-200 rounded-lg p-6 mr-4 mb-4 min-w-[350px] max-w-sm shadow-md flex flex-col"
         style={{ height: "258px" }}
      >
         <div className="flex flex-col h-full">
            <Link
               to={linkUrl}
               className="flex-grow overflow-auto mb-4 cursor-pointer hover:bg-gray-100 rounded transition duration-200"
               onClick={() => handleMentionClick(currentMention)}
            >
               <blockquote className="text-sm text-gray-700 italic border-l-4 border-gray-300 pl-4">
                  {currentMention.span_text}
               </blockquote>
            </Link>
            <div className="flex justify-between items-center">
               <button onClick={handlePrevious} className="text-gray-500 hover:text-gray-700">
                  <FaChevronLeft />
               </button>
               <span className="text-sm text-gray-500">
                  {currentMentionIndex + 1} / {mentions.length}
               </span>
               <button onClick={handleNext} className="text-gray-500 hover:text-gray-700">
                  <FaChevronRight />
               </button>
            </div>
            <div className="flex items-center mt-4 pt-4 border-t border-gray-200">
               <FaFileAlt className="text-gray-400 mr-2" />
               <Link to={linkUrl} className="text-xs text-gray-500 truncate hover:underline">
                  {fileMetadata.file_name}
               </Link>
            </div>
         </div>
      </div>
   );
}

export default DocWhereInsightIsMentionedCard;