import React from 'react';
import { Popover } from 'antd';

const RelatedInitiativeCard = ({ title, description, explanation_of_relatedness_decision }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-4 border border-gray-200">
      <h3 className="text-lg font-semibold mb-2 text-gray-800">{title}</h3>
      <p className="text-sm text-gray-600 mb-2">{description}</p>
      <Popover
        content={
          <div className="max-w-xs max-h-48 overflow-y-auto">
            {explanation_of_relatedness_decision}
          </div>
        }
        trigger="hover"
      >
        <span className="text-sm text-blue-600 cursor-pointer">Why is this related?</span>
      </Popover>
    </div>
  );
};

export default RelatedInitiativeCard;