import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";

const RenewSubscription = () => {
   const { user, createCheckoutSession } = useAuth();
   const navigate = useNavigate();
   const [error, setError] = useState("");

   const handleRenew = async () => {
      try {
         const checkoutUrl = await createCheckoutSession(user.userId);
         if (checkoutUrl) {
            window.location.href = checkoutUrl;
         } else {
            setError("Failed to create checkout session");
         }
      } catch (error) {
         console.error("Failed to create checkout session:", error);
         setError("An error occurred while processing your request");
      }
   };

   return (
      <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
         <h2 className="text-2xl font-bold mb-6 text-center">Renew Your Subscription</h2>
         <p className="mb-4">Your subscription has expired. Please renew to continue using our services.</p>
         {error && <p className="text-red-500 text-center mb-4">{error}</p>}
         <PrimaryButton onClick={handleRenew} className="w-full">
            Renew Subscription
         </PrimaryButton>
      </div>
   );
};

export default RenewSubscription;
