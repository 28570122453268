import React from "react";

const H3Gray = ({ children, style }) => {
   const defaultSubtitleStyle = {
      color: "var(--slate-dark, #727F95)",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "135%",
      marginTop: "12px",
      width: "100%"
   };

   const mergedStyle = { ...defaultSubtitleStyle, ...style };

   return <h3 style={mergedStyle}>{children}</h3>;
};

export default H3Gray;