import React, { useState, useRef, useEffect, useContext } from 'react';
import API from "../../../services/api";
import { StatusContext } from '../../../contexts/StatusContext';
import { useNavigate } from 'react-router-dom';
import TeamInformationForm from './TeamInformationForm';

const IndividualInitiativeMoreOptionsDropdown = ({ initiative }) => {
  const [isRunMenuOpen, setIsRunMenuOpen] = useState(false);
  const [showTeamInformationForm, setShowTeamInformationForm] = useState(false);
  const runMenuRef = useRef(null);
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (runMenuRef.current && !runMenuRef.current.contains(event.target)) {
        setIsRunMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFindRelatedInsightsForInitiative = async () => {
    setIsRunMenuOpen(false);
    try {
      const response = await API.get(`initiatives-page/${initiative._id}/find-related-insights-for-initiative`);
      if (response.status === 200) {
        console.log("Related insights search started");
      } else {
        throw new Error("Failed to start related insights search");
      }

      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
    } catch (error) {
      console.error("Error starting related insights or objectives search:", error);
    }
  };

  const handleAboutInitiative = () => {
    setIsRunMenuOpen(false);
    navigate(`/initiatives/${initiative._id}/about`);
  };

  const handleEstimateAgain = () => {
    setIsRunMenuOpen(false);
    setShowTeamInformationForm(true);
  };

  return (
    <>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/96231f69581157fcbd7a9702e3f52eced07f94dae25e4082b7ff565cb85aaa86?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43"
        alt=""
        className="object-contain shrink-0 w-6 aspect-square cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setIsRunMenuOpen(!isRunMenuOpen);
        }}
      />
      {isRunMenuOpen && (
        <div ref={runMenuRef} className="absolute right-0 mt-2 w-48 bg-white rounded shadow-lg z-10">
          <button
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
            onClick={handleEstimateAgain}
          >
            Estimate metrics
          </button>
          {initiative.last_time_related_insights_was_ran && (
            <button
              className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
              onClick={handleFindRelatedInsightsForInitiative}
            >
              Find insights related to this initiative again
            </button>
          )}
          <button
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
            onClick={handleAboutInitiative}
          >
            About
          </button>
        </div>
      )}
      {showTeamInformationForm && (
        <TeamInformationForm
          initiativeId={initiative._id}
          onClose={() => setShowTeamInformationForm(false)}
          title={initiative.title}
        />
      )}
    </>
  );
};

export default IndividualInitiativeMoreOptionsDropdown;