import React from 'react';

const MetricCard = ({ value, metricType, initiativeId, onClick }) => {
  const getMetricTitle = (metricType) => {
    switch (metricType.toLowerCase()) {
      case 'roi':
        return 'ROI';
      case 'development-time':
        return 'Timeline';
      case 'cost':
        return 'Cost';
      default:
        return metricType;
    }
  };

  const handleClick = () => {
    onClick(initiativeId, metricType);
  };

  const formatValue = (metricType, value) => {
    switch (metricType) {
      case 'roi':
        return `${(value * 100).toFixed(2)}%`;
      case 'cost':
        return `$${value.toLocaleString('en-US')}`;
      case 'development-time':
        return `${value} MONTHS`;
      default:
        return value;
    }
  };

  const formattedValue = formatValue(metricType, value);

  return (
    <div 
      className="flex gap-1 items-start py-4 pr-4 pl-5 bg-white rounded-lg shadow-[0px_1px_2px_rgba(114,127,149,0.3)] cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex flex-col w-[120px]">
        <div className="text-sm tracking-wide text-slate-900">{getMetricTitle(metricType)}</div>
        <div className="mt-1 text-base font-bold tracking-wide text-emerald-600">{formattedValue}</div>
      </div>
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9fe37c609d3f1eb3cca49e953a184f1727a9f085c67c001986f7fdc279c21ee4?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43" alt="" className="object-contain shrink-0 w-6 aspect-square" />
    </div>
  );
};

export default MetricCard;