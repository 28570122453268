import React, { useContext } from "react";
import API from "../../services/api";
import { StatusContext } from '../../contexts/StatusContext';
import H2Black from "../reusable_components/HeadingsAndTitles/H2Black";
import H3Gray from "../reusable_components/HeadingsAndTitles/H3Gray";
import OnboardingFlowProgressBar from "./OnboardingFlowProgressBar";
import DummyInitiativeBoxForFindRelevantInsightsStepOfOnboardingFlow from "./DummyInitiativeBoxForFindRelevantInsightsStepOfOnboardingFlow";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import FindInsightsIcon from "../../assets/icons/FindInsightsIcon.svg";
import SkipThisStepButton from "./SkipThisStepButton";

const FindRelevantInsightsOnboardingFlowPage4 = ({ initiativeId, onNextStep, onSkipStep }) => {
   const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);

   const handleFindInsightsClick = async () => {
      try {
         const response = await API.get(`initiatives-page/${initiativeId}/find-related-insights-for-initiative`);
         if (response.status === 200) {
            console.log("Related insights search started");
            setIsCurrentlyCheckingForUpdatesOnProcesses(true);
            onNextStep();
         } else {
            throw new Error("Failed to start related insights search");
         }
      } catch (error) {
         console.error("Error starting related insights search:", error);
         // You might want to show an error message to the user here
      }
   };

   const handleSkipClick = () => {
      console.log("Skip button clicked");
      onSkipStep();
   };

   const isInitiativeIdValid = initiativeId && initiativeId.trim() !== "";

   return (
      <div
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "687px",
            maxWidth: "880px",
         }}
      >
         <OnboardingFlowProgressBar currentStep={3} totalSteps={3} />
         <H2Black style={{ textAlign: "center" }}>Find relevant insights for your initiative</H2Black>
         <p style={{
            alignSelf: 'stretch',
            color: 'var(--slate-dark, #727F95)',
            fontFamily: '"Roboto Mono"',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '135%',
            marginTop: '12px',
            marginBottom: '64px'
         }}>
            Relevant insights can help you support your product roadmap decisions with data
         </p>
         <DummyInitiativeBoxForFindRelevantInsightsStepOfOnboardingFlow />
         <PrimaryButton
            icon={FindInsightsIcon}
            onClick={handleFindInsightsClick}
            disabled={!isInitiativeIdValid}
            style={{
               marginTop: '64px'
            }}
         >
            Find Insights
         </PrimaryButton>
         <SkipThisStepButton onClick={handleSkipClick} />
      </div>
   );
};

export default FindRelevantInsightsOnboardingFlowPage4;