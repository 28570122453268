import * as React from "react";
import { useEffect, useRef } from "react";
import API from "../../../src/services/api";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import log from "../../utils/logger";

function GearIcon({ src, alt, onClick }) {
   return (
      <button className="flex justify-center items-center px-2.5 w-13 h-13 bg-blue-900 rounded" onClick={onClick}>
         <img loading="lazy" src={src} alt={alt} className="w-7 aspect-square" />
      </button>
   );
}

function Label({ children }) {
   return (
      <div className="flex-1 justify-center items-center px-10 py-4 text-sm font-bold tracking-wide leading-5 uppercase whitespace-nowrap bg-gray-50 rounded text-slate-900">
         {children}
      </div>
   );
}

const Menu = React.forwardRef(({ onRemove }, ref) => {
   const handleRemoveClick = () => {
      if (window.confirm("Are you sure you want to remove this integration?")) {
         onRemove();
      }
   };

   return (
      <div ref={ref} className="absolute bg-white shadow-md rounded p-2 mt-2">
         <button
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            onClick={handleRemoveClick}
         >
            Remove Integration
         </button>
      </div>
   );
});

function ButtonFillerForAlreadyIntegratedServices({ serviceName }) {
   const [menuVisible, setMenuVisible] = React.useState(false);
   const menuRef = useRef(null);

   const toggleMenu = () => {
      setMenuVisible(!menuVisible);
   };

   const handleRemoveIntegration = async () => {
      try {
         const response = await API.post("/integrations_general/remove_integration", {
            service_name: serviceName
         });
         if (response.status === 200) {
            log("Integration removed successfully");
            window.location.reload();
         }
      } catch (error) {
         console.error("Failed to remove integration", error);
      }
      setMenuVisible(false);
   };

   useEffect(() => {
      const handleOutsideClick = (event) => {
         if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuVisible(false);
         }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
         document.removeEventListener("mousedown", handleOutsideClick);
      };
   }, [menuRef]);

   return (
      <section className="relative flex gap-2 self-stretch max-w-[356px] mt-4">
         <GearIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ce6a88ec7c53b5404cacea8021092a6c7fada3fa7db3928adc2ff9d642f0260a?apiKey=8e58a4857cb34ae1a2d77305a4e595bc&"
            alt="Installed icon"
            onClick={toggleMenu}
         />
         <Label>installed</Label>
         {menuVisible && <Menu ref={menuRef} onRemove={handleRemoveIntegration} />}
      </section>
   );
}

export default ButtonFillerForAlreadyIntegratedServices;
