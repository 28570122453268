import React, { useState, useEffect, useRef } from 'react';
import log from '../../../../utils/logger';

function CostArea({ areaName, costItems }) {
  const [editedCostItems, setEditedCostItems] = useState(costItems);
  const [editField, setEditField] = useState({});

  const handleChange = (itemName, field, value) => {
    setEditedCostItems((prevItems) => ({
      ...prevItems,
      [itemName]: {
        ...prevItems[itemName],
        [field]: value,
      },
    }));
  };

  const handleSave = (itemName, field) => {
    setEditField((prev) => ({
      ...prev,
      [`${itemName}-${field}`]: false,
    }));
    log('Updated Cost Items:', editedCostItems);
  };

  const handleEdit = (itemName, field) => {
    setEditField((prev) => ({
      ...prev,
      [`${itemName}-${field}`]: true,
    }));
  };

  const textareaRef = useRef(null);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [editField]);

  const totalCost = Object.values(editedCostItems).reduce((sum, item) => sum + parseFloat(item.cost), 0);

  return (
    <div className="m-5 p-5 border rounded-lg bg-white shadow-md">
      <h3 className="text-2xl mb-2">{areaName}</h3>
      <p className="text-lg mb-5 font-semibold">Total Cost: ${totalCost.toFixed(2)}</p>
      <ul className="list-none p-0">
        {Object.entries(editedCostItems).map(([itemName, itemData]) => (
          <li key={itemName} className="mb-5 pb-5 border-b">
            <strong className="text-lg block mb-2">{itemName}</strong>
            <div className="mb-3 flex items-center">
              <label className="font-bold mr-2">Cost:</label>
              {editField[`${itemName}-cost`] ? (
                <>
                  <input
                    type="number"
                    className="p-2 border rounded"
                    value={itemData.cost}
                    onChange={(e) => handleChange(itemName, 'cost', e.target.value)}
                  />
                  <button
                    className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                    onClick={() => handleSave(itemName, 'cost')}
                  >
                    Save
                  </button>
                </>
              ) : (
                <span
                  className="cursor-pointer p-2 border rounded hover:border-blue-500"
                  onClick={() => handleEdit(itemName, 'cost')}
                >
                  ${parseFloat(itemData.cost).toFixed(2)}
                </span>
              )}
            </div>
            <div className="flex items-center">
              <label className="font-bold mr-2">Description:</label>
              {editField[`${itemName}-description`] ? (
                <>
                  <textarea
                    ref={textareaRef}
                    className="p-2 border rounded w-full resize-none overflow-hidden"
                    value={itemData.description}
                    onChange={(e) => handleChange(itemName, 'description', e.target.value)}
                    rows={1}
                  />
                  <button
                    className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                    onClick={() => handleSave(itemName, 'description')}
                  >
                    Save
                  </button>
                </>
              ) : (
                <span
                  className="cursor-pointer p-2 border rounded hover:border-blue-500"
                  onClick={() => handleEdit(itemName, 'description')}
                >
                  {itemData.description}
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CostArea;