import React from "react";

function StepItem({ imgSrc, imgAlt, description }) {
  return (
    <div className="flex flex-col items-center w-60">
      <img
        loading="lazy"
        src={imgSrc}
        alt={imgAlt}
        className="object-contain w-[171px] h-[171px]"
      />
      <p className="mt-5 text-center">{description}</p>
    </div>
  );
}

export default StepItem;