import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import SocialIcons from "./SocialIcons";
import NavBar from "./NavBar";

function LogoSection({ isOnboarding }) {
   const LogoContent = () => (
      <>
         <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4c9d08ea80f7ed7432fe4d345972889eaa982f96910d64379d9076f6858fc2d?apiKey=8e58a4857cb34ae1a2d77305a4e595bc&"
            alt="XProd logo"
            style={{ height: "33px" }}
         />
         <div
            style={{
               color: "var(--blue-darkest, #001331)",
               fontFamily: "Audiowide",
               fontSize: "20px",
               fontStyle: "normal",
               fontWeight: "400",
               lineHeight: "120%"
            }}
         >
            XProd
         </div>
      </>
   );

   const sharedStyles = {
      display: 'flex',
      height: '56.609px',
      padding: '12px',
      alignItems: 'center',
      gap: '8px'
   };

   return isOnboarding ? (
      <div className="flex items-center gap-2" style={sharedStyles}>
         <LogoContent />
      </div>
   ) : (
      <Link to="/" className="flex items-center gap-2" style={sharedStyles}>
         <LogoContent />
      </Link>
   );
}

function SectionWithLogoAndNavBar({ user, isOnboarding }) {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'flex-end',
      gap: '30px',
      flex: '1 0 0'
    }}>
      <LogoSection isOnboarding={isOnboarding} />
      {user && !isOnboarding && <NavBar />}
    </div>
  );
}

export default function Header() {
   const { user, logout } = useAuth();
   const location = useLocation();
   const isOnboarding = location.pathname === "/onboarding";

   return (
      <header className="flex justify-between items-center px-10 py-3 bg-white shadow-md" style={{
         height: '60px',
         flexShrink: 0,
         boxShadow: '0px 4px 12px 0px rgba(16, 68, 153, 0.06)'
      }}>
         <SectionWithLogoAndNavBar user={user} isOnboarding={isOnboarding} />
         {user && !isOnboarding && <SocialIcons onLogout={logout} />}
      </header>
   );
}