import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API from "../../../services/api";
import "./SingleAggregateFeatureRequestPage.css";
import PageTitle from "../../reusable_components/HeadingsAndTitles/PageTitle";
import DocWhereInsightIsMentionedCard from "../../../components/reusable_components/DocWhereInsightIsMentionedCard";
import { StatusContext } from '../../../contexts/StatusContext';
import PrimaryButton from "../../reusable_components/Buttons/PrimaryButton";
import RelatedInitiativeCard from "../../reusable_components/Cards/RelatedInitiativeCard";
import PageLoadSpinner from "../../reusable_components/PageLoadSpinner/PageLoadSpinner";

const SingleAggregateFeatureRequestPage = () => {
   const [featureRequest, setFeatureRequest] = useState(null);
   const [individualFeatureRequests, setIndividualFeatureRequests] = useState([]);
   const [relatedInitiatives, setRelatedInitiatives] = useState([]);
   const { id } = useParams();
   const navigate = useNavigate();
   const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await API.get(`insights/aggregate-feature-request/${id}`);
            setFeatureRequest(response.data.aggregate_feature_request);
            setIndividualFeatureRequests(response.data.individual_feature_requests);
            setRelatedInitiatives(response.data.related_initiatives);
         } catch (error) {
            console.error("Error fetching feature request data:", error);
         }
      };

      fetchData();
   }, [id]);

   const handleFindRelatedInitiatives = async () => {
      try {
         const response = await API.post('insights/find-and-store-related-initiatives-for-insight', {
            insight_id: id,
            insight_type: 'aggregate_feature_request'
         });
         if (response.status === 202) {
            console.log("Related initiatives search started");
            setIsCurrentlyCheckingForUpdatesOnProcesses(true);
         } else {
            throw new Error("Failed to start related initiatives search");
         }
      } catch (error) {
         console.error("Error starting related initiatives search:", error);
      }
   };

   if (!featureRequest) {
      return <PageLoadSpinner />;
   }

   return (
      <div className="single-aggregate-feature-request-page">
         <PageTitle>{featureRequest.title}</PageTitle>

         <div className="feature-request-summary">
            <p className="description">{featureRequest.description}</p>
            <div className="impact-info">
               <span className="impact-score">Impact Score: {featureRequest.impact_score}</span>
               <span className="count-percentage">
                  {featureRequest.count} out of {Math.round(featureRequest.count / featureRequest.percentage)}{" "}
                  interviewees ({(featureRequest.percentage * 100).toFixed(2)}%)
               </span>
            </div>
         </div>

         <div className="feature-request-details">
            <section className="detail-section">
               <h2>Impacted Parties</h2>
               <p>{featureRequest.impacted_parties}</p>
            </section>

            <section className="detail-section">
               <h2>Current Solutions</h2>
               <p>{featureRequest.current_solutions}</p>
            </section>

            <section className="detail-section">
               <h2>Requested Solutions</h2>
               <p>{featureRequest.requested_solutions}</p>
            </section>

            <section className="detail-section">
               <h2>Solutions Considered</h2>
               <p>{featureRequest.solutions_considered}</p>
            </section>

            <section className="detail-section">
               <h2>Why Them</h2>
               <p>{featureRequest.why_them}</p>
            </section>

            <section className="detail-section">
               <h2>Cost</h2>
               <p>{featureRequest.cost}</p>
            </section>

            <section className="detail-section">
               <h2>Specifics</h2>
               <p>{featureRequest.specifics}</p>
            </section>

            {featureRequest.metrics_impacted && featureRequest.metrics_impacted.length > 0 && (
               <section className="detail-section">
                  <h2>Metrics Impacted</h2>
                  <ul>
                     {featureRequest.metrics_impacted.map((metric, index) => (
                        <li key={index}>{metric}</li>
                     ))}
                  </ul>
               </section>
            )}

            {featureRequest.impactful_quotes && featureRequest.impactful_quotes.length > 0 && (
               <section className="detail-section">
                  <h2>Impactful Quotes</h2>
                  {featureRequest.impactful_quotes.map((quote, index) => (
                     <blockquote key={index}>{quote}</blockquote>
                  ))}
               </section>
            )}
         </div>

         <div className="individual-feature-requests">
            <h2>Individual Feature Requests</h2>
            <div className="individual-feature-requests-scroller">
               {individualFeatureRequests.map((individualFeatureRequest) => (
                  <div
                     key={individualFeatureRequest._id}
                     className="individual-feature-request-card cursor-pointer"
                     onClick={() => navigate(`/insights/individual-feature-request/${individualFeatureRequest._id}`)}
                  >
                     <h3>{individualFeatureRequest.title}</h3>
                     {individualFeatureRequest.impactful_quotes &&
                        individualFeatureRequest.impactful_quotes.length > 0 && (
                           <blockquote>{individualFeatureRequest.impactful_quotes[0]}</blockquote>
                        )}
                     <p className="source-document">Source: {individualFeatureRequest.source_document_name}</p>
                  </div>
               ))}
            </div>
         </div>

         <div className="mentions-section">
            <h2>Mentions</h2>
            <div className="mentions-scroller">
               {featureRequest.source_document_ids.map((docId) => (
                  <DocWhereInsightIsMentionedCard
                     key={docId}
                     insight_id={id}
                     filemetadata_doc_id={docId}
                     choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight="mongo_aggregate_insight"
                  />
               ))}
            </div>
         </div>

         <div className="related-initiatives-section">
            <h2 style={{ fontSize: '1.5rem' }}>Related Initiatives</h2>
            <PrimaryButton
               onClick={handleFindRelatedInitiatives}
            >
               Find Related Initiatives
            </PrimaryButton>

            {featureRequest.last_time_find_related_initiatives_was_ran && relatedInitiatives.length > 0 && (
               <div className="related-initiatives-list">
                  {relatedInitiatives.map((initiative) => (
                     <RelatedInitiativeCard 
                        key={initiative.id} 
                        title={initiative.title} 
                        description={initiative.description} 
                        explanation_of_relatedness_decision={initiative.explanation_of_relatedness_decision} 
                     />
                  ))}
               </div>
            )}
         </div>
      </div>
   );
};

export default SingleAggregateFeatureRequestPage;