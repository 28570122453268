import React, { useContext } from "react";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import { uploadFiles, getSupportedFileTypes } from "../../services/localFileUploadService";
import { Tooltip, IconButton, styled } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import documentTypes from "../../ConstantVariables.json";
import { StatusContext } from "../../contexts/StatusContext";

// Custom styled tooltip
const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
   ({ theme }) => ({
      "& .MuiTooltip-tooltip": {
         backgroundColor: theme.palette.background.paper,
         color: theme.palette.text.primary,
         maxWidth: 320,
         fontSize: theme.typography.pxToRem(12),
         border: "1px solid #dadde9",
         boxShadow: theme.shadows[1],
         borderRadius: theme.shape.borderRadius,
         padding: theme.spacing(2)
      }
   })
);

function LocalFilesUploadBox() {
   const name = "Local Files";
   const category = "Storage";
   const description = "Upload your video, audio, and text files.";
   const imgSrc = `${process.env.PUBLIC_URL}/assets/external_integration_logos/upload_icon.png`;
   const altText = "Local Files logo";
   const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);

   const handleUploadClick = async () => {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.multiple = true;
      fileInput.accept = getSupportedFileTypes().join(",");
      fileInput.onchange = async (event) => {
         const files = Array.from(event.target.files); // Remove file size filter
         if (files.length > 0) {
            const uploadedFileMetadataObjects = await uploadFiles(files, setIsCurrentlyCheckingForUpdatesOnProcesses);
         }
      };
      fileInput.click();
   };

   const documentTypeTooltipContent = (
      <div>
         <p style={{ marginBottom: "12px", fontWeight: "bold" }}>
            Here's where you upload your documents that XProd uses to fuel your insights.
         </p>

         <p style={{ marginBottom: "12px", fontWeight: "bold" }}>Examples:</p>
         {Object.entries(documentTypes).map(([type, { description }]) => (
            <div key={type} style={{ marginBottom: "8px" }}>
               <strong style={{ color: "#1976d2" }}>{type}:</strong>
               <p style={{ margin: "4px 0 0 0" }}>{description}</p>
            </div>
         ))}
      </div>
   );

   return (
      <section className="flex flex-col p-4 bg-white rounded-lg leading-[135%] max-w-[388px]">
         <article className="flex gap-4">
            <img loading="lazy" src={imgSrc} alt={altText} className="shrink-0 w-14 aspect-square" />
            <div className="flex flex-col flex-1 my-auto">
               <div className="flex items-center">
                  <h1 className="text-lg font-medium text-slate-900">{name}</h1>
                  <StyledTooltip title={documentTypeTooltipContent} arrow placement="right-start">
                     <IconButton size="small" style={{ marginLeft: "4px" }}>
                        <InfoIcon fontSize="small" color="action" />
                     </IconButton>
                  </StyledTooltip>
               </div>
               <span className="mt-1 text-sm tracking-wide uppercase text-slate-500">{category}</span>
            </div>
         </article>
         <p className="mt-4 text-sm leading-5 text-slate-900 h-16">{description}</p>
         <PrimaryButton onClick={handleUploadClick}>Upload Files</PrimaryButton>
      </section>
   );
}

export default LocalFilesUploadBox;
