import React, { useState, useEffect, useContext } from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import { Menu, MenuItem, Tooltip, IconButton, styled, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import API from "../../services/api";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";
import log from "../../utils/logger";
import documentTypes from "../../ConstantVariables.json";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../contexts/StatusContext";


// Custom styled tooltip
const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
   ({ theme }) => ({
      "& .MuiTooltip-tooltip": {
         backgroundColor: theme.palette.background.paper,
         color: theme.palette.text.primary,
         maxWidth: 320,
         fontSize: theme.typography.pxToRem(12),
         border: "1px solid #dadde9",
         boxShadow: theme.shadows[1],
         borderRadius: theme.shape.borderRadius,
         padding: theme.spacing(2)
      }
   })
);

// Custom styled Alert component
const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ClassifyFilesPage = () => {
   const [files, setFiles] = useState([]);
   const [selectedDocumentTypes, setSelectedDocumentTypes] = useState({});
   const [anchorEl, setAnchorEl] = useState(null);
   const [selectedFileId, setSelectedFileId] = useState(null);
   const [selectedRows, setSelectedRows] = useState([]);
   const [bulkDocumentType, setBulkDocumentType] = useState("");
   const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success", key: 0 });
   const navigate = useNavigate();
   const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);

   useEffect(() => {
      fetchFiles();
   }, []);

   const fetchFiles = async () => {
      try {
         const response = await API.get("file-management/classify-files");
         const sortedFiles = sortFilesByDocumentType(response.data);
         setFiles(sortedFiles);
         setInitialDocumentTypes(sortedFiles);
      } catch (error) {
         console.error("Error fetching files:", error);
      }
   };

   const sortFilesByDocumentType = (files) => {
      return files.sort((a, b) => {
         if (!a.documentType && b.documentType) return -1;
         if (a.documentType && !b.documentType) return 1;
         return 0;
      });
   };

   const setInitialDocumentTypes = (files) => {
      const initialDocumentTypes = {};
      files.forEach((file) => {
         initialDocumentTypes[file.id] = file.documentType || "";
      });
      setSelectedDocumentTypes(initialDocumentTypes);
   };

   const showNotification = (message, severity) => {
      setSnackbar((prev) => ({
         open: true,
         message,
         severity,
         key: prev.key + 1 // Increment the key to force re-render
      }));
   };

   const handleDocumentTypeChange = async (fileId, documentType) => {
      setSelectedDocumentTypes((prevTypes) => ({
         ...prevTypes,
         [fileId]: documentType
      }));

      try {
         await API.post("file-management/update-file-metadata", {
            files: [files.find((file) => file.id === fileId)],
            selectedDocumentTypes: { [fileId]: documentType }
         });
         showNotification("Document type has been successfully saved.", "success");
         log("Kicking off insights extraction process");
         await API.post("/insights/extract_insights_for_user");
         setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      } catch (error) {
         console.error("Error updating file metadata:", error);
         showNotification("Failed to save document type.", "error");
      }
   };

   const handleOptionsClick = (event, fileId) => {
      setAnchorEl(event.currentTarget);
      setSelectedFileId(fileId);
   };

   const handleOptionsClose = () => {
      setAnchorEl(null);
      setSelectedFileId(null);
   };

   const handleDelete = async () => {
      try {
         await API.delete(`file-management/delete-files-and-metadata`, {
            data: { file_ids: selectedRows }
         });
         setFiles((prevFiles) => prevFiles.filter((file) => !selectedRows.includes(file.id)));
         setSelectedRows([]);
         showNotification("Selected files have been successfully deleted.", "success");
      } catch (error) {
         console.error("Error deleting files:", error);
         showNotification("Failed to delete files.", "error");
      }
   };

   const handleBulkDocumentTypeChange = (event) => {
      setBulkDocumentType(event.target.value);
   };

   const applyBulkDocumentType = async () => {
      log("Applying bulk document type:", bulkDocumentType);
      log("Selected rows for bulk update:", selectedRows);
      log("Current document types before update:", selectedDocumentTypes);
      const updatedDocumentTypes = { ...selectedDocumentTypes };
      selectedRows.forEach((rowId) => {
         updatedDocumentTypes[rowId] = bulkDocumentType;
      });
      setSelectedDocumentTypes(updatedDocumentTypes);

      try {
         await API.post("file-management/update-file-metadata", {
            files: files.filter((file) => selectedRows.includes(file.id)),
            selectedDocumentTypes: updatedDocumentTypes
         });
         showNotification("Bulk document type has been successfully saved.", "success");
         
         // Add insights extraction process
         log("Kicking off insights extraction process for bulk update");
         await API.post("/insights/extract_insights_for_user");
         setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      } catch (error) {
         console.error("Error updating file metadata:", error);
         showNotification("Failed to save bulk document type.", "error");
      }
   };

   const documentTypeTooltipContent = (
      <div>
         {Object.entries(documentTypes).map(([type, { description }]) => (
            <div key={type} style={{ marginBottom: "8px" }}>
               <strong style={{ color: "#1976d2" }}>{type}:</strong>
               <p style={{ margin: "4px 0 0 0" }}>{description}</p>
            </div>
         ))}
      </div>
   );

   const preventSelectionOnClick = (event) => {
      event.stopPropagation();
   };

   const columns = [
      {
         field: "actions",
         headerName: "",
         width: 30,
         renderCell: (params) => (
            <GridActionsCellItem
               icon={<MoreVertIcon />}
               onClick={(event) => handleOptionsClick(event, params.row.id)}
               label="Options"
            />
         )
      },
      {
         field: "fileName",
         headerName: "File Name",
         width: 350,
         renderCell: (params) => (
            <div
               className="cursor-pointer text-blue-600 hover:underline"
               onClick={() => navigate(`/doc-viewer/${params.row.id}`)}
            >
               {params.value}
            </div>
         )
      },
      { field: "fileExtension", headerName: "File Extension", width: 120 },
      {
         field: "documentType",
         headerName: "Document Type",
         width: 350,
         renderHeader: (params) => (
            <div style={{ display: "flex", alignItems: "center" }}>
               {params.colDef.headerName}
               <StyledTooltip title={documentTypeTooltipContent} arrow placement="right-start">
                  <IconButton size="small" style={{ marginLeft: "4px" }}>
                     <InfoIcon fontSize="small" color="action" />
                  </IconButton>
               </StyledTooltip>
            </div>
         ),
         renderCell: (params) => (
            <div onClick={preventSelectionOnClick} className="w-full h-full flex items-center">
               <select
                  className={`w-full ${selectedDocumentTypes[params.row.id] === "" ? "bg-red-200" : ""}`}
                  value={selectedDocumentTypes[params.row.id] || ""}
                  onChange={(e) => handleDocumentTypeChange(params.row.id, e.target.value)}
               >
                  <option value="">Select Document Type</option>
                  {Object.keys(documentTypes).map((type) => (
                     <option key={type} value={type}>
                        {type}
                     </option>
                  ))}
               </select>
            </div>
         ),
         cellClassName: (params) => (selectedDocumentTypes[params.row.id] === "" ? "bg-red-200" : "")
      }
   ];

   // Calculate the total width of all columns
   const totalColumnWidth = columns.reduce((total, column) => total + (column.width || 0), 0);
   // Add extra width for checkbox column and potential scrollbar
   const minGridWidth = totalColumnWidth + 100;

   const handleCloseSnackbar = (event, reason) => {
      if (reason === "clickaway") {
         return;
      }
      setSnackbar((prev) => ({ ...prev, open: false }));
   };

   const renderBatchActions = () => {
      if (selectedRows.length === 0) return null;

      return (
         <div>
            <select value={bulkDocumentType} onChange={handleBulkDocumentTypeChange}>
               <option value="">Batch Select Document Type</option>
               {Object.keys(documentTypes).map((type) => (
                  <option key={type} value={type}>
                     {type}
                  </option>
               ))}
            </select>
            <button
               onClick={applyBulkDocumentType}
               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
            >
               Apply to Selected
            </button>
            <button
               onClick={handleDelete}
               className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2"
            >
               Delete
            </button>
         </div>
      );
   };

   return (
      <div>
         <PageTitle>Classify Files</PageTitle>
         {renderBatchActions()}
         <div className="pt-5 pb-5" style={{ height: 400, width: "100%", minWidth: `${minGridWidth}px` }}>
            <DataGrid
               rows={files}
               columns={columns}
               checkboxSelection
               onRowSelectionModelChange={(newSelection) => {
                  log("New Selection (onRowSelectionModelChange):", newSelection);
                  setSelectedRows(newSelection);
               }}
               selectionModel={selectedRows}
               disableRowSelectionOnClick
               sx={{
                  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                     outline: "none"
                  }
               }}
            />
         </div>
         <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleOptionsClose}>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
         </Menu>
         <Snackbar
            key={snackbar.key}
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
         >
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
               {snackbar.message}
            </Alert>
         </Snackbar>
      </div>
   );
};

export default ClassifyFilesPage;
