import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import QuestionIcon from "../../assets/icons/HeaderIcons/social-icons-Question-icon.svg";
import GearSixIcon from "../../assets/icons/HeaderIcons/social-icons-GearSix-icon.svg";
import UserCircleIcon from "../../assets/icons/HeaderIcons/social-icons-UserCircle-icon.svg";

function SocialIcons({ onLogout }) {
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const dropdownRef = useRef(null);

   useEffect(() => {
      const handleClickOutside = (event) => {
         if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !event.target.closest(".dropdown-toggle")
         ) {
            setIsDropdownOpen(false);
         }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   const socialIcons = [
      {
         src: QuestionIcon,
         alt: "Help"
      },
      {
         src: GearSixIcon,
         alt: "Settings"
      },
      {
         src: UserCircleIcon,
         alt: "User profile",
         onClick: () => setIsDropdownOpen(!isDropdownOpen),
         className: "dropdown-toggle"
      }
   ];

   return (
      <div className="flex gap-6 relative">
         {socialIcons.map((icon, index) =>
            icon.onClick ? (
               <div key={index} onClick={icon.onClick} className={icon.className}>
                  <img src={icon.src} alt={icon.alt} className="shrink-0 w-[30px] h-[30px] cursor-pointer" />
               </div>
            ) : (
               <div key={index}>
                  <img src={icon.src} alt={icon.alt} className="shrink-0 w-[30px] h-[30px]" />
               </div>
            )
         )}
         {isDropdownOpen && (
            <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded shadow-lg">
               <Link
                  to="/my-account"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={() => setIsDropdownOpen(false)}
               >
                  My Account
               </Link>
               <Link
                  to="/team-information"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  onClick={() => setIsDropdownOpen(false)}
               >
                  Team Information
               </Link>
               <button
                  onClick={() => {
                     onLogout();
                     setIsDropdownOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
               >
                  Log Out
               </button>
            </div>
         )}
      </div>
   );
}

export default SocialIcons;
