import React from 'react';

const FindInsightsIcon = ({ width = 20, height = 20, color = '#628FDA' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_34_910)">
        <path
          d="M7.89475 14.0448C9.64913 14.0448 11.1404 13.4568 12.3684 12.2809C13.5965 11.1047 14.2105 9.67666 14.2105 7.99667C14.2105 6.31667 13.5965 4.88869 12.3684 3.71273C11.1404 2.53677 9.64913 1.94879 7.89475 1.94879C6.14036 1.94879 4.64913 2.53677 3.42106 3.71273C2.19298 4.88869 1.57895 6.31667 1.57895 7.99667C1.57895 9.67666 2.19298 11.1047 3.42106 12.2809C4.64913 13.4568 6.14036 14.0448 7.89475 14.0448ZM7.10527 10.803V4.28084H8.68422V10.803H7.10527ZM3.50211 10.803V6.2453H5.08106V10.803H3.50211ZM10.7084 10.803V7.23232H12.2874V10.803H10.7084ZM18.9008 19.5606L12.9069 13.8299C12.2212 14.3667 11.4528 14.785 10.6016 15.0849C9.75036 15.385 8.84808 15.535 7.89475 15.535C5.69088 15.535 3.82413 14.8039 2.29448 13.3418C0.764825 11.8797 0 10.0953 0 7.98862C0 5.88191 0.76465 4.097 2.29395 2.63389C3.82325 1.17077 5.68966 0.439209 7.89317 0.439209C10.0967 0.439209 11.9636 1.17043 13.494 2.63288C15.0243 4.09533 15.7895 5.88007 15.7895 7.98711C15.7895 8.89856 15.6326 9.7612 15.3187 10.575C15.005 11.3889 14.5675 12.1203 14.0061 12.7695L20 18.5001L18.9008 19.5606Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_34_910">
          <rect width="20" height="19.1213" fill="white" transform="translate(0 0.439209)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FindInsightsIcon;
