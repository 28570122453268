import React from 'react';

const PageTitle = ({ children }) => {
  const style = {
    color: 'var(--blue-darkest, #001331)',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '135%',
    marginBottom: '60px',
    marginTop: '60px'
  };

  return <h1 style={style}>{children}</h1>;
};

export default PageTitle;
