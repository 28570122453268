import React, { useState } from 'react';
import { useInitiativesPage } from '../../../contexts/InitiativesPageContext';

const SearchBar = () => {
  const { setSearchText, setSearchMode, searchText } = useInitiativesPage();
  const [inputValue, setInputValue] = useState('');

  const handleSearch = () => {
    const trimmedValue = inputValue.trim();
    setSearchText(trimmedValue);
    setSearchMode(trimmedValue.length > 0);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="flex flex-wrap gap-3 items-center px-5 py-2.5 mt-5 w-full text-base bg-white rounded-xl border border-solid border-slate-300 min-h-[42px] text-slate-500 max-md:max-w-full">
      <input
        type="text"
        placeholder={searchText.length > 0 ? searchText : "Search by title or description"}
        className="flex-1 shrink self-stretch my-auto basis-0 max-md:max-w-full bg-transparent border-none outline-none"
        aria-label="Search by title or description"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6db107a89e684099ff95b8ac0561c739ea6dd50c8f38d8729aa25ff8bc577d81?apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43&&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43" alt="" className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square fill-blue-900 stroke-[0.4px] stroke-blue-900" />
    </div>
  );
};

export default SearchBar;