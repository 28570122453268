import React from 'react';
import InsideOfParenthesesContent from './InsideOfParenthesesContent';
import RightSideOfEquation from './RightSideOfEquation';

const ROIEquation = ({ value, amountGained, amountSpent }) => {
  return (
    <div className="roi-equation flex pb-10 items-center justify-center gap-4 w-full">
      <div className="flex flex-row items-center pb-4">
        <span className="text-[#C5D1E4] font-['Roboto_Mono'] text-[200px] font-medium leading-[135%]">(</span>
        <InsideOfParenthesesContent amountGained={amountGained} amountSpent={amountSpent} />
        <span className="text-[#C5D1E4] font-['Roboto_Mono'] text-[200px] font-medium leading-[135%]">)</span>
      </div>
      <div className="multiply-symbol text-[#727F95] font-['Roboto_Mono'] text-[56px] font-medium leading-[135%]">×</div>
      <div className="hundred text-[#001331] font-['Roboto_Mono'] text-[72px] font-medium leading-[135%]">100</div>
      <div className="equal-symbol text-[#727F95] font-['Roboto_Mono'] text-[56px] font-medium leading-[135%]">=</div>
      <RightSideOfEquation value={value} />
    </div>
  );
};

export default ROIEquation;