import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DevelopmentTimeCalculations from './MetricCalculationsPagesForEachMetricType/DevelopmentTime/DevelopmentTimeCalculations';
import CostCalculations from './MetricCalculationsPagesForEachMetricType/Cost/CostCalculations';
import ROICalculations from './MetricCalculationsPagesForEachMetricType/ROI/ROICalculations'; // Import the ROI component
import API from '../../services/api';

const metricTypeComponentMap = {
  'development-time': DevelopmentTimeCalculations,
  'cost': CostCalculations,
  'roi': ROICalculations  // Make sure this line is present and 'roi' is lowercase
  // Add more metric types and their corresponding components
};
const fetchMetricData = async (initiativeId, metricType) => {
  try {
    const response = await API.get('/metrics/get_metric_based_on_related_initiative_and_metric_type', {
      params: {
        initiativeId,
        metricType,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching metric data:', error);
    throw error;
  }
};

const MetricCalculationsPage = () => {
  const { initiativeId, metricType } = useParams();
  const [metricData, setMetricData] = useState(null);

  useEffect(() => {
    fetchMetricData(initiativeId, metricType)
      .then((data) => setMetricData(data))
      .catch((error) => console.error('Error fetching metric data:', error));
  }, [initiativeId, metricType]);

  const MetricTypeComponent = metricTypeComponentMap[metricType];

  return (
    <div>
      {metricData ? (
        <MetricTypeComponent data={metricData} initiativeId={initiativeId} />
      ) : (
        <p>Loading metric calculation data...</p>
      )}
    </div>
  );
};

export default MetricCalculationsPage;