import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Navigate, Outlet } from "react-router-dom";
import log from "../utils/logger";

function PrivateRoute({ children }) {
   // log("We're in PrivateRoute. Children:", children);
   // return children;
   const { user } = useAuth(); // Use the useAuth hook to get the current user
   log("We're in PrivateRoute. User:", user, "Evaluated as:", !!user);
   if (user) {
      log("Rendering children in PrivateRoute:", children);
      return <Outlet />;
   } else {
      log("Navigating to /login in PrivateRoute");
      return <Navigate to="/login" replace />;
   }
}

export { PrivateRoute };
