import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function MoreOptionsDropdownOnVerticalMenuOnDocViewerPageCard({ onClose, insightType, insightId }) {
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleGoToInsightPage = () => {
        const insightTypePath = insightType.toLowerCase().replace(' ', '-');
        navigate(`/insights/individual-${insightTypePath}/${insightId}`);
        onClose();
    };

    return (
        <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded shadow-lg z-10">
            <button
                className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
                onClick={handleGoToInsightPage}
            >
                Go to insight page
            </button>
        </div>
    );
}

export default MoreOptionsDropdownOnVerticalMenuOnDocViewerPageCard;
