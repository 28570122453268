import React from 'react';
import FindInsightsIcon from '../../../assets/icons/FindInsightsIcon.svg';

const FindRelatedInsightsButton = ({ style }) => {
  return (
    <button
      style={{
        display: 'flex',
        height: '40px',
        padding: '0px 12px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '999px',
        ...style,
      }}
    >
      <img
        src={FindInsightsIcon}
        alt="Find Insights Icon"
        style={{
          width: '20px',
          height: '19.121px',
        }}
      />
      <span
        style={{
          color: 'var(--blue-medium, #628FDA)',
          fontFamily: '"Roboto Mono"',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: '135%',
          letterSpacing: '0.32px',
          textTransform: 'capitalize',
        }}
      >
        Find Related Insights
      </span>
    </button>
  );
};

export default FindRelatedInsightsButton;