import API from './api';



export const handleDocumentTypeChange = async (file_metadata_mongodb_doc_id, documentType) => {
  try {
    await API.post("file-management/update-file-metadata", {
      files: [{ id: file_metadata_mongodb_doc_id }],
      selectedDocumentTypes: { [file_metadata_mongodb_doc_id]: documentType }
    });
    return true;
    // showNotification("Document type has been successfully saved.", "success");
  } catch (error) {
    console.error("Error updating file metadata:", error);
    return false;
    // showNotification("Failed to save document type.", "error");
  }
};