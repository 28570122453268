import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import ButtonFillerForAlreadyIntegratedServices from "./ButtonFillerForAlreadyIntegratedServices";
import JiraIntegrationBox from "./JiraIntegrationBox";
import { Link } from "react-router-dom";
import LocalFilesUploadBox from "./LocalFilesUploadBox";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import StatusBox from "../StatusBox/StatusBox";
import log from "../../utils/logger";
import { useIntegrations } from "../../contexts/IntegrationsContext";

function DataIntegrationsPage() {
   const { user } = useAuth();
   const { integrations } = useIntegrations();

   const renderIntegrationButton = (serviceName, handleIntegrationClick) => {
      log(
         `Function: renderIntegrationButton, Args: serviceName=${serviceName}, handleIntegrationClick=${handleIntegrationClick}`
      );
      log(`Integration status for ${serviceName}:`, !!integrations[serviceName]);
      return integrations[serviceName] ? (
         <ButtonFillerForAlreadyIntegratedServices serviceName={serviceName} />
      ) : (
         <PrimaryButton onClick={handleIntegrationClick}>Add {serviceName} Integration</PrimaryButton>
      );
   };

   return (
      <div style={{ padding: "20px" }}>
         <PageTitle>Data Integrations</PageTitle>
         <div className="grid grid-cols-1 md:grid-cols-2">
            <JiraIntegrationBox user={user} renderIntegrationButtonFunction={renderIntegrationButton} />
            <LocalFilesUploadBox />
         </div>
         <Link to="/classify-files">
            <PrimaryButton style={{ width: "200px", marginLeft: "20px", marginTop: "100px" }}>
               Classify Files
            </PrimaryButton>
         </Link>
         <StatusBox />
      </div>
   );
}

export default DataIntegrationsPage;
