import React from "react";
import { format } from 'date-fns';

function KPICard({ kpi }) {
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return format(new Date(dateString), 'MMM d, yyyy');
  };

  return (
    <div className="bg-white border border-gray-300 rounded-lg p-6 shadow-lg flex flex-col justify-between h-full">
      <div>
        <h4 className="text-xl font-bold text-indigo-600 mb-2">{kpi.title}</h4>
        <p className="text-sm text-gray-600 mb-4">{kpi.qualitative_summary}</p>
        <div className="space-y-2">
          {kpi.current_value !== undefined && (
            <div className="flex justify-between">
              <span className="font-semibold">Current Value:</span>
              <span>{kpi.current_value}</span>
            </div>
          )}
          {kpi.target_value !== undefined && (
            <div className="flex justify-between">
              <span className="font-semibold">Target Value:</span>
              <span>{kpi.target_value}</span>
            </div>
          )}
          {kpi.target_date && (
            <div className="flex justify-between">
              <span className="font-semibold">Target Date:</span>
              <span>{formatDate(kpi.target_date)}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default KPICard;